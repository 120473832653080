<template>
  <form id="paymentForm" class="payment-form">
    <h2 class="sub-header text-center text-uppercase mb-30">
      {{title}}
    </h2>
    <div v-if="canSetPrice && isPayFormLoaded" class="payment-form__price-value">
      <Input
        prepend="$"
        :value="priceValue"
        placeholder="Setup your price"
        @onInput="onPriceUpdate"
      />
    </div>
    <slot v-if="!hasSavedCard">
      <div
        id="paymentCardContainer"
        :class="['payment-form__container', {
          'is-hidden': !isPayFormLoaded
        }]"
      />
      <div v-if="!isPayFormLoaded" class="text-center">
        <img src="@/assets/images/spinner.svg" class="payment-form__spinner"/>
      </div>
      <div ref="errorsContainer" class="payment-form__error" role="alert"></div>
    </slot>
    <Button
      rounded
      :disabled="!isPayFormLoaded || !isValueValid"
      class="mt-20"
      :text="buttonText"
      @onClick="onPay"
    />
  </form>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { Button, Input } from '@/components';

  export default {
    name: 'USApayForm',
    components: { Button, Input },
    props: {
      title: String,
      price: [String, Number],
      canSetPrice: Boolean,
      hasSavedCard: Boolean,
      buttonText: {
        type: String,
        default: 'Pay',
      }
    },
    setup({ hasSavedCard, price }, { emit }) {
      const validateValue = v => /^\+?([+-]?([0-9]*[.])?[0-9]+)$/.test(v) && v > 0
      const API_KEY = '_8CEUv355WDFT3rZ9eEpBc1f6ERFQUse2Mur87ZWau';
      const client = new window.usaepay.Client(API_KEY);
      const paymentCard = client.createPaymentCardEntry();
      const errorsContainer = ref(null);
      const isPayFormLoaded = ref(false);
      const priceValue = ref(price || 1);
      const isValueValid = ref(validateValue(priceValue.value));

      const onPay = (event) => {
        event.preventDefault();

        isPayFormLoaded.value = false;

        if (!hasSavedCard) {
          client.getPaymentKey(paymentCard).then(result => {
            if (result.error) {
              errorsContainer.value.textContent = result.error.message;
            } else {
              emit('paid', {
                token: result,
                price: priceValue.value
              });
            }
          });
        } else {
          emit('paid', {
            price: priceValue.value
          });
        }
      };

      const onPriceUpdate = (value) => {
        priceValue.value = value;
        isValueValid.value = validateValue(value);
      }

      onMounted(async () => {
        if (!hasSavedCard) {
          paymentCard.generateHTML({
            'base': {
              height: '40px'
            },
            'base:nth-child(1)': {
              display: 'none'
            },
            'base:nth-child(2)': {
              position: 'absolute',
              top: '0px',
              width: '100%!important'
            },
            'base:nth-child(3)': {
              position: 'absolute',
              top: '48px',
              left: '0px',
              width: '50%!important',
              'padding-left': '15px'
            },
            'base:last-child': {
              position: 'absolute',
              top: '48px',
              left: '50%',
              width: '50%!important',
              margin: '0 0 0 5px!important',
              'padding-left': '15px'
            },
          })
          paymentCard.addHTML('paymentCardContainer');

          document.getElementById('paymentCardIFrame').addEventListener('load', () => {
            isPayFormLoaded.value = true;
          });

          paymentCard.addEventListener('error', errorMessage => {
            errorsContainer.value.textContent = errorMessage;

            if (errorMessage) {
              isPayFormLoaded.value = true;
            }
          });
        } else {
          isPayFormLoaded.value = true;
        }
      });

      return {
        priceValue,
        isPayFormLoaded,
        errorsContainer,
        onPay,
        onPriceUpdate,
        isValueValid,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .payment-form {
    position: relative;

    padding: 20px 0;

    &__container {
      height: 100px;

      &.is-hidden {
        display: none;
      }
    }

    &__price-value {
      margin-bottom: 8px;
    }

    &__error {
      color: red;
      position: absolute;
      font-size: 14px;
      top: 166px;
      text-transform: capitalize;
    }
  }
</style>

import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/price-settings',
    name: 'PriceSettingsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
    },
  },
  {
    path: '/app/fans',
    name: 'FansManagementPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
    },
  },
  {
    path: '/app/creator-post',
    name: 'CreatorPostPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
      isFullscreen: true,
    },
  },
  {
    path: '/app/create-story',
    name: 'CreateStoryPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
      isFullscreen: true,
    },
  },
  {
    path: '/app/new-broadcast',
    name: 'CreatorNewBroadcastPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
      isFullscreen: true,
    },
  },
  {
    path: '/app/live-broadcast',
    name: 'CreatorBroadcastPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
      header: 'CreatorStreamHeader'
    },
  },
  {
    path: '/app/notifications-settings',
    name: 'NotificationsSettingsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
    },
  },
  {
    path: '/app/notifications',
    name: 'NotificationsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
      isFullscreen: true,
    },
  },
  {
    path: '/app/scheduler',
    name: 'CreatorSchedulerSettingsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['model'],
    },
  },
];

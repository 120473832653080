<template>
  <div class="post__footer d-flex justify-space-between" v-if="!noActions">
    <div class="post__info">
      <span v-if="!post.blocked" class="post__info-like mr-20" @click="onLikeAction">
        <img v-if="isPostLiked" src="@/assets/images/icons/like.svg"/>
        <img v-else src="@/assets/images/icons/like-empty.svg"/>
        <span>{{ toSiFormat(likesNum) }}</span>
      </span>

      <span v-if="!isLocked && !post.blocked" class="post__info-comment" @click="onCommentsToggle">
        <img src="@/assets/images/icons/comment.svg"/>
        <span>{{ toSiFormat(commentsNum) }}</span>
      </span>

      <span class="post__info-share" @click="onCopyShareLink">
        <img src="@/assets/images/icons/share.svg"/>
      </span>
    </div>

    <div class="post__actions" v-if="!isViewOnly">
      <router-link
        :to="`/app/creator-post?pid=${post._id}&type=${bundle ? 'bundle' : 'post'}`"
        class="post__actions_type_edit"
      >
        <img src="@/assets/images/icons/edit.svg"/>
      </router-link>
    </div>
    <div v-else-if="!isLocked && !post.blocked">
      <span class="post__info-tip">
        <img src="@/assets/images/icons/tip.svg" @click="onTipAction"/>
      </span>
      <span class="post__info-report" @click="onReportAction">
        <img src="@/assets/images/icons/report.svg"/>
      </span>
    </div>
  </div>
  <PostComments
    v-if="isCommentsVisible"
    :postId="post._id"
    @incrementCommentsCounter="onCounterIncrement"
  />

  <Popup v-if="isModalVisible" @close="onPaymentClose">
    <USAepayForm
      title="Tip"
      canSetPrice
      @paid="onPayForMessage"
      :hasSavedCard="hasSavedCard"
    />
  </Popup>

  <!-- Report issue -->
  <Popup v-if="isPopupVisible" class="text-center" @close="onPopupClose">
    <h4 class="sub-header text-center">Report Post</h4>
    <SelectBox
      class="mt-20"
      placeholder="Select your report reason"
      :options="postReportsList"
      @onSelect="onReportSelect"
    />
    <Button
      class="mt-20" size="m" type="green" text="Report" rounded
      @onClick="onReportSend"
    />
  </Popup>
</template>

<script>
import { useStore } from 'vuex';
import { toSiFormat } from '@/utils';
import { common, consumer } from '@/api';
import { ref, watch } from 'vue';
import textConfig from '@/config/text';
import { SelectBox, Button } from '@/components/atoms';
import PostComments from '@/components/PostComments';
import Popup from '@/components/Popup';
import USAepayForm from '@/components/USAepayForm';

export default {
  name: 'PostActions',
  props: {
    post: Object,
    noActions: Boolean,
    isLocked: Boolean,
    bundle: Array,
    isViewOnly: Boolean,
    hasSavedCard: Boolean,
  },
  components: { PostComments, Popup, USAepayForm, SelectBox, Button },
  setup({ post }) {
    const store = useStore();
    const likesNum = ref(post.likesNum);
    const commentsNum = ref(post.commentsNum);
    const isPostLiked = ref(null);
    const isCommentsVisible = ref(false);
    const isModalVisible = ref(false);
    const isPopupVisible = ref(false);
    const reportText = ref('');

    watch(() => store.state.app.auth?.user?.likes, (likes) => {
      setIfPostLiked(likes);
    });

    const onLikeAction = async () => {
      const { data } = await common.likePost({ pid: post._id });

      if (data) {
        await store.dispatch('app/addPostLike', data);
        likesNum.value += 1;
      } else {
        await store.dispatch('app/deletePostLike', post._id);
        likesNum.value -= 1;
      }
    }

    const onPayForMessage = async ({token, price}) => {
      await consumer.payForTip({
        token,
        tipAmount: price,
        modelId: post.author
      }).catch((error) => {
        if (error.response.data.error) {
          alert(error.response.data.error);
        }
      });

      onPaymentClose();
    }

    const onPaymentClose = () => {
      isModalVisible.value = false;
    }

    const onTipAction = () => {
      isModalVisible.value = true;
    }

    const onCopyShareLink = () => {
      navigator.clipboard.writeText(`${window.location.origin}/${post.author.username}`).then(() => {
        store.dispatch('message/show', {
          text: 'Model\'s profile link has been copied!'
        });
      });
    }

    const onCounterIncrement = () => {
      commentsNum.value += 1;
    }

    const onCommentsToggle = () => {
      isCommentsVisible.value = !isCommentsVisible.value;
    }

    const setIfPostLiked = (data) => {
      const likes = data || store.state.app.auth?.user?.likes;

      isPostLiked.value = false;

      if (likes) {
        likes.forEach((like) => {
          if (like.post === post._id) {
            isPostLiked.value = true;
          }
        });
        if (!likes.length) {
          isPostLiked.value = false;
        }
      } else {
        isPostLiked.value = false;
      }
    }

    const onReportAction = () => {
      isPopupVisible.value = true;
    }

    const onReportTextInput = (text) => {
      reportText.value = text;
    }

    const onReportSend = async () => {
      await common.postReport({
        mid: store.getters['app/userID'],
        pid: post._id,
        report: reportText.value,
      });
      onPopupClose();
    }

    const onPopupClose = () => {
      isPopupVisible.value = false;
    }

    const onReportSelect = (reportValue) => {
      reportText.value = reportValue;
    }

    return {
      postReportsList: textConfig.postReportsList,
      toSiFormat,
      onLikeAction,
      isPostLiked,
      likesNum,
      commentsNum,
      onCounterIncrement,
      onCommentsToggle,
      isCommentsVisible,
      onCopyShareLink,
      isModalVisible,
      onTipAction,
      onPayForMessage,
      onPaymentClose,
      reportText,
      onReportAction,
      onReportTextInput,
      onReportSend,
      onReportSelect,
      isPopupVisible,
      onPopupClose,
    }
  },
}
</script>

<style lang="scss" scoped>
  .post {
    &__info-like,
    &__info-comment {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;

      img, span {
        vertical-align: middle;
      }

      img {
        width: 16px;
      }

      span {
        margin-left: 5px;
      }
    }

    &__info-comment {
      margin-right: 35px;
    }

    &__info-share {
      img {
        width: 20px;
        position: relative;
        top: 2px;
      }
    }

    &__info-report {
      img {
        height: 16px;
      }
    }

    &__info-tip {
      margin-right: 30px;

      img {
        height: 18px;
        vertical-align: top;
      }
    }

    &__actions_type_edit {
      img {
        width: 20px;
      }
    }
  }
</style>

<template>
  <h2 class="header text-center text-uppercase">Live Now</h2>
  <div class="model-cards">
    <div v-if="!streams.length" class="text-center text">
      No streams
    </div>
    <ModelCard
      v-else
      :key="stream.id"
      v-for="stream in streams"
      :data="{ ...stream.model, url: `/app/live-stream?id=${stream.streamId}` }"
    />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { consumer } from '@/api';
  import { ModelCard } from '@/components';

  export default {
    name: 'LiveModelsPage',
    components: { ModelCard },
    setup() {
      const streams = ref([]);

      onMounted(async () => {
        const streamsData = await consumer.getStreams();

        streams.value = streamsData.data;
      });

      return { streams };
    }
  }
</script>

<style lang="scss" scoped>
.header {
  margin: 20px;
}

.menu {
  padding: 20px 30px;

  background: #141414;
  list-style-type: none;

  &__item {
    display: inline-block;

  }

  .link {
    font-weight: bold;
  }
}
</style>

import axios from 'axios';
import { auth } from '@/api';
import { deleteAllCookies } from '@/utils';

const state = {
  auth: {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
  },
  isSidebarVisible: false,
  unread: false,
  notifications: [],
  soundLevel: localStorage.getItem('soundLevel') || 0.2
}

const getters = {
  userRole: () => {
    return state.auth.user && state.auth.user.currentRole;
  },
  userID: () => {
    return state.auth.user.id;
  },
  userInfo: () => {
    return state.auth.user;
  },
  paidPosts: () => {
    return state.auth.user && state.auth.user.boughtPosts;
  },
}

const actions = {
  async signUp ({ commit }, params) {
    const { data } = await auth.signUp(params);
    saveAuthData(commit, data);
    return data;
  },

  async signIn ({ commit } , params) {
    const { data } = await auth.signIn(params);
    saveAuthData(commit, data);
    return data;
  },

  async refreshToken ({ commit } , data) {
    saveAuthData(commit, data);
  },

  async sendVerificationCode (_, params) {
    const { data } = await auth.sendVerificationCode(params);
    return data;
  },

  async codeVerification (_, params) {
    const { data } = await auth.codeVerification(params);
    return data;
  },

  async sendResetCode (_, params) {
    const { data } = await auth.sendResetCode(params);
    return data;
  },

  async resetPassword (_, params) {
    const { data } = await auth.resetPassword(params);
    return data;
  },

  async updateUser ({ commit }, {uid, params}) {
    const { data } = await auth.updateUser({uid, params});

    commit('UPDATE_USER', params);

    return data;
  },

  addFollower ({ commit }, params) {
    commit('ADD_FOLLOWER', params);
  },

  deleteFollower ({ commit }, params) {
    commit('DELETE_FOLLOWER', params);
  },

  addSubscriber ({ commit }, params) {
    commit('ADD_SUBSCRIBER', params);
  },

  deleteSubscriber ({ commit }, params) {
    commit('DELETE_SUBSCRIBER', params);
  },

  addBoughtPost ({ commit }, params) {
    commit('ADD_BOUGHT_POST', params);
  },

  addPostLike ({ commit }, params) {
    commit('ADD_POST_LIKE', params);
  },

  deletePostLike ({ commit }, params) {
    commit('DELETE_POST_LIKE', params);
  },

  unread({ commit }, params) {
    commit('SET_UNREAD', params);
  },

  addNotifications ({ commit }, params) {
    commit('ADD_NOTIFICATIONS', params);
  },

  readNotifications ({ commit }, params) {
    commit('READ_NOTIFICATIONS', params);
  },

  setSoundLevel({ commit }, value) {
    commit('SET_SOUND_LEVEL', value);
  }
}

const mutations = {
  SET_AUTH(state, data) {
    state.auth = { ...state.auth, ...data };
  },

  async LOGOUT(state) {
    state.auth = {
      token: null,
      user: null,
    };
    state.isSidebarVisible = false;

    deleteAllCookies();
    localStorage.clear();
    document.cookie = '';
    if (window.__socket) {
      window.__socket.close();
    }
    await auth.logout();
  },

  UPDATE_USER(state, data) {
    state.auth.user = {
      ...state.auth.user, ...data,
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  SET_SIDEBAR_VISIBILITY(state, visibilityStatus) {
    state.isSidebarVisible = visibilityStatus;
  },

  ADD_FOLLOWER(state, fid) {
    state.auth.user = {
      ...state.auth.user,
      followed: [...state.auth.user.followed, fid],
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  DELETE_FOLLOWER(state, fid) {
    state.auth.user = {
      ...state.auth.user,
      followed: state.auth.user.followed.filter((item) => item !== fid)
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  ADD_SUBSCRIBER(state, subscriber) {
    state.auth.user = {
      ...state.auth.user,
      subscribed: [...state.auth.user.subscribed, subscriber],
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  DELETE_SUBSCRIBER(state, sid) {
    state.auth.user = {
      ...state.auth.user,
      subscribed: state.auth.user.subscribed.filter((item) => item.model !== sid)
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  ADD_BOUGHT_POST(state, pid) {
    state.auth.user = {
      ...state.auth.user,
      boughtPosts: state.auth.user.boughtPosts ? [...state.auth.user.boughtPosts, pid] : [pid],
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  ADD_POST_LIKE(state, like) {
    state.auth.user = {
      ...state.auth.user,
      likes: state.auth.user.likes ? [...state.auth.user.likes, {...like}] : [{...like}],
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  DELETE_POST_LIKE(state, pid) {
    console.log(pid, state.auth.user.likes);
    state.auth.user = {
      ...state.auth.user,
      likes: state.auth.user.likes.filter((item) => item.post !== pid)
    };
    localStorage.setItem('user', JSON.stringify(state.auth.user));
  },

  SET_UNREAD(state, data) {
    state.unread = data;
  },

  ADD_NOTIFICATIONS(state, data) {
    state.notifications = [...state.notifications, ...data];
  },

  READ_NOTIFICATIONS(state) {
    state.notifications = [];
  },

  SET_SOUND_LEVEL(state, value) {
    localStorage.setItem('soundLevel', value);
    state.soundLevel = value;
  }
}

function saveAuthData(commit, data) {
  if (data.exists) {
    throw data;
  }

  const token = `${data.token.tokenType} ${data.token.accessToken}`;

  if (data.user) {
    commit('SET_AUTH', {token, user: data.user});
  } else {
    commit('SET_AUTH', { token });
  }

  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', data.token.refreshToken);

  if (data.user) {
    localStorage.setItem('user', JSON.stringify(data.user));
  }

  axios.defaults.headers.common['Authorization'] = token;
}

export default {
  namespaced: true,
  state, getters, actions, mutations,
};

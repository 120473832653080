<template>
  <div class="posts">
    <h3 class="sub-header text-center">FEED</h3>

    <div v-if="!feed?.length" class="text-center mb-20">-</div>
      <ModelPost
        :isOwn="isOwn"
        isViewOnly
        v-for="post in feed"
        :post="post"
        :key="post._id"
      />
    <div class="spinner-container" v-if="isFeedLoading">
      <img src="@/assets/images/spinner.svg"/>
    </div>
  </div>
</template>

<script>
  import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
  import { useStore } from 'vuex';
  import { ModelPost } from '@/components';

  export default {
    name: 'ModelPagePosts',
    components: { ModelPost },
    setup() {
      const store = useStore();
      const container = ref(null);
      const isFeedLoading = ref(false);
      const isOwn = computed(() => {
        const role = store.getters['app/userRole'];
        return role === 'creator';
      })

      const feed = ref([]);

      watch(() => store.state.consumer.modelPostsList, data => feed.value = data);

      const getModelFeed = async () => {
        await store.dispatch('consumer/getCurrentModelFeed',
        { next: true, uid: store.state.consumer.viewingModel.model._id });
      }

      const getFeed = async () => {
        if (store.state.consumer.modelPosts.nextPage) {
          isFeedLoading.value = true;
          await getModelFeed();
          isFeedLoading.value = false;
        }
      }

      const onContentScroll = () => {
        const contentHeight = container.value.offsetHeight;

        if (!isFeedLoading.value && contentHeight > innerHeight) {
          if (contentHeight - document.documentElement.scrollTop < window.innerHeight) {
            getFeed();
          }
        }
      };

      onMounted(async () => {
        isFeedLoading.value = true;
        await getModelFeed();
        isFeedLoading.value = false;

        window.addEventListener('scroll', onContentScroll);
        container.value = document.querySelector('.content');
      });

      onUnmounted(async () => {
        window.removeEventListener('scroll', onContentScroll);
        await store.dispatch('consumer/clearCurrentModelFeed');
      });

      return { feed, isFeedLoading, isOwn };
    }
  }
</script>

<style scoped>
  .spinner-container {
    display: flex;
    justify-content: center;
  }

  .posts {
    background: #111;
  }

  .sub-header {
    padding-top: 30px;
  }
</style>

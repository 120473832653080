  <template>
  <h1 class="header text-center text-uppercase">My Account</h1>
  <h2 class="sub-header text-center text-uppercase">Profile settings</h2>

  <Avatar class="avatar" :image="avatar" isEditable role="consumer"/>

  <form class="form">
    <div class="form__group" v-if="data.fields.email">
      <Input
          disabled
          :value="data.fields.email"
          placeholder="Email"
          @onInput="onInput('email', $event)"
      />
    </div>
    <div class="form__group" v-if="data.fields.phone">
      <Input
          disabled
          :value="data.fields.phone"
          placeholder="Phone"
          @onInput="onInput('phone', $event)"
      />
    </div>
    <div class="form__group">
      <Input
          :value="data.fields.firstName"
          placeholder="First name"
          @onInput="onInput('firstName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
          :value="data.fields.lastName"
          placeholder="Last name"
          @onInput="onInput('lastName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
          :value="data.fields.username"
          placeholder="Username"
          @onInput="onUsernameCheck('username', $event)"
      />
      <div v-if="isUsernameAvailable === 'available'" class="username--available">Username is available</div>
      <div v-else-if="isUsernameAvailable === 'in use'" class="username--in-use">Username already in use</div>
    </div>

    <div class="form__group">
      <Button text="Save changes" class="mb-20" @onClick="onProfileUpdate"/>
    </div>
  </form>
</template>

<script>
  import { watch, reactive, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Input, Button, Avatar } from '@/components';
  import { throttle } from '@/utils';
  import { common } from '@/api';

  export default {
    name: 'ConsumerAccount',
    components: { Input, Button, Avatar },
    setup() {
      const store = useStore();
      const creatorData = reactive({
        fields: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
        },
      });
      const originUsername = ref(null);
      const isUsernameAvailable = ref(null);
      const avatar = ref(store.state.consumer.profile.member.picture);

      const onInput = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onProfileUpdate = async (event) => {
        event.preventDefault();

        const { fields } = creatorData;

        await store.dispatch(
          'consumer/updateProfileInfo',
          {
            member: {
              first_name: fields.firstName,
              last_name: fields.lastName,
              username: fields.username
            },
          },
        ).then(() => {
          originUsername.value = fields.username;
          isUsernameAvailable.value = null;

          store.dispatch('message/show', {
            text: 'You successfully updated your profile',
            type: 'info'
          });
        });
      };

      const onUsernameCheck = throttle(async (type, value) => {
        if (!value) {
          isUsernameAvailable.value = null;
          return false;
        }

        const { data } = await common.checkUsernameAvailability({
          role: 'model',
          username: value,
        });

        if (data.status === 'available') {
          creatorData.fields[type] = value;
        }

        isUsernameAvailable.value = data.status;

        if (originUsername.value === value) {
          isUsernameAvailable.value = null;
        }
      }, 1000);

      watch(() => store.state.consumer.profile, (profile) => {
        creatorData.fields.lastName = profile.member.last_name;
        creatorData.fields.firstName = profile.member.first_name;
        creatorData.fields.username = profile.member.username;
        creatorData.fields.email = profile.email;
        creatorData.fields.phone = profile.phone;

        originUsername.value = profile.member.username;
      });

      onMounted(async () => {
        await store.dispatch('consumer/getOwnProfileInfo');
      });

      return {
        data: creatorData, isUsernameAvailable,
        onInput, onProfileUpdate, onUsernameCheck,
        avatar,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 20px;
  }

  .username {
    &--available,
    &--in-use {
      position: relative;
      top: 6px;

      font-size: 12px;

      color: #31b131;
    }

    &--in-use {
      color: #e64242;
    }
  }
</style>

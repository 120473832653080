import axios from 'axios';

export default {
  signUp(data) {
    return axios.post('/auth/register', data);
  },
  refreshToken(data) {
    return axios.post('/auth/refresh-token', data);
  },
  sendVerificationCode({type, action}) {
    return axios.get(`/users/confirm_code/${type}/${action}`);
  },
  codeVerification({type, action, code}) {
    return axios.get(`/users/confirm_code/${type}/${action}/${code}`);
  },
  signIn(data) {
    return axios.post('/auth/login', data);
  },
  sendResetCode(data) {
    return axios.post('/auth/send-password-reset', data);
  },
  resetPassword(data) {
    return axios.post('/auth/reset-password', data);
  },
  getUserByCookie() {
    return axios.get('/users/user-by-cookie', {
      withCredentials: true,
    });
  },
  updateUser({uid, params}) {
    return axios.patch(`/users/${uid}`, params);
  },
  logout() {
    return axios.get('/auth/logout');
  }
}

<template>
  <div
    :class="['notification', `notification_type_${message.type}`]"
    v-if="message.isVisible"
  >
    <span>{{message.text}}</span>
  </div>
</template>

<script>
  import { reactive, watch } from 'vue';
  import { useStore } from 'vuex';

  export default {
    name: 'Notification',
    setup() {
      const message = reactive({
        text: 'Message',
        type: 'info',
      });
      const store = useStore();

      watch(() => store.state.message.data, (data) => {
        message.text = data.text;
        message.type = data.type || 'info';
        message.isVisible = data.isVisible;
      });

      return { message };
    }
  }
</script>

<style lang="scss" scoped>
  .notification {
    position: fixed;
    top: 0;
    z-index: 32001;
    width: 100%;
    padding: 20px;

    color: #FFF;
    background: #ce0505;
    box-shadow: 0 0 10px #000;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: 1px 1px 0 #000;

    &_type_info {
      background: #049e04;
    }

    &_type_error {
      background: #ce0505;
    }
  }
</style>

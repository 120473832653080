const MESSAGE_SHOW_TIMEOUT = 4000;
let timeout = null;

const state = {
  data: {
    text: '',
    type: null,
    isVisible: false,
  }
}

const getters = {};

const actions = {
  show ({ commit }, data) {
    commit('SET_MESSAGE', { ...data, isVisible: true });

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      commit('SET_MESSAGE', { isVisible: false, text: '' });
    }, MESSAGE_SHOW_TIMEOUT);
  },
}

const mutations = {
  SET_MESSAGE(state, data) {
    state.data = { ...state.data, ...data };
  },
}

export default {
  namespaced: true,
  state, getters, actions, mutations,
};

export default {
  routerHandler: (router) => {
    // scrollBehavior: () => {
    //   window.scrollTo(0, 0);
    // }
    router.beforeEach((to, from, next) => {
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if (token && user) {
        const { email_confirmed, phone_confirmed, currentRole } = JSON.parse(user);

        /* Show pages only for ROLES that are permitted */
        if (to.meta.roles && !to.meta.roles.includes(currentRole)) {
          if (!to.meta.public) {
            next('/');
          }
        }

        if (to.path === '/') {
          if (email_confirmed || phone_confirmed) {
            next('/app');
          }
          next();
        }
        next();
      } else {
        if (to.matched.some(record => record.meta.auth)) {
          next('/');
        }
        next();
      }
    })
  }
}

import axios from 'axios';

export default {
  getS3UploadURL(params) {
    return axios.post('/media/get-upload-url', params);
  },
  getPublicOptions(params) {
    return axios.get('/public/options', params);
  },
  reportForS3SuccessUpload(params) {
    return axios.post('/media/upload-success', params);
  },

  /*
    @params.role {('model' | 'member')}
    @params.username {String}
   */
  checkUsernameAvailability(params) {
    return axios.post('/users/check-user-name', params);
  },

  likePost(params) {
    return axios.post(`/users/like-post/${params.pid}`);
  },

  postReport({ mid, pid, report}) {
    return axios.post(`/users/model/${mid}/post/${pid}/report`, { report });
  },

  getBillingActivity() {
    return axios.get('/users/billing_activity');
  },

  getNotificationsList() {
    return axios.get('/users/notify/get');
  },

  clearNotificationsList() {
    return axios.get('/users/notify/clear');
  },

  findUsers() {
    return axios.get('/users');
  },
}

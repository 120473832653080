<template>
  <h1 class="header text-center text-uppercase">E Wallet</h1>
  <h2 v-if="hasSavedCard" class="sub-header text-center text-uppercase">
    SAVED CREDIT CARDS
  </h2>
  <div class="credit-cards" v-if="hasSavedCard">
    <div class="credit-cards__card">
      <p class="credit-cards__card-num text-center">
        {{creditCardInfo}}
      </p>
      <Button
        class="credit-cards__card-remove"
        text="Delete" type="red" size="extra-small" rounded
        @click="onCardDelete"
      />
    </div>
  </div>
  <div class="no-cards text-center" v-else>
    <p class="text text-center mt-20">You don't have saved credit cards</p>
    <Button
        class="add-credit-card mt-20"
        text="+ Add Credit Card" type="red" size="extra-small" rounded
        @click="onCardAdd"
    />
  </div>

  <Popup v-if="isPaymentPopupVisible" @close="onPaymentClose">
    <USAepayForm title="Add Credit Card" @paid="onCardAttached" buttonText="Add"/>
  </Popup>
</template>

<script>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { consumer } from '@/api';
  import {
    Button,
    Popup,
    USAepayForm
  } from '@/components';

  export default {
    name: 'PaymentsPage',
    components: {
      Button,
      Popup,
      USAepayForm
    },
    setup() {
      const store = useStore();
      const hasSavedCard = ref(store.state.consumer.profile.member.lastTransaction);
      const creditCardInfo = ref(store.state.consumer.profile.member.creditCard);
      const isPaymentPopupVisible = ref(false);

      const onCardDelete = async () => {
        await consumer.deleteSavedCC();
        await store.dispatch('consumer/updateProfileInfo', {
          member: {
            lastTransaction: null,
            creditCard: null,
          }
        });
        hasSavedCard.value = null;
      }

      const onCardAdd = () => {
        isPaymentPopupVisible.value = true;
      }

      const onPaymentClose = () => {
        isPaymentPopupVisible.value = false;
      }

      const onCardAttached = async ({token}) => {
        const { data } = await consumer.addCreditCard({token});

        const { creditCard } = data;

        hasSavedCard.value = true;
        creditCardInfo.value = creditCard;

        await store.dispatch(
          'consumer/updateProfileInfo',
          {
            member: {
              creditCard,
              lastTransaction: Date.now(),
            },
          },
        );

        onPaymentClose();
      }

      onMounted(async () => {
        const resp = await store.dispatch('consumer/getOwnProfileInfo');

        hasSavedCard.value = resp.member.lastTransaction;
        creditCardInfo.value = resp.member.creditCard;
      });

      return {
        isPaymentPopupVisible,
        hasSavedCard,
        creditCardInfo,
        onCardDelete,
        onCardAdd,
        onPaymentClose,
        onCardAttached
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 40px 0;
  }

  .credit-cards {
    margin: 20px 0;

    &__card {
      width: 200px;
      margin: 0 auto;
    }

    &__card-num {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1em;
      color: #CBCBCB;
    }

    &__card-remove {
      width: 100px;
      margin: 10px auto;
    }
  }

  .add-credit-card {
    display: inline-block;
    width: 50%;
  }
</style>

import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/helpdesk',
    name: 'HelpdeskPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member', 'model'],
      isFullscreen: true,
    },
  },
];

<template>
  <footer class="footer d-flex">
    <div class="footer__icon">
      <router-link to="/app">
        <img src="@/assets/images/icons/home.svg"/>
      </router-link>
    </div>
    <div class="footer__icon" @click="onSidebarOpen">
      <img src="@/assets/images/icons/menu.svg"/>
    </div>
  </footer>
</template>

<script>
  import { useStore } from 'vuex';

  export default {
    name: 'AdminFooter',
    setup() {
      const store = useStore();

      const onSidebarOpen = () => {
        store.commit('app/SET_SIDEBAR_VISIBILITY', true);
      };

      return { onSidebarOpen };
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: fixed;
    width: 100%;
    height: 60px;
    bottom: 0;
    padding: 0 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      position: relative;
      height: 22px;

      img {
        height: 100%;
      }
    }
  }
</style>

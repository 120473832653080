<!-- TODO: need to refactor this component. Split into several sub-components -->
<template>
  <div class="post" ref="postItem">
    <PostHeader :post="post" :isOwn="isOwn" :isDiscountAvailable="isDiscountAvailable" />

    <!-- Media block -->
    <!-- TODO: remove post__media-wrapper duplicates -->
    <slot v-if="post.bundle && post.bundle.length">
      <div class="splide" ref="mediaList" v-if="!isLocked">
        <div class="post__media splide__track">
          <div class="splide__list">
            <div class="splide__slide post__media-wrapper" v-for="(item, index) in bundle" :key="item._id">
              <span v-if="!post.isPublished" class="post__draft-flag">Draft</span>
              <video
                v-if="item.videoSrc"
                :src="item.videoSrc"
                controls
                autoplay
              />
              <div class="post__audio-player" v-else-if="item.audioSrc">
                <audio controls autoplay>
                  <source :src="item.audioSrc" type="audio/mpeg">
                </audio>
              </div>
              <img
                :class="['post__media-img', {
                  'post__media-img-transparent': item.mediaType === 'pdf'
                }]"
                v-else-if="item.thumbnail"
                :src="item.thumbnail"
                @click="onMediaOpen($event, item, index)"
              />
              <span
                class="post__media_icon_video"
                v-if="item.mediaType === 'video' && !item.videoSrc"
                @click="onVideoPlay($event, item, index)"
              >
                <img src="@/assets/images/icons/video.svg"/>
              </span>
              <span class="post__media_icon_video"
                v-if="item.mediaType === 'audio' && !item.audioSrc"
                @click="onAudioPlay($event, item, index)"
              >
                <img src="@/assets/images/icons/video.svg"/>
              </span>
              <span
                class="post__media_icon_pdf"
                v-if="item.mediaType === 'pdf' && !item.pdfSrc"
                @click="onDocOpen($event, item, index)"
              >
                <img src="@/assets/images/icons/document.svg"/>
              </span>
            </div>
          </div>
        </div>
      </div>
      <LockedMedia
        :post="post"
        :isDiscountAvailable="isDiscountAvailable"
        :hasSavedCard="hasSavedCard"
        v-else
      />
    </slot>

    <slot v-else>
      <div class="post__media" v-if="!isLocked">
        <div class="post__media-wrapper">
          <span v-if="!post.isPublished" class="post__draft-flag">Draft</span>
          <video
            v-if="videoSrc"
            :src="videoSrc"
            :poster="post.media.thumbnail"
            controls
            autoplay
          />
          <div class="post__audio-player" v-else-if="audioSrc">
            <audio controls autoplay>
              <source :src="audioSrc" type="audio/mpeg">
            </audio>
          </div>
          <img :class="['post__media-img', {
              'post__media-img-transparent': isPDFType
            }]"
            v-else-if="hasMedia"
            :src="post.media.thumbnail"
            @click="onMediaOpen"
          />
          <span class="post__media_icon_video"
            v-if="isVideoType && !videoSrc"
            @click="onVideoPlay"
          >
            <img src="@/assets/images/icons/video.svg"/>
          </span>
          <span class="post__media_icon_video"
            v-if="isAudioType && !audioSrc"
            @click="onAudioPlay"
          >
              <img src="@/assets/images/icons/video.svg"/>
            </span>
          <span class="post__media_icon_pdf"
            v-if="isPDFType && !pdfSrc"
            @click="onDocOpen"
          >
            <img src="@/assets/images/icons/document.svg"/>
          </span>
        </div>
      </div>
      <LockedMedia :post="post" :isDiscountAvailable="isDiscountAvailable" :hasSavedCard="hasSavedCard" v-else />
    </slot>


    <PostActions v-if="!noActions" :post="post" :bundle="bundle" :isLocked="isLocked" :isViewOnly="isViewOnly" :hasSavedCard="hasSavedCard" />


    <!-- View post Image -->
    <PostPreview :isPreviewOpened="isPreviewOpened" :imageToView="imageToView" :isPDFType="isPDFType" :onMediaClose="onMediaClose"/>
  </div>
</template>

<script>
  import { ref, watch, computed, nextTick, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import panzoom from 'panzoom';
  import Splide from '@splidejs/splide';

  import { creator } from '@/api';
  import { debounce } from '@/utils';
  import PostHeader from './PostHeader';
  import PostPreview from './PostPreview';
  import PostActions from './PostActions';
  import LockedMedia from './LockedMedia';

  export default {
    name: 'ModelPost',
    props: {
      isViewOnly: {
        type: Boolean,
        default: false,
      },
      post: Object,
      isOwn: Boolean,
      noActions: {
        type: Boolean,
        default: false
      },
    },
    components: {
      PostHeader,
      LockedMedia,
      PostPreview,
      PostActions,
    },
    setup({ post, isOwn }) {
      const store = useStore();
      const hasMedia = post.media && post.media.thumbnail;
      const videoSrc = ref(null);
      const audioSrc = ref(null);
      const pdfSrc = ref(null);
      const isPreviewOpened = ref(false);
      const postItem = ref(null);
      const imageToView = ref(post.media && post.media.thumbnail);
      const mediaList = ref();
      const hasSavedCard = ref();
      const bundle = ref(post.bundle ? [...post.bundle.map((item) => ({...item}))] : null);
      const isDiscountAvailable = computed(() => post.promotion_end > new Date().toISOString());
      let isVideoType = ref(null);
      let isAudioType = ref(null);
      let isPDFType = ref(null);

      if (post.media) {
        isVideoType = computed(() => post.media.mediaType === 'video');
        isAudioType = computed(() => post.media.mediaType === 'audio');
        isPDFType = computed(() => post.media.mediaType === 'pdf');
      }

      const checkIsLocked = () => {
        const user = store.state.app?.auth?.user
        const hasSubscription = user?.subscribed?.length && user.subscribed.find((item) => item.model === post.author._id);
        const isBought = user?.boughtPosts?.length && user?.boughtPosts.find((item) => item === post._id);

        if (isBought) return false;
        if (hasSubscription && post.price === 0) return false;
        if (isOwn) return false;
        if (!post.isPublic) return true;

        return false;
      }

      const isLocked = ref(checkIsLocked(post));

      watch(() => store.state.app.auth?.user, () => {
        isLocked.value = !isOwn && checkIsLocked(post);
      });

      watch(() => mediaList.value, () => {
        splideInit();
      });

      const openFullscreen = (elem) => {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }

      const onVideoPlay = async (event, item, index) => {
        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid: post._id,
        });

        if (item) {
          bundle.value[index].videoSrc = data[index];
        } else {
          videoSrc.value = data;
        }

        nextTick(() => {
          const video = postItem.value.querySelector('video');

          video.volume = store.state.app.soundLevel;

          video.addEventListener('volumechange', debounce(() => {
            store.dispatch('app/setSoundLevel', video.volume);
          }, 500));

          openFullscreen(video);
        });
      };

      const onAudioPlay = async (event, item, index) => {
        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid: post._id,
        });

        if (item) {
          bundle.value[index].audioSrc = data[index];
        } else {
          audioSrc.value = data;
        }
      }

      const onDocOpen = async (event, item, index) => {
        // duct tape for async url to be accessible on mobile devices
        const windowOpen = window.open();

        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid: post._id,
        });

        windowOpen.location = index ? data[index] : data;
      }

      const onMediaOpen = async (event, item, index) => {
        if (isVideoType.value) {
          return false;
        }

        isPreviewOpened.value = true;

        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid: post._id,
        });
        imageToView.value = null;

        const image = new Image();

        image.onload = () => {
          if (post.bundle && post.bundle.length) {
            imageToView.value = data[index];
          } else {
            imageToView.value = data;
          }

          nextTick(() => {
            panzoom(postItem.value.querySelector('.post-preview__content-img'), { maxZoom: 3, minZoom: 1, bounds: true, boundsPadding: 0.3 });
          });
        };

        if (post.bundle && post.bundle.length) {
          image.src = data[index];
        } else {
          image.src = data;
        }
      }

      const onMediaClose = () => {
        isPreviewOpened.value = false;
      }

      const splideInit = () => {
        if (mediaList.value) {
          new Splide(mediaList.value, {
            type: 'loop',
            width: '100%',
            perPage: 1,
          }).mount();
        }
      }

      onMounted(() => {
        if (store.state.consumer.profile) {
          hasSavedCard.value = Boolean(store.state.consumer.profile.member.lastTransaction);
        }

        if (post.bundle && post.bundle.length) {
          splideInit();
        }
      });

      return {
        postItem,
        hasMedia,
        isVideoType,
        isAudioType,
        isPDFType,
        videoSrc,
        audioSrc,
        pdfSrc,
        isPreviewOpened,
        mediaList,
        imageToView,
        hasSavedCard,
        bundle,
        onAudioPlay,
        onVideoPlay,
        onDocOpen,
        onMediaOpen,
        onMediaClose,
        isLocked,
        isDiscountAvailable,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .post {
    padding: 32px 16px;
    margin: 0;
    background: none;
    border-bottom: 1px solid #555;

    &:first-child {
      margin-top: 0;
    }

    &__media {
      position: relative;
      min-height: 200px;
      padding: 16px 0;
    }

    &__media-wrapper {
      height: 100%;
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;

      img, video {
        width: auto;
        height: auto;
        max-height: 60vh;
        max-width: 100%;
        display: inline-block;
      }

      &-img-transparent {
        opacity: 0.3;
      }
    }

    &__media_icon_video,
    &__media_icon_pdf {
      display: block;
      position: absolute;
      width: 81px;
      top: 50%;
      left: 50%;
      margin: -30px 0 0 -40px;

      opacity: 0.5;
    }

    &__media_icon_pdf {
      text-align: center;
      width: 156px;
      margin: -68px 0 0 -73px;
      padding: 50px;
      opacity: 0.5;

      img {
        background: none;
      }
    }

    &__draft-flag {
      display: inline-block;
      position: absolute;
      right: 2px;
      top: 10px;

      padding: 2px 10px;
      margin: 0 6px;
      width: 90px;

      font-family: Roboto;
      color: #FFF;
      font-weight: bold;
      font-size: 9px;
      letter-spacing: 0.3em;
      text-align: center;
      background: #d63e34;
      text-transform: uppercase;
    }

    &__audio-player {
      audio {
        position: absolute;
        bottom: 10px;
        top: 50%;
        width: 90%;
        height: 40px;

        margin-left: -45%;
        margin-top: -20px;
      }
    }
  }
</style>

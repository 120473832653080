<template>
  <div v-if="!isFullScreen" class="auth-page d-flex">
    <component :is="ComponentNames.header"/>
    <div class="content">
      <component :is="pageComponent"/>
    </div>
    <component :is="ComponentNames.footer"/>
  </div>

  <div v-else class="auth-page d-flex">
    <component :is="ComponentNames.header"/>
    <div class="content content_type_fullscreen">
      <component :is="pageComponent"/>
    </div>
  </div>

  <component :is="ComponentNames.sidebar" :isVisible="isSidebarVisible" v-if="isSidebarReadyToRender"/>
</template>

<script>
  import { computed, onBeforeMount, shallowRef, watch, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';

  import * as Pages from '@/components/pages';
  import Headers from '@/components/header';
  import { ConsumerFooter, CreatorFooter, AdminFooter } from '@/components/footer';
  import { CreatorSidebar, ConsumerSidebar, AdminSidebar } from '@/components/sidebar';

  export default {
    name: 'AuthPagesController',
    components: { ...Pages },
    setup() {
      const route = useRoute();
      const store = useStore();
      const isSidebarVisible = computed(() => store.state.app.isSidebarVisible);
      const isSidebarReadyToRender = ref(false);
      const isFullScreen = ref(route.meta.isFullscreen);
      const customHeader = ref(route.meta.header);
      const isDependOnRole = ref(route.meta.dependOnRole);
      const ComponentStruct = {
        member: {
          header: Headers.ConsumerHeader,
          footer: ConsumerFooter,
          sidebar: ConsumerSidebar
        },
        model: {
          header: Headers.CreatorHeader,
          footer: CreatorFooter,
          sidebar: CreatorSidebar
        },
        admin: {
          header: Headers.AdminHeader,
          footer: AdminFooter,
          sidebar: AdminSidebar
        }
      };
      let role = store.getters['app/userRole'];
      let ComponentNames = shallowRef({ ...ComponentStruct[role] });
      let pageComponent = shallowRef(null);

      const getPageComponent = (pageName) => {
        const page = {
          member: 'Consumer',
          model: 'Creator',
          admin: 'Admin',
        }[role];

        if (isDependOnRole.value) {
          pageComponent.value = `${page}${pageName}Page`;
        } else {
          pageComponent.value = pageName;
        }
      };

      const setHeaderComp = () => {
        if (isFullScreen.value) {
          ComponentNames.value.header = Headers.FullScreenHeader;
        } else {
          ComponentNames.value.header = ComponentStruct[role].header;
        }

        if (customHeader.value) {
          ComponentNames.value.header = Headers[customHeader.value];
        }
      };

      const initAuthLayout = async () => {
        if (role === 'model') {
          await store.dispatch('creator/getProfileInfo', {
            uid: store.getters['app/userID'],
          });
        } else if (role === 'member') {
          await store.dispatch('consumer/getOwnProfileInfo');
        }

        isDependOnRole.value = route.meta.dependOnRole;
        isSidebarReadyToRender.value = true;

        getPageComponent(route.name);

        isFullScreen.value = route.meta.isFullscreen;
        customHeader.value = route.meta.header;
        ComponentNames.value = { ...ComponentStruct[role] };

        setHeaderComp();
      };

      watch(() => route.name, (pageName) => {
        isDependOnRole.value = route.meta.dependOnRole;
        getPageComponent(pageName);
        isFullScreen.value = route.meta.isFullscreen;
        customHeader.value = route.meta.header;
        setHeaderComp();
      });

      /* If we current page was changed we need to be sure that sidebar is closed */
      watch(() => route.name, () => {
        if (isSidebarVisible) {
          store.commit('app/SET_SIDEBAR_VISIBILITY', false);
        }
      });

      /* If user switching between roles we should re-render all UI */
      watch(() => store.state.app.auth?.user?.currentRole,async (newRole) => {
        if (newRole) {
          role = newRole;
          await initAuthLayout();
        }
      });

      onBeforeMount(async () => {
        await initAuthLayout();
      });

      return {
        isFullScreen,
        isSidebarVisible, isSidebarReadyToRender,
        ComponentNames, pageComponent
      };
    }
  }
</script>

<style lang="scss" scoped>
  .auth-page {
    flex-direction: column;
    justify-content: space-between;
  }

  .content {
    margin: 60px 0;

    //overflow: hidden;
    //overflow-y: scroll;
    //height: calc(100vh - 120px);
    //
    &_type_fullscreen {
      margin: 0;
    }
  }
</style>

<template>
  <Popup @close="onClose" class="text-center">
    <h4 class="sub-header text-center">Report User</h4>
    <SelectBox
        class="mt-20"
        placeholder="Select your report reason"
        :options="options"
        @onSelect="onReportSelect"
    />
    <Button
        class="mt-20" size="m" type="green" text="Report" rounded
        @onClick="onReportSend"
    />
  </Popup>
</template>

<script>
  import {
    Popup,
    SelectBox,
    Button,
  } from '@/components/index';
  import textConfig from '@/config/text';

  export default {
    name: 'UserReport',
    components: {
      Popup,
      SelectBox,
      Button,
    },
    setup(_, { emit }) {
      let reportValue = null;

      const onClose = () => {
        emit('close');
      }

      const onReportSelect = (value) => {
        reportValue = value;
        emit('select', value);
      }

      const onReportSend = () => {
        emit('send', reportValue);
      }

      return {
        options: textConfig.userReportsList,
        onClose,
        onReportSelect,
        onReportSend,
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="model" v-if="modelData">
    <Cover :avatar="modelData.picture" :cover="modelData.home_settings.picture" :isOwner="false"/>
    <div class="top">
      <Statusbar :data="modelData"/>
      <Nickname :username="modelData.username"/>
      <div class="d-flex justify-center align-items-center  mt-20">
        <div class="text-center" v-if="isChatButtonEnable && !inModelBlackList">
          <router-link :to="`/app/messaging/${modelData.username}`" class="icon">
            <img src="@/assets/images/icons/messaging.svg"/>
          </router-link>
        </div>
        <div class="ml-40" v-if="role === 'member' && !inModelBlackList">
          <img src="@/assets/images/icons/tip.svg" @click="onTipAction"/>
        </div>
        <div class="share-link ml-40" @click="onCopyShareLink">
          <img src="@/assets/images/icons/share.svg"/>
        </div>
        <div class="ml-40" @click="onShowStreamEvents">
          <img src="@/assets/images/icons/calendar.svg"/>
        </div>
        <div class="report-link ml-40" @click="onReportWinShow" v-if="!inModelBlackList">
          <img src="@/assets/images/icons/report.svg"/>
        </div>
      </div>
      <ModelStatistics :statistics="modelData.statistics"/>
    </div>

    <div class="content text-center">
      <h4 class="sub-header">ABOUT ME</h4>
      <p class="text-italic"> {{ modelData.home_settings.about_me || '-' }} </p>

      <h4 class="sub-header">ATTRIBUTES</h4>
      <ModelAttributes
        :attributes="modelData.attributes"
        :home_settings="modelData.home_settings"
      />
    </div>

    <ModelPagePosts />
  </div>

  <Popup v-if="isPopupVisible" @close="onPopupClose">

    <!-- Showing payment process -->
    <div v-if="isPaymentProcessVisible">
      <USAepayForm
        :hasSavedCard="hasSavedCard"
        :title="`Subscribe for $${modelData.price_settings.subscriptions_monthly}`"
        :price="modelData.price_settings.subscriptions_monthly"
        @paid="onGetPaymentToken"
      />
    </div>

    <!-- If user wants to unsubscribe but following -->
    <div v-else-if="isUnsubscribingProcessVisible">
      <h4 class="sub-header text-center">Do you want to unfollow as well?</h4>
      <div class="actions mt-20">
        <Button
            class="mt-20" size="small" type="yellow" text="Yes" rounded
            @onClick="onUnsubscribeAndUnfollow"
        />
        <Button
            class="mt-20" size="small" type="red" text="No" rounded
            @onClick="onUnsubscribeOnly"
        />
      </div>
    </div>

    <!-- If user wants to unfollow but has subscription -->
    <div v-else-if="isUnfollowProcessVisible">
      <h4 class="sub-header text-center">Do you want to unsubscribe as well?</h4>
      <div class="actions mt-20">
        <Button
            class="mt-20" size="small" type="yellow" text="Yes" rounded
            @onClick="onUnsubscribeAndUnfollow"
        />
        <Button
            class="mt-20" size="small" type="red" text="No" rounded
            @onClick="onUnfollowOnly"
        />
      </div>
    </div>

    <!-- Showing follow or subscribe choice -->
    <div v-else>
      <h4 class="sub-header text-center">You can</h4>
      <div class="actions mt-20">
        <Button
          size="small" type="yellow" text="Continue as Free" rounded
          @onClick="onFollow"
        />
        <Button
          class="mt-20" size="small" type="yellow" text="Subscribe" rounded
          @onClick="onSubscribe"
        />
      </div>
    </div>
  </Popup>

  <!-- Send Tip -->
  <!-- TODO: move 'hasSavedCard' to the USAepayForm comp -->
  <Popup v-if="isTipPaymentVisible" @close="onPaymentClose">
    <USAepayForm
      title="Tip"
      canSetPrice
      @paid="onPayForMessage"
      :hasSavedCard="hasSavedCard"
    />
  </Popup>

  <Popup v-if="isStreamEventsVisible" @close="onStreamEventsClose">
    <h2 class="header text-uppercase text-center">Streams</h2>

    <div v-if="streamsList.length" class="events-list mt-40">
      <div
          class="event"
          v-for="event in streamsList"
          :key="event._id"
      >
        <div class="event__title text text-uppercase">
          {{event.title}}
        </div>
        <div class="event__description text">
          ({{event.description}})
        </div>
        <div class="event__date mt-10">
          {{formatEventDate(event)}}
        </div>
      </div>
    </div>
    <div v-else class="events-list text-center mt-40">
      <p class="text">No streams in scheduler</p>
    </div>
  </Popup>

  <UserReport
    v-if="reportWinVisible"
    @close="onReportWinHide"
    @send="onUserReportSend"
  />
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import moment from 'moment';

  import { consumer, creator } from '@/api';
  import {
    Button, Cover, Nickname, Statusbar, Popup, USAepayForm,
    ModelStatistics, ModelAttributes, ModelPagePosts, UserReport,
  } from '@/components';

  export default {
    name: 'ModelPage',
    components: {
      Button, Cover, Nickname, Statusbar, Popup, USAepayForm,
      ModelStatistics, ModelAttributes, UserReport, ModelPagePosts,
    },
    setup() {
      const STREAM_EVENS_CHUNKS_SIZE = 25;
      const route = useRoute();
      const store = useStore();
      const modelData = ref(null);
      const isPopupVisible = ref(false);
      const isPaymentProcessVisible = ref(false);
      const isUnsubscribingProcessVisible = ref(false);
      const isUnfollowProcessVisible = ref(false);
      const isChatButtonEnable = ref(false);
      const role = store.getters['app/userRole'];
      const hasSavedCard = ref(false);
      const isTipPaymentVisible = ref(false);
      const isStreamEventsVisible = ref(false);
      const streamsList = ref([]);
      const reportWinVisible = ref(false);
      const inModelBlackList = ref(true);

      const onPopupClose = async () => {
        await store.dispatch('consumer/setSubscribingProcess', false);
        await store.dispatch('consumer/setPaymentProcess', false);
        await store.dispatch('consumer/setUnsubscribeConfirmProcess', false);
        await store.dispatch('consumer/setUnfollowConfirmProcess', false);

        isPopupVisible.value = false;
        isPaymentProcessVisible.value = false;
      }

      const onFollow = async () => {
        const uid = store.state.consumer.viewingModel.model._id;
        await consumer.followModel({ uid });
        await store.dispatch('app/addFollower', uid);
        await onPopupClose();
      }

      const onGetPaymentToken = async ({token}) => {
        const uid = store.state.consumer.viewingModel.model._id;

        const subscriber = await consumer.payForSubscription({
          token,
          modelId: uid
        });

        if (subscriber.status != 'Error') {
          const model = store.state.consumer.viewingModel.model;
          await store.dispatch('app/addSubscriber', subscriber.data);

          const { data: { creditCard, lastTransaction }} = subscriber;

          await store.dispatch('consumer/updateViewingModelData', {
            ...model,
            statistics: {
              ...model.statistics,
              subscribers: model.statistics.subscribers + 1,
            }
          });

          await store.dispatch(
            'consumer/updateProfileInfo',
            {
              member: {
                creditCard,
                lastTransaction,
              },
            },
          );
        }

        /* Follow the model when user is subscribing */
        if (!store.state.app.auth.user.followed.includes(uid)) {
          await consumer.followModel({ uid });
          await store.dispatch('app/addFollower', uid);
        }

        isPaymentProcessVisible.value = false;

        await onPopupClose();
      }

      const onSubscribe = () => {
        isPaymentProcessVisible.value = true;
      }

      const onUnsubscribe = async () => {
        const uid = store.state.consumer.viewingModel.model._id;

        await consumer.unSubscribeModel({ uid })
        await store.dispatch('app/deleteSubscriber', uid);
      }

      const onUnFollow = async () => {
        const uid = store.state.consumer.viewingModel.model._id;

        await consumer.unfollowModel({ uid });
        await store.dispatch('app/deleteFollower', uid);
      };

      const onUnsubscribeOnly = async () => {
        await onUnsubscribe();
        await onPopupClose();
      }

      const onUnsubscribeAndUnfollow = async () => {
        await onUnsubscribe();
        await onUnFollow();
        await onPopupClose();
      }

      const onUnfollowOnly = async () => {
        await onUnFollow();
        await onPopupClose();
      }

      const checkCanChat = (data) => {
        if (role === 'member') {
          if (data) {
            const canChat = data.subscribed.find((sub) => {
              return sub.model === store.state.consumer.viewingModel.model._id;
            });

            isChatButtonEnable.value = Boolean(canChat);
          }
        }
      }

      const onTipAction = () => {
        isTipPaymentVisible.value = true;
      }

      const onPaymentClose = () => {
        isTipPaymentVisible.value = false;
      }

      const onPayForMessage = async ({token, price}) => {
        await consumer.payForTip({
          token,
          tipAmount: price,
          modelId: store.state.consumer.viewingModel.model._id,
        });
        onPaymentClose();
      }

      const onCopyShareLink = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
          store.dispatch('message/show', {
            text: 'Model\'s profile link has been copied!'
          });
        });
      }

      const onShowStreamEvents = async () => {
        isStreamEventsVisible.value = true;

        const res = await consumer.getModelStreamsScheduler({
          mid: store.state.consumer.viewingModel.model._id,
          limit: STREAM_EVENS_CHUNKS_SIZE,
          page: 1,
        });

        streamsList.value = res.data.docs;
      }

      const onStreamEventsClose = () => {
        isStreamEventsVisible.value = false;
      }

      const formatEventDate = (event) => {
        const date = moment(event.startAt);
        return `${date.format('DD of MMM')} at ${date.format('HH:mm')}`;
      }

      const onReportWinShow = () => {
        reportWinVisible.value = true;
      }

      const onReportWinHide = () => {
        reportWinVisible.value = false;
      }

      const onUserReportSend = (value) => {
        consumer.sendModelReport(
          store.state.consumer.viewingModel.model._id,
          {
            reportType: value,
          }
        );

        onReportWinHide();

        store.dispatch('message/show', {
          text: 'Report has been sent'
        });
      }

      watch(() => store.state.consumer.viewingModel, (data) => {
        modelData.value = data.model;
      });

      watch(() => store.state.consumer.isSubscribingProcess, (data) => {
        isPopupVisible.value = data;
      });

      watch(() => store.state.consumer.isPaymentProcess, (data) => {
        isPopupVisible.value = data;
        isPaymentProcessVisible.value = data;
      });

      watch(() => store.state.consumer.isUnsubscribeProcess, (data) => {
        isPopupVisible.value = data;
        isUnsubscribingProcessVisible.value = data;
      });

      watch(() => store.state.consumer.isUnfollowProcess, (data) => {
        isPopupVisible.value = data;
        isUnfollowProcessVisible.value = data;
      });

      watch(() => store.state.app.auth.user, (data) => {
        checkCanChat(data);
      });

      onMounted(async () => {
        await store.dispatch('consumer/getModelByUsername', {
          username: route.params.modelUsername
        });

        checkCanChat(store.getters['app/userInfo']);

        hasSavedCard.value = Boolean(store.state.consumer.profile.member.lastTransaction);

        if (store.getters['app/userInfo'].id !== store.state.consumer.viewingModel.model.user) {
          await creator.setModelView({
            uid: store.state.consumer.viewingModel.model.user
          });
        }

        inModelBlackList.value = store.state.consumer.viewingModel.model.blackList.includes(store.state.app.auth.user.id);
      });

      return {
        role,
        modelData,
        isChatButtonEnable,
        hasSavedCard,
        isPopupVisible,
        isPaymentProcessVisible,
        isUnsubscribingProcessVisible,
        isUnfollowProcessVisible,
        isTipPaymentVisible,
        isStreamEventsVisible,
        streamsList,
        reportWinVisible,
        inModelBlackList,
        onPopupClose,
        onFollow,
        onSubscribe,
        onUnsubscribeOnly,
        onUnsubscribeAndUnfollow,
        onUnfollowOnly,
        onGetPaymentToken,
        onTipAction,
        onPaymentClose,
        onPayForMessage,
        onCopyShareLink,
        onShowStreamEvents,
        onStreamEventsClose,
        formatEventDate,
        onReportWinShow,
        onReportWinHide,
        onUserReportSend,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .top {
    padding-top: 50px;

    background: #000;
  }

  .content {
    padding: 8px 28px;
  }

  .content .sub-header {
    margin: 20px 0;
  }

  .share-link,
  .report-link {
    height: 22px;
    margin-top: -5px;

    img {
      height: 100%;
    }
  }

  .events-list {
    margin: 30px 10px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .event {
    position: relative;
    margin: 10px 0;
    padding: 10px 15px;

    background: #000;
    font-family: Roboto;
    font-style: normal;

    &__title {
      padding-right: 40px;
      font-size: 16px;
    }

    &__description {
      margin-top: 5px;
      font-size: 11px;
      color: #CCC;
    }

    &__date {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #d5ae34;
    }
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">Notifications</h1>

  <ul class="notification-list" v-if="notifications && notifications.length">
    <li
      :key="notification._id"
      v-for="notification in notifications"
      class="notification d-flex"
    >
      <img
        v-if="isDefaultAvatar(notification)"
        class="notification__thumb"
        :src="notification?.meta?.member?.picture"
      />
      <img
        v-else
        class="notification__thumb"
        src="@/assets/images/default-avatar.png"
      />
      <div class="notification__content" v-if="notification.meta.member">
        <h5 class="notification__username">
          {{ notification.meta.member.username }}
        </h5>
        <p class="notification__text">
          {{notification.message}}
        </p>
      </div>

      <div v-else class="notification__content">
        <h5 class="notification__username">&nbsp;</h5>
        <p class="notification__text">
          {{notification.message}}
        </p>
      </div>
    </li>
  </ul>
  <ul v-else class="notification-list">
    <li v-if="!isLoading" class="notification-list__empty text text-center">
      Empty
    </li>
  </ul>
</template>

<script>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { common } from '@/api';

  export default {
    name: 'Notifications',
    setup() {
      const store = useStore();
      const notifications = ref(null);
      const isLoading = ref(true);

      const isDefaultAvatar = ({ meta }) => meta?.member?.picture;

      onMounted(async () => {
        const { data } = await common.getNotificationsList();

        isLoading.value = false;

        notifications.value = data;

        await common.clearNotificationsList();
        await store.dispatch('app/readNotifications');
      });

      return { isLoading, isDefaultAvatar, notifications };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .notification-list {
    margin-bottom: 20px;
  }

  .notification {
    padding: 10px 30px;

    font-family: Roboto;
    font-style: italic;
    font-size: 11px;
    color: #FFF;

    &__thumb {
      width: 45px;
      height: 45px;

      border-radius: 30px;
    }

    &__content {
      margin-left: 20px;
    }

    &__username {
      font-size: 12px;
      font-weight: bold;
      line-height: 20px;
    }
  }
</style>

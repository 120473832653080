<template>
  <div v-if="msg.isOwn" class="message message_type_own">
    <div class="message__container">
      <div class="message__wrapper">
        <div class="message__name">Me:</div>
        <span class="message__text">{{msg.message}}</span>
      </div>
      <div class="message__attachments" v-if="msg.attachments && msg.attachments.length">
        <div class="message__attachments-thumb">
          <img :src="msg.attachments[0].thumb"/>
        </div>
      </div>
    </div>
  </div>
  <div class="message" v-else>
    <div class="message__container d-flex">
      <div class="message__avatar">
        <img :src="msg.recipient.picture"/>
      </div>
      <div class="message__body">
        <div>
          <div class="message__name">
            {{msg.recipient.username}}
          </div>
          <span class="message__text">{{msg.message}}</span>
        </div>
        <div class="message__attachments" v-if="msg.attachments.length">
          <div class="message__attachments-thumb">
            <slot v-if="msg.isPaid">
              <img
                  v-if="msg.attachments[0].thumb"
                  :src="msg.attachments[0].thumb"
                  @click="onImageOpen(msg.attachments[0].thumb)"
              />
              <div
                  v-else
                  class="video-media text-center"
                  @click="onGetVideo(msg.attachments[0].postId)"
              >
                <img v-if="msg.attachments[0].thumb" :src="msg.attachments[0].thumb"/>
                <img src="@/assets/images/icons/video.svg"/>
              </div>
            </slot>
            <div v-else class="message__attachments-thumb-empty text-center">
              <img src="@/assets/images/icons/lock.svg"/>
            </div>
          </div>
          <Button
              v-if="!msg.isPaid"
              class="message__attachments-buy"
              :text="`Watch for $${msg.attachments[0].price}`"
              type="green" size="small" rounded
              @onClick="onBuyMedia(msg.attachments[0].price, msg.attachments[0].postId)"
          />
        </div>
      </div>
    </div>
  </div>

  <Popup v-if="isPayFormVisible" @close="onPaymentPopupClose">
    <USAepayForm
        @paid="onPayForMessage"
        :price="currentMediaPrice"
        :title="`Watch for $${currentMediaPrice}`"
    />
  </Popup>

  <div class="post-preview" v-if="isPreviewOpened">
    <div class="post-preview__close">
      <img src="@/assets/images/icons/cross.svg" @click="onPreviewClose"/>
    </div>
    <div class="post-preview__content">
      <img :src="previewImagePath" class="post-preview__content-img" ref="preview"/>
    </div>
  </div>
</template>

<script>
  import { ref, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import panzoom from 'panzoom';
  import { Button } from '@/components/atoms';
  import { Popup, USAepayForm } from '@/components';
  import { consumer, creator } from '@/api';

  export default {
    name: 'MessagingPage',
    components: { Button, Popup, USAepayForm },
    setup() {
      const store = useStore();
      const content = ref();
      const userData = ref(null);
      const message = ref('');
      const messages = ref([]);
      const role = store.getters['app/userRole'];
      const preview = ref();

      const currentMediaPrice = ref(0);
      let currentMediaPostId = null;
      const isPayFormVisible = ref(false);
      const isPreviewOpened = ref(false);
      const previewImagePath = ref(null);

      const isOwnMessage = (msg) => {
        if (
            role === 'member' && msg.senderMember ||
            role === 'model' && msg.senderModel
        ) {
          return true;
        }
        return false;
      }

      const onBuyMedia = (price, postId) => {
        currentMediaPrice.value = price;
        isPayFormVisible.value = true;
        currentMediaPostId = postId;
      }

      const onPayForMessage = async ({token}) => {
        await consumer.buyChatMedia({ token, postId: currentMediaPostId });
        await store.dispatch('app/addBoughtPost', currentMediaPostId);

        messages.value = messages.value.map((msg) => {
          if (msg.attachments && msg.attachments.length) {
            if (msg.attachments[0].postId === currentMediaPostId) {
              return { ...msg, isPaid: true };
            }
            return msg;
          }
          return msg;
        });

        currentMediaPrice.value = 0;
        isPayFormVisible.value = false;
        currentMediaPostId = null;
      }

      const onPaymentPopupClose = () => {
        isPayFormVisible.value = false;
      }

      const onImageOpen = (thumb) => {
        isPreviewOpened.value = true;
        previewImagePath.value = thumb;

        /* TODO: get signed AWS url */

        nextTick(() => {
          panzoom(preview.value);
        });
      }

      const onPreviewClose = () => {
        isPreviewOpened.value = false;
      }

      const onGetVideo = async (pid) => {
        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid,
        });

        console.log(data);
      }

      return {
        role, messages, message, userData, content, previewImagePath,
        currentMediaPrice, isPayFormVisible, isPreviewOpened, preview,
        isOwnMessage, onBuyMedia,
        onPayForMessage, onPaymentPopupClose,
        onImageOpen, onPreviewClose, onGetVideo,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    padding: 20px;

    background: #141414;
  }

  .avatar {
    width: 80px;
    height: 80px;
    margin-right: 20px;

    border-radius: 40px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    max-width: 70%;

    font-family: Roboto;

    &__username {
      margin-top: 12px;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #FFFFFF;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__link {
      display: block;

      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      line-height: 24px;
      letter-spacing: 0.1em;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;

      color: #FFF;
    }
  }

  .content {
    overflow-y: scroll;
    height: calc(100vh - 310px);
  }

  .message-input {
    position: fixed;
    bottom: 60px;
    width: 100%;
    padding: 15px 20px;

    background: #141414;

    &__action {
      margin: 0 10px;

      .icon {
        margin-top: 8px;
      }
    }

    &__field {
      width: 100%;
    }
  }

  .message {
    display: flex;
    margin: 16px 0;

    justify-content: flex-start;
    font-family: Roboto;
    font-style: italic;
    font-size: 12px;
    color: #FFFFFF;

    &__wrapper {
      margin: 2px 10px;
    }

    &__container {
      width: 90%;
      min-height: 60px;
      padding: 10px;

      background: #888888;
      border-radius: 0 25px 25px 0;
    }

    &_type_own {
      justify-content: flex-end;
    }

    &_type_own &__container {
      background: #3F3F3F;
      border-radius: 30px 0 0 30px;
    }

    &__avatar {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-right: 10px;

      border-radius: 25px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__body {
      width: 100%;
    }

    &__name {
      margin-bottom: 10px;
      font-weight: bold;
      letter-spacing: 0.1em;
    }

    &__text {
      font-weight: 300;
      line-height: 16px;
    }

    &__attachments {
      position: relative;
      height: 105px;
    }

    &__attachments-thumb {
      height: 100%;
      margin: 10px 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    &__attachments-thumb-empty {
      opacity: 0.3;

      img {
        width: 40px;
      }
    }

    &__attachments-buy {
      width: 200px;
      position: absolute;
      top: 70px;
      left: 50%;
      margin-left: -100px;
    }
  }

  /* TODO: remove duplicate */
  .post-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: rgba(0, 0, 0, 0.8);

    &__content {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
  }

  .video-media {
    padding-bottom: 30px;

    background: #CCC;
    border-radius: 15px;

    img {
      width: 40px;
      margin-top: 40px;
    }
  }
</style>

<template>
  <div class="header d-flex">
    <div class="header__icon header__icon-logo">
      <img src="@/assets/images/logo.svg"/>
    </div>
    <div class="header__icon header__icon-streaming">
      <router-link to="/app/new-broadcast">
        <img src="@/assets/images/icons/record.svg"/>
      </router-link>
    </div>
    <!--div class="header__icon">
      <router-link to="/app/creator-post">
        <img src="@/assets/images/icons/camera-switch.svg"/>
      </router-link>
    </div-->
  </div>
</template>

<script>
  export default {
    name: 'CreatorStreamHeader',
  }
</script>

<style lang="scss" scoped>
  @keyframes leaves {
    0% {
      transform: scale(1.0);
    }
    100% {
      transform: scale(1.25);
    }
  }

  .header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      height: 25px;

      img {
        height: 100%;
      }
    }

    &__icon-logo {
      height: 23px;
    }

    &__icon-streaming {
      animation: leaves 0.7s ease-in-out infinite alternate;
    }
  }
</style>

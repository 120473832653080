<template>
  <h1 class="header text-center text-uppercase">
    ADD STORY
  </h1>

  <div v-if="!thumbSrc && !videoSrc" class="attach-media d-flex">
    <div class="attach-media-item text-center">
      <div class="attach-media-item__img-wrapper">
        <img src="@/assets/images/icons/video.svg"/>
        <input
            type="file"
            accept="video/*"
            class="attach-media-item__file-btn"
            @change="onTakeVideo"
        />
      </div>
      <div class="attach-media-item__lbl">Record a story or upload a video.</div>
    </div>

    <div class="attach-media-item text-center">
      <div class="attach-media-item__img-wrapper">
        <input
            type="file"
            accept="image/*"
            class="attach-media-item__file-btn"
            @change="onTakePhoto"
        />
        <img src="@/assets/images/icons/picture.svg"/>
      </div>
      <div class="attach-media-item__lbl">Take a photo or upload an image.</div>
    </div>
  </div>

  <div v-if="thumbSrc" class="thumb text-center">
    <img :src="thumbSrc"/>
  </div>

  <div v-if="videoSrc">
    <div class="thumb text-center">
      <video :src="videoSrc" controls/>
    </div>

    <h2 class="sub-header text-center text-uppercase mt-25">Upload Video Thumbnail</h2>

    <!-- If video uploaded user can upload video thumbnail -->
    <div v-if="!videoThumbSrc" class="attach-media d-flex">
      <div class="attach-media-item text-center">
        <div class="attach-media-item__img-wrapper">
          <input
              type="file"
              accept="image/*"
              class="attach-media-item__file-btn"
              @change="onTakeVideoThumb"
          />
          <img src="@/assets/images/icons/picture.svg"/>
        </div>
        <div class="attach-media-item__lbl">Take a photo or upload an image.</div>
      </div>
    </div>
    <div v-else class="thumb text-center">
      <img :src="videoThumbSrc"/>
    </div>
    <div
      class="validation-error"
      v-if="!videoThumbSrc && validationErrors.thumbIsRequired"
    >
      {{validationErrors.thumbIsRequired}}
    </div>
  </div>

  <div class="m-20 text-center" v-if="thumbSrc || videoSrc">
    <span class="clear-btn icon" @click="onSelectedMediaClear">
      <img src="@/assets/images/icons/trash.svg"/>
    </span>
  </div>

  <form class="form switchers">
    <div class="button-loader">
      <Button :disabled="isSendingData" text="SUBMIT" class="mt-40" @onClick="onSubmit"/>
      <div v-if="isSendingData" class="button-loader__wrapper text-center">
        <img src="@/assets/images/spinner.svg"/>
      </div>
    </div>
  </form>
</template>

<script>
  import { ref, reactive, computed, onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Button } from '@/components';
  import { common, creator } from '@/api';
  import { errorsToMessage, sizeInMB, sizeInGb, getVideoDuration } from '@/utils';
  import config from '@/config';

  export default {
    name: 'CreateStoryPage',
    components: { Button },
    props: { },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const thumbSrc = ref(null);
      const videoSrc = ref(null);
      const videoThumbSrc = ref(null);
      const isSendingData = ref(false);
      const validationErrors = ref({ thumbIsRequired: false });
      const form = reactive({
        title: '',
        description: '',
        isPublished: true,
        price: 0,
        isPublic: false,
      });
      const postId = route.query.pid;
      const titleText = computed(() => postId ? 'Edit' : 'Add');
      let image = null;
      let video = null;
      let videoThumb = null;

      const uploadMedia = (media, mediaType, pid) => {
        let signedURLData = null;

        return common.getS3UploadURL({
          mediaType,
          contentType: media.type,
          fileName: media.name,
          postId: pid,
        })
        .then(({ data }) => {
          signedURLData = data;

          return fetch(data.url, {
            method: 'PUT',
            mode: 'cors',
            body: media,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': media.type,
            }
          })
        })
        .then(async () => {
          await common.reportForS3SuccessUpload({
            mediaType,
            contentType: media.type,
            key: signedURLData.key,
            postId: pid,
          });
        });
      };

      const onSubmit = (e) => {
        let post = null;

        e.preventDefault();

        validationErrors.value.thumbIsRequired = false;

        if (videoSrc.value && !videoThumb) {
          validationErrors.value.thumbIsRequired = 'Thumbnail is required';

          return;
        }

        isSendingData.value = true;

        creator.createStory(form)
            .then((res) => {
              post = res;

              if (image || video) {
                return uploadMedia(
                    image || video,
                    image ? 'image' : 'video',
                    postId || post.data._id
                );
              }
              return;
            })
            .then(() => {
              if (videoThumb) {
                return uploadMedia(videoThumb, 'video-img', postId || post.data._id);
              }
            })
            .then(() => {

              /*
                Image on AWS can't be updated immediately event after callback so we need a delay on our side.
                TODO: find more elegant solution
              */
              setTimeout(() => {
                isSendingData.value = false;
                router.push('/');
              }, config.AWS_REFRESH_FILES_DELAY);
            })
            .catch((error) => {
              if (error.response.data.message.includes('Contains Prohibited Content')) {
                alert('Contains Prohibited Content.');
              } else {
                store.dispatch('message/show', {
                  type: 'error',
                  text: errorsToMessage(error.response.data.errors)
                });
              }
              isSendingData.value = false;
            });
      };

      const onTakePhoto = (e) => {
        image = e.target.files[0];

        if (parseFloat(sizeInMB(image.size)) > 20) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Photo size can not be more then 20Mb'
          });
          return false;
        }

        thumbSrc.value = URL.createObjectURL(image);
      }

      const onTakeVideo = (e) => {
        video = e.target.files[0];

        getVideoDuration(video, (duration) => {
          if (duration <= config.STORY_MAX_DURATION) {
            videoSrc.value = URL.createObjectURL(video)
          } else {
            store.dispatch('message/show', {
              type: 'error',
              text: 'Video can\'t be longer than 60 seconds'
            });

            return false
          }
        });

        if (parseFloat(sizeInGb(video.size)) > config.STORY_MAX_SIZE) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Video size can not be more than 100mb'
          });
          return false
        }
      }

      const onTakeVideoThumb = (e) => {
        videoThumb = e.target.files[0];

        if (parseFloat(sizeInMB(videoThumb.size)) > 20) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Photo size can not be more then 20Mb'
          });
          return false;
        }

        videoThumbSrc.value = URL.createObjectURL(videoThumb);
      }

      const onSelectedMediaClear = () => {
        thumbSrc.value = null;
        videoSrc.value = null;
        videoThumbSrc.value = null;
      }

      const onMakePostPublic = () => {
        form.isPublic = !form.isPublic;
      }

      onBeforeMount(async () => {
        if (postId) {
          const media = await creator.getPostMedia({
            mid: store.getters['app/userID'],
            pid: postId
          });

          await creator.getPost(postId).then(({ data }) => {
            form.title = data.title;
            form.description = data.description;

            if (data.media.mediaType === 'video') {
              videoSrc.value = media.data;
              videoThumbSrc.value = data.media.thumbnail;
            } else {
              thumbSrc.value = media.data;
            }
          });
        }
      });

      return {
        titleText, form, thumbSrc, videoSrc, videoThumbSrc, isSendingData,
        onSubmit, validationErrors,
        onTakePhoto, onTakeVideo, onTakeVideoThumb,
        onSelectedMediaClear, onMakePostPublic,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 30px 0;
  }

  .form {
    padding: 0 20px;
  }

  .attach-media {
    padding: 30px 20px;
    justify-content: space-around;
  }

  .validation-error {
    color: red;
    text-align: center;
    font-size: 14;
  }

  .attach-media-item {
    &__img-wrapper {
      position: relative;
      width: 110px;
      height: 80px;

      img {
        height: 100%;
      }
    }

    &__lbl {
      white-space: break-spaces;
    }

    &__file-btn {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0;
    }
  }

  .thumb {
    margin: 20px;
    height: 200px;

    border: 1px solid #4e4c4c;

    img, video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .button-loader {
    position: relative;

    &__wrapper {
      position: absolute;
      top: 0;
      height: 61px;
      width: 100%;

      background: rgba(0,0,0, 0.82);

      img {
        height: 100%;
      }
    }
  }

  .clear-btn {
    width: 56px;
    height: 56px;
    padding: 13px;

    border-radius: 30px;
    background: #313131;
  }
</style>

import PublicPagesController from '@/controllers/PublicPagesController';
import {
  LoginView, RegistrationView, RegistrationVerificationView,
  ForgotPasswordView, ResetPasswordView,
} from '@/views';

export default [
    {
      path: '/',
      query: 'transferCompleted',
      name: 'Login',
      component: LoginView,
      meta: { public: true },
    },
    {
      path: '/registration',
      name: 'Registration',
      component: RegistrationView,
      meta: { public: true },
    },
    {
      path: '/registration-verification',
      name: 'RegistrationVerification',
      component: RegistrationVerificationView,
      meta: { public: true },
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPasswordView,
      meta: { public: true },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPasswordView,
      meta: { public: true },
    },
    {
      path: '/docs/:type',
      name: 'PublicDocs',
      component: PublicPagesController,
      meta: { public: true },
    },
];

import ConsumerHeader from './ConsumerHeader';
import CreatorHeader from './CreatorHeader';
import FullScreenHeader from './FullScreenHeader';
import CreatorStreamHeader from './CreatorStreamHeader';
import ModelPageHeader from './ModelPageHeader';
import AdminHeader from './AdminHeader';

export default {
  ConsumerHeader, CreatorHeader, FullScreenHeader, CreatorStreamHeader,
  ModelPageHeader, AdminHeader,
}

<template>
  <h1 class="header text-center text-uppercase">
    Search
  </h1>

  <form class="form" v-if="options">
    <div class="form__group">
      <div class="search-input">
        <Input
          class="search-input__item"
          :value="form.search"
          placeholder="SEARCH THEN PRESS ENTER"
          @onInput="onInput('search', $event)"
        />
        <img src="@/assets/images/icons/search-grey.svg" class="search-input__icon"/>
      </div>

      <h1 class="sub-header text-center text-uppercase mt-20 mb-20">
        or Filter
      </h1>

      <CheckboxPanel
        title="Hair color"
        :options="options.hair_color"
        @select="onAttributeChoose('hair_color', $event)"
      />
      <CheckboxPanel
        title="Bust size"
        :options="options.bust_size"
        @select="onAttributeChoose('bust_size', $event)"
      />
      <CheckboxPanel
        title="Waist size"
        :options="options.waist_size"
        @select="onAttributeChoose('waist_size', $event)"
      />
      <CheckboxPanel
        title="Hip size"
        :options="options.hip_size"
        @select="onAttributeChoose('hip_size', $event)"
      />
      <CheckboxPanel
        title="Eye color"
        :options="options.eye_color"
        @select="onAttributeChoose('eye_color', $event)"
      />
      <CheckboxPanel
          title="Cup size"
          :options="options.cup_size"
          @select="onAttributeChoose('cup_size', $event)"
      />
      <CheckboxPanel
          title="FETISHES"
          :options="options.fetishes"
          @select="onAttributeChoose('fetishes', $event)"
      />

      <Button text="Search" class="mt-40" @onClick="onSearch"/>
    </div>
  </form>
</template>

<script>
  import { ref, reactive, onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  import { Input, Button, CheckboxPanel } from '@/components';
  import { common } from '@/api';

  export default {
    name: 'CreatorSearchPage',
    components: { Input, Button, CheckboxPanel },
    props: { },
    setup() {
      const router = useRouter();
      const isSendingData = ref(false);
      const form = reactive({
        search: '',
        filters: []
      });
      const options = ref({
        hair_color: [],
        bust_size: [],
        waist_size: [],
        hip_size: [],
        eye_color: [],
        cup_size: [],
        fetishes: [],
      });

      const onInput = (type, value) => {
        form[type] = value;
      }

      const onSearch = (e) => {
        const queryParams = {};

        if (form.search) {
          queryParams.text = form.search;
        }
        if (form.filters.length) {
          form.filters.forEach((filterItem) => {
            queryParams[filterItem.key] = filterItem.value.join()
          });
        }

        router.push(`/app/search-results?${new URLSearchParams(queryParams).toString()}`);

        e.preventDefault();
      }

      const onAttributeChoose = (type, data) => {
        form.filters.push({
          key: type,
          value: data.selectedItems,
        });
      }

      onMounted(async () => {
        const { data } = await common.getPublicOptions();
        options.value.hair_color = data.enums.attributes.hair_color.map((value) => ({ text: value, value }));
        options.value.bust_size = data.enums.attributes.bust_size.map((value) => ({ text: value, value }));
        options.value.waist_size = data.enums.attributes.waist_size.map((value) => ({ text: value, value }));
        options.value.hip_size = data.enums.attributes.hip_size.map((value) => ({ text: value, value }));
        options.value.eye_color = data.enums.attributes.eye_color.map((value) => ({ text: value, value }));
        options.value.cup_size = data.enums.attributes.cup_size.map((value) => ({ text: value, value }));
        options.value.fetishes = data.enums.attributes.fetishes.map((value) => ({ text: value, value }));
      });

      return {
        form, options, isSendingData,
        onInput, onSearch, onAttributeChoose,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 20px 0;
  }

  .form {
    padding: 0 20px;
  }

  .search-input {
    &__item {
      padding-right: 50px;
    }

    &__icon {
      position: absolute;
      top: 18px;
      right: 10px;
    }
  }

  .attributes-name {
    text-transform: capitalize;
  }
</style>

<template>
  <div>
    <div v-if="requestStep === 0" class="container">
      <p class="text text-center">You'll be redirected to DocuSign to sign the contract</p>
      <a class="link button" href="https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=feb4e51c-23e9-41ce-9b33-5d8441bddbb0&env=demo&acct=7d4d23cf-d3eb-4688-8785-8974f082bbde&v=2">
        Sign Agreement
      </a>
    </div>
    <div v-else-if="requestStep === 1" class="container">
      <p class="text text-center">Your request is pending. The processing of the request usually takes 15 minutes</p>
      <a class="link text" href="https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=feb4e51c-23e9-41ce-9b33-5d8441bddbb0&env=demo&acct=7d4d23cf-d3eb-4688-8785-8974f082bbde&v=2">
        Re-sign Agreement
      </a>
    </div>
  </div>
</template>

<script>
  import { onMounted, watch, ref, onBeforeUnmount } from 'vue'
  import { useStore } from 'vuex';

  export default {
    name: 'DocusignForm',
    setup() {
      const store = useStore();
      const requestStep = ref(0);
      const pollingInterval = ref(null);

      const getRequestStep = docusign => {
        if (docusign && docusign?.status !== 'completed') {
          if (!pollingInterval.value) {
            pollingInterval.value = setInterval(async () => await store.dispatch('consumer/getOwnProfileInfo'), 5000)
          }

          return requestStep.value = 1;
        }

        if (docusign && docusign?.status === 'completed') {
          clearInterval(pollingInterval.value);
        }

        return requestStep.value = 0;
      }

      watch(() => store.state.consumer.profile?.model?.docusign, docusign => {
        getRequestStep(docusign);
      })

      const onSignOut = (event) => {
        event.preventDefault();
        store.commit('app/LOGOUT');
        window.localStorage.clear();
      }

      onMounted(() => {
        const docusign = store.state.consumer?.profile?.model?.docusign;

        getRequestStep(docusign);
      })

      onBeforeUnmount(() => {
        clearInterval(pollingInterval.value);
      })

      return { requestStep, onSignOut }
    }
  }
</script>

<style scoped>
  .text {
    margin: 0 20px 100px;
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .button {
    background-color: #D5AF34;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFF;
    padding: 20px 45px;
    text-align: center;
    margin: 0 20px;
  }
</style>

<template>
  <div class="checkbox-panel">
    <h4 class="checkbox-panel__header" @click="onToggle">
      <div class="d-flex justify-space-between align-items-center">
        <span>{{ title }}</span>
        <h4 class="checkbox-panel__toggle">
          <img src="@/assets/images/icons/cross.svg" v-if="isOpen"/>
          <img src="@/assets/images/icons/menu.svg" v-else/>
        </h4>
      </div>
    </h4>
    <div
      :class="['checkbox-panel__box', {
        'is-closed': !isOpen
      }]"
    >
      <div
        class="checkbox-panel__cell"
        v-for="item in options"
        :key="item.value"
      >
        <Checkbox
          :label="item.text"
          :value="item.value"
          :checked="Boolean(selectedItems.find((key) => key === item.value)) || false"
          @onChange="onSelect($event, item)"
          class="checkbox-panel__el"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { Checkbox } from '@/components/atoms';

  export default {
    name: 'CheckboxPanel',
    components: { Checkbox },
    props: {
      title: {
        type: String,
        required: true,
      },
      selected: {
        type: Array,
      },
      isOpened: {
        type: Boolean,
        default: false,
      },
      isSingle: {
        type: Boolean,
        default: false,
      },
      options: Array,
    },
    setup(props, { emit }) {
      const isOpen = ref(props.isOpened);
      const selectedItems = ref(props.selected || []);
      const onToggle = () => {
        isOpen.value = !isOpen.value;
      }

      const onSelect = (event, item) => {
        if (props.isSingle) {
          selectedItems.value = [item.value];
        } else {
          if (!selectedItems.value.includes(item.value)) {
            selectedItems.value.push(item.value);
          } else {
            selectedItems.value = selectedItems.value.filter((key) => key !== item.value);
          }
        }

        emit('select', {
          selectedItems: selectedItems.value, event, item
        });
      }

      watch(() => props.selected, (data) => {
        selectedItems.value = data;
      })

      return { isOpen, selectedItems, onToggle, onSelect };
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox-panel {
    margin: 10px 0;

    &__header {
      padding: 22px 12px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.1em;

      text-transform: uppercase;
      color: #FFF;
      background: #000;
    }

    &__box {
      padding: 14px 24px;
      background: #141414;
      max-height: 50vh;
      overflow-y: auto;

      &.is-closed {
        height: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    &__cell {
      display: inline-block;

      width: 50%;
      margin: 6px 0;

      vertical-align: top;
    }

    &__toggle {
      cursor: pointer;
      height: 24px;
    }

    &__el {
      text-transform: capitalize;
    }
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">Users</h1>

  <div class="search-input">
    <Input
        class="search-input__item"
        placeholder="INPUT USERNAME OR FIRST NAME"
        :value="searchValue"
        @onInput="onInput"
    />
    <img src="@/assets/images/icons/search-grey.svg" class="search-input__icon"/>
  </div>

  <div class="results mt-20" v-if="usersList">
    <div class="user d-flex justify-space-between align-items-center" v-for="user in usersList" :key="user._id">
      <div class="user__info">
        <div class="user__info-name">{{user.first_name || '-'}} {{user.last_name || '-'}}</div>
        <div class="user__info-username">({{user.username}})</div>
      </div>
      <div class="user__actions">
        <Button v-if="user.user.is_active" text="Disable" type="red" size="extra-small" rounded @onClick="onUserDisable(user)"/>
        <Button v-else text="Enable" type="transparent" size="extra-small" rounded @onClick="onUserEnable(user)"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { ref } from 'vue';
  import {
    Input,
    Button
  } from '@/components';
  import { admin } from '@/api';

  export default {
    name: 'AdminUsersPage',
    components: {
      Input,
      Button
    },
    setup() {
      const store = useStore();
      const searchValue = ref('');
      const usersList = ref(null);

      const onInput = async (value) => {
        let requestQuery = {
          $and: [],
        };

        searchValue.value = value;

        if (value.length > 1) {
          requestQuery.$and.push({$or: [
            { username: { $regex: value, $options: 'i' }},
            { first_name: { $regex: value, $options: 'i' }},
            { last_name: { $regex: value, $options: 'i' }},
          ]});

          usersList.value = await store.dispatch('consumer/searchModels', requestQuery);
        } else {
          usersList.value = null;
        }

        /* TODO: find add users (use aggregation)
          requestQuery.$and.push({$or: [
            {
              'model.username': {$regex: value, $options: 'i'},
            },
            {
              'model.first_name': {$regex: value, $options: 'i'}
            },
            {
              'model.last_name': {$regex: value, $options: 'i'}
            },
            {
              'member.username': {$regex: value, $options: 'i'}
            },
            {
              'member.last_name': {$regex: value, $options: 'i'}
            },
            {
              'member.last_name': {$regex: value, $options: 'i'}
            },
          ]});

          usersList.value = await admin.findUsers({
            params: requestQuery
          });
        */
      }

      const onUserDisable = async ({user}) => {
        await admin.disableUser(user._id);
        updateUserActiveStatus(user, false);
      }

      const onUserEnable = async ({user}) => {
        await admin.enableUser(user._id);
        updateUserActiveStatus(user, true);
      }

      const updateUserActiveStatus = (user, status) => {
        usersList.value = usersList.value.map((item) => {
          if (item.user._id === user._id) {
            return {
              ...item,
              user: {
                ...item.user,
                is_active: status
              }
            };
          }
          return item;
        });
      }

      return {
        usersList,
        searchValue,
        onInput,
        onUserDisable,
        onUserEnable,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .search-input {
    position: relative;
    margin: 0 20px 10px 20px;

    &__item {
      padding-right: 50px;
    }

    &__icon {
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }

  .user {
    padding: 10px 20px;
    margin: 2px 0;

    background: #333;

    &__info-username {
      font-size: 12px;
      letter-spacing: 1px;
      color: #9b9b9b;
    }
  }
</style>

<template>
  <div class="textarea">
    <textarea
      :placeholder="placeholder"
      :value="value"
      class="textarea__item"
      @input="onInput"
    ></textarea>
  </div>
</template>

<script>
  export default {
    name: 'Textarea',
    props: {
      value: String,
      placeholder: String,
    },
    setup(_, { emit }) {
      const onInput = (e) => {
        emit('onInput', e.target.value);
      };

      return { onInput };
    }
  }
</script>

<style lang="scss" scoped>
  .textarea {
    position: relative;
    height: 150px;
    padding: 10px;

    background-color: #FFF;

    &__item {
      width: 100%;
      height: 100%;

      background: none;
      border: 0;
      -webkit-appearance: none;

      font-family: Roboto;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1em;
      color: #888;

      &:focus {
        outline: none;
      }
    }

    &__item::placeholder {
      color: #a5a5a5;
      text-transform: uppercase;
    }
  }
</style>

<template>
  <div v-if="recipientData">
    <div class="header d-flex">
      <div class="avatar">
        <img v-if="recipientData.picture" :src="recipientData.picture"/>
        <img v-else src="@/assets/images/avatar_default.png"/>
      </div>
      <div class="info">
        <router-link
          :to="`/${recipientData.username}`"
          class="info__link"
        >
          <h3 class="info__username">
            {{recipientData.username}}
          </h3>
          <div v-if="role !== 'model'">
            www.rhinovision.com/{{recipientData.username}}
          </div>
        </router-link>
        <div class="" v-if="role !== 'model'" @click="onBuyMedia(null)">
          <img src="@/assets/images/icons/tip.svg"/>
        </div>
      </div>
    </div>

    <div class="content" ref="content">
      <slot v-for="msg in messages" :key="msg.id">
        <div v-if="msg.isOwn" class="message message_type_own">
          <div class="message__container">
            <div class="message__wrapper">
              <div class="message__name">Me:</div>
              <span class="message__text">{{msg.message}}</span>
            </div>
            <div class="message__attachments" v-if="msg.attachments && msg.attachments.length">
              <div class="message__attachments-thumb">
                <img
                    v-if="msg.attachments[0].thumb"
                    :src="msg.attachments[0].thumb"
                    @click="onImageOpen(msg.attachments[0].thumb)"
                />
                <div
                    v-else
                    class="video-media text-center d-flex justify-center"
                    @click="onGetVideo(msg.attachments[0].postId)"
                >
                  <slot v-if="!msg.videoSrc">
                    <img v-if="msg.attachments[0].thumb" :src="msg.attachments[0].thumb"/>
                    <img src="@/assets/images/icons/video.svg"/>
                  </slot>
                  <slot v-else>
                    <video :src="msg.videoSrc" controls autoplay/>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="message" v-else>
          <div class="message__container d-flex">
            <div class="message__avatar">
              <img v-if="msg.recipient.picture" :src="msg.recipient.picture"/>
              <img v-else src="@/assets/images/avatar_default.png"/>
            </div>
            <div class="message__body">
              <div>
                <div class="message__name">
                  {{msg.recipient.username}}
                </div>
                <span class="message__text">{{msg.message}}</span>
              </div>
              <div class="message__attachments" v-if="msg.attachments.length">
                <div class="message__attachments-thumb">
                  <slot v-if="msg.isPaid">
                    <img
                      v-if="msg.attachments[0].thumb"
                      :src="msg.attachments[0].thumb"
                      @click="onImageOpen(msg.attachments[0].thumb)"
                    />
                    <div
                      v-else
                      class="video-media text-center d-flex justify-center"
                      @click="onGetVideo(msg.attachments[0].postId)"
                    >
                      <slot v-if="!msg.videoSrc">
                        <img v-if="msg.attachments[0].thumb" :src="msg.attachments[0].thumb"/>
                        <img src="@/assets/images/icons/video.svg"/>
                      </slot>
                      <slot v-else>
                        <video :src="msg.videoSrc" controls autoplay/>
                      </slot>
                    </div>
                  </slot>
                  <div v-else class="message__attachments-thumb-empty text-center">
                    <img src="@/assets/images/icons/lock.svg"/>
                  </div>
                </div>

                <Button
                  v-if="!msg.isPaid"
                  class="message__attachments-buy"
                  :text="`Watch for $${msg.attachments[0].price}`"
                  type="green" size="small" rounded
                  @onClick="onBuyMedia(msg.attachments[0].price, msg.attachments[0].postId)"
                />
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>

    <div class="message-input d-flex">
      <MessagingMedia
        :text="message"
        :price="mediaPrice"
        buttonText="Send"
        :memberId="recipientData._id"
        v-if="role === 'model'"
        @sent="onMediaSent"
      />
      <Input
        :value="message"
        placeholder="Your message"
        class="message-input__field"
        @onKeyUp="onTextInput"
      />
    </div>

    <Popup v-if="isPayFormVisible" @close="onPaymentPopupClose">
      <USAepayForm
        v-if="currentMediaPostId"
        @paid="onPay"
        :hasSavedCard="hasSavedCard"
        :price="currentMediaPrice"
        :title="`Watch for $${currentMediaPrice}`"
      />
      <USAepayForm
        v-else
        :hasSavedCard="hasSavedCard"
        title="Tip"
        canSetPrice
        @paid="onPay"
      />
    </Popup>

    <div class="post-preview" v-if="isPreviewOpened">
      <div class="post-preview__close">
        <img src="@/assets/images/icons/cross.svg" @click="onPreviewClose"/>
      </div>
      <div class="post-preview__content">
        <img :src="previewImagePath" class="post-preview__content-img" ref="preview"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import mqtt from 'mqtt';
  import panzoom from 'panzoom';
  import { Input, Button } from '@/components/atoms';
  import { Popup, USAepayForm, MessagingMedia } from '@/components';
  import { common, consumer, creator } from '@/api';
  import config from '@/config';

  export default {
    name: 'MessagingPage',
    components: {
      Input, Button, Popup, USAepayForm, MessagingMedia
    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const content = ref();
      const userData = ref(null);
      const recipientData = ref(null);
      const message = ref('');
      const messages = ref([]);
      const role = store.getters['app/userRole'];
      const preview = ref();
      const mediaPrice = ref(0);

      const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);
      let client = null;
      let channelToListen = null;
      let channelToPublish = null;
      const profile = ref(null);
      let recipient = null;
      const currentMediaPrice = ref(0);
      const currentMediaPostId = ref(null);
      const isPayFormVisible = ref(false);
      const isPreviewOpened = ref(false);
      const previewImagePath = ref(null);
      const hasSavedCard = ref(false);

      const sendMessage = (role, text) => {
        if (!text) return false;

        const initial = {
          message: {
            text,
          },
          senderRole: role,
        };
        let msg = null;

        if (role === 'member') {
         msg = {
            ...initial,
            senderId: profile.value.member._id,
            recipientRole: 'model',
            recipientId: recipient.data.model._id,
            attachments: [],
          };
        } else {
          msg = {
            ...initial,
            senderId: profile.value.model._id,
            recipientRole: 'member',
            recipientId: recipient.data.member._id,
            attachments: [],
          };
        }

        messages.value.push({
          message: text,
          isOwn: true,
        });

        client.publish(channelToPublish, JSON.stringify(msg), { qos: 0, retain: false });
        message.value = '';

        scrollDown();
      }

      const onTextInput = (e) => {
        message.value = e.target.value;

        if (e.keyCode === 13) {
          sendMessage(role, message.value);
        }
      };

      const isOwnMessage = (msg) => {
        if (
          role === 'member' && msg.senderMember ||
          role === 'model' && msg.senderModel
        ) {
          return true;
        }
        return false;
      }

      const scrollDown = () => {
        nextTick(() => {
          content.value.scrollTo(0, content.value.scrollHeight);
        });
      };

      const onBuyMedia = (price, postId) => {
        if (price) {
          currentMediaPrice.value = price;
          isPayFormVisible.value = true;
          currentMediaPostId.value = postId;
        } else {
          isPayFormVisible.value = true;
        }
      }

      const onPay = async (data) => {
        if (currentMediaPostId.value) {
          await onPayForMessage(data);
        } else {
          await consumer.payForTip({
            token: data.token,
            tipAmount: data.price,
            modelId: recipient.data.model,
          });
        }

        isPayFormVisible.value = false;
      }

      const onPayForMessage = async ({token}) => {
        const boughtMedia = await consumer.buyChatMedia({ token, postId: currentMediaPostId.value });
        await store.dispatch('app/addBoughtPost', currentMediaPostId.value);

        await store.dispatch('consumer/updateProfileInfo', {
          member: {
            lastTransaction: boughtMedia.data.lastTransaction,
            creditCard: boughtMedia.data.creditCard,
          }
        });

        messages.value = messages.value.map((msg) => {
          if (msg.attachments && msg.attachments.length) {
            if (msg.attachments[0].postId === currentMediaPostId.value) {
              return { ...msg, isPaid: true };
            }
            return msg;
          }
          return msg;
        });

        currentMediaPrice.value = 0;
        currentMediaPostId.value = null;
      }

      const onPaymentPopupClose = () => {
        isPayFormVisible.value = false;
      }

      const onImageOpen = (thumb) => {
        isPreviewOpened.value = true;
        previewImagePath.value = thumb;

        /* TODO: get signed AWS url */

        nextTick(() => {
          panzoom(preview.value);
        });
      }

      const onPreviewClose = () => {
        isPreviewOpened.value = false;
      }

      const onGetVideo = async (pid) => {
        const { data } = await creator.getPostMedia({
          mid: store.getters['app/userID'],
          pid,
        });

        messages.value = messages.value.map((msg) => {
          if (msg.attachments && msg.attachments.length) {
            if (msg.attachments[0].postId === pid) {
              return { ...msg, videoSrc: data };
            }
            return msg;
          }
          return msg;
        });
      }

      const onMediaSent = async (data) => {
        messages.value.push({
          attachments: data.attachments,
          message: data.message.text,
          recipientMember: {
            _id: data.recipientId,
            username: recipientData.value.username,
          },
          senderModel: {
            _id: data.senderId,
            username: userData.value.username,
            picture: userData.value.picture,
          },
          isOwn: true,
          recipient: role === 'member' ? recipient.data.model : recipient.data.member,
        });
      }

      onMounted(async () => {
        const options = await common.getPublicOptions();
        let historyData = null;

        if (role === 'member') {
          profile.value = await store.dispatch('consumer/getOwnProfileInfo');
          recipient = await creator.getModelByUsername({
            username: route.params.username
          });
          historyData = await consumer.getChatHistory({id: recipient.data.model._id});
          userData.value = profile.value.member;
          recipientData.value = recipient.data.model;

          channelToListen = `${options.data.mqttEnv}/chat/member/${profile.value.member._id}`;
          channelToPublish= `${options.data.mqttEnv}/chat/model/${recipient.data.model._id}`;
        } else {
          profile.value = await store.dispatch('creator/getOwnProfileInfo');
          recipient = await consumer.getMemberByUsername({
            username: route.params.username
          });
          historyData = await creator.getChatHistory({id: recipient.data.member._id});
          userData.value = profile.value.model;
          recipientData.value = recipient.data.member;

          channelToListen = `${options.data.mqttEnv}/chat/model/${profile.value.model._id}`;
          channelToPublish = `${options.data.mqttEnv}/chat/member/${recipient.data.member._id}`;
        }

        messages.value = historyData.data.history.docs.reverse();

        /* Transform data to know is message own and paid */
        messages.value = messages.value.map((msg) => {
          const transformedMsg = {
            ...msg,
            isOwn: isOwnMessage(msg),
            recipient: role === 'member' ? recipient.data.model : recipient.data.member,
          };

          const paidPosts = store.getters['app/paidPosts'];

          if (msg.attachments.length && paidPosts.length && msg.attachments[0].price === 0) {
            transformedMsg.isPaid = true;
          }

          if (msg.attachments && paidPosts.length && msg.attachments.length && paidPosts) {
            const paidPost = paidPosts.find((pid) => pid === msg.attachments[0].postId);
            if (paidPost) transformedMsg.isPaid = true;
          }

          return transformedMsg;
        });

        client = mqtt.connect(config.MQTT_BROKER, { ...config.MQTT_BROKER_CONFIG, clientId });

        client.on('error', (error) => {
          console.log('Connection error: ', error);
          client.end();
        });

        client.on('connect', () => {
          client.subscribe(channelToListen, { qos: 0 });
        });

        client.on('message', async (topic, message) => {
          const originMsg = JSON.parse(message.toString());
          originMsg.message = originMsg.message.text;
          originMsg.recipient = role === 'member' ? recipient.data.model : recipient.data.member;

          if (originMsg.attachments[0] && originMsg.attachments[0].price === 0) {
            originMsg.isPaid = true;
          }

          messages.value.push(originMsg);
          await store.dispatch('app/unread', false);
          scrollDown();
        });

        await store.dispatch('app/unread', false);

        if (role === 'model') {
          mediaPrice.value = store.state.creator.profile.model.price_settings.private_message;
        }

        if (store.state.consumer.profile) {
          hasSavedCard.value = Boolean(store.state.consumer.profile.member.lastTransaction);
        }

        scrollDown();
      });

      onBeforeUnmount(() => {
        if (client) {
          client.unsubscribe(channelToListen);
          client.end();
        }
      });

      return {
        role, messages, message, userData, recipientData, content, previewImagePath,
        currentMediaPrice, isPayFormVisible, isPreviewOpened, preview, mediaPrice, hasSavedCard,
        currentMediaPostId,
        onPay,
        onTextInput, isOwnMessage, onBuyMedia,
        onPayForMessage, onPaymentPopupClose,
        onImageOpen, onPreviewClose, onGetVideo, onMediaSent,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    padding: 20px;

    background: #141414;
  }

  .avatar {
    width: 80px;
    height: 80px;
    margin-right: 20px;

    border-radius: 40px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    max-width: 70%;

    font-family: Roboto;

    &__username {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #FFFFFF;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__link {
      display: block;

      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      line-height: 24px;
      letter-spacing: 0.1em;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;

      color: #FFF;
    }
  }

  .content {
    overflow-y: scroll;
    height: calc(100vh - 310px);
  }

  .message-input {
    position: fixed;
    bottom: 60px;
    width: 100%;
    padding: 15px 20px;

    background: #141414;

    &__action {
      margin: 0 10px;

      .icon {
        margin-top: 8px;
      }
    }

    &__field {
      width: 100%;
    }
  }

  .message {
    display: flex;
    margin: 16px 0;

    justify-content: flex-start;
    font-family: Roboto;
    font-style: italic;
    font-size: 12px;
    color: #FFFFFF;

    &__wrapper {
      margin: 2px 10px;
    }

    &__container {
      width: 90%;
      min-height: 60px;
      padding: 10px;

      background: #888888;
      border-radius: 0 25px 25px 0;
    }

    &_type_own {
      justify-content: flex-end;
    }

    &_type_own &__container {
      background: #3F3F3F;
      border-radius: 30px 0 0 30px;
    }

    &__avatar {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-right: 10px;

      border-radius: 25px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__body {
      width: 100%;
    }

    &__name {
      margin-bottom: 10px;
      font-weight: bold;
      letter-spacing: 0.1em;
    }

    &__text {
      font-weight: 300;
      line-height: 16px;
    }

    &__attachments {
      position: relative;
      min-height: 105px;
    }

    &__attachments-thumb {
      height: 100%;
      margin: 10px 0;
      //max-height: 100px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    &__attachments-thumb-empty {
      opacity: 0.3;

      img {
        display: inline-block;
        width: 40px;
      }
    }

    &__attachments-buy {
      width: 200px;
      position: absolute;
      top: 70px;
      left: 50%;
      margin-left: -100px;
    }
  }

  /* TODO: remove duplicate */
  .post-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: rgba(0, 0, 0, 0.8);

    &__content {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
  }

  .video-media {
    background: #CCC;
    border-radius: 15px;

    img {
      width: 40px;
      margin-top: 40px;
      padding-bottom: 30px;
      border-radius: 0;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
</style>

<template>
  <div class="model-card">
    <slot v-if="!isFeatured">
      <router-link :to="data.url" class="link">
        <img v-if="data.picture" :src="data.picture"/>
        <img v-else src="@/assets/images/avatar_default.png"/>
      </router-link>

      <router-link :to="data.url" class="link">
        <span class="link">{{data.username}}</span>
      </router-link>
    </slot>
    <slot v-else>
      <div class="link">
        <img v-if="data.picture" :src="data.picture"/>
        <img v-else src="@/assets/images/avatar_default.png"/>
        <span class="link">{{data.username}}</span>
      </div>
    </slot>

    <Button
      v-if="isFeatured"
      :text="data.featured ? 'Remove' : 'Add'"
      :type="data.featured ? 'red' : 'green'"
      size="small" class="mt-10 mb-20" rounded
      @onClick="onButtonClick(data.featured)"
    />
  </div>
</template>

<script>
  import { Button } from '@/components';

  export default {
    name: 'ModelCard',
    components: { Button },
    props: {
      data: {
        picture: String,
        username: String,
        url: String,
      },
      isFeatured: {
        type: Boolean,
        default: false,
      }
    },
    setup(_, { emit }) {
      const onButtonClick = (value) => {
        emit('action', Boolean(value));
      };

      return { onButtonClick };
    }
  }
</script>

<style lang="scss" scoped>
  .model-card {
    position: relative;
    display: inline-block;
    width: calc(50% - 20px);
    margin: 10px;

    text-overflow: ellipsis;
    overflow: hidden;

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }

    .link {
      font-weight: bold;
      line-height: 20px;
    }

    a {
      text-decoration: none;
    }
  }
</style>

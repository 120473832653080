<template>
  <h1 class="header text-center text-uppercase">
    Setup Stream
  </h1>

  <form class="form">
    <div class="form__group">
      <Input
          :value="form.name"
          placeholder="Give it a name"
          @onInput="onInput('name', $event)"
      />
    </div>
    <div class="form__group">
      <Textarea
          :value="form.description"
          placeholder="Description"
          @onInput="onInput('description', $event)"
      />
    </div>
  </form>

  <form class="form switchers">
    <div class="form__group">
      <Switch label="SAVE VIDEO AND CREATE POST" @onChange="onSaveSelected"/>
      <Switch label="IS STREAM PRIVATE?" :checked="form.isPrivate" @onChange="onMakeStreamPrivate"/>
      <div class="form__group" v-if="form.isPrivate">
        <Input
            prepend="$"
            type="number"
            :value="form.price"
            placeholder="Setup price to join"
            @onInput="onInput('price', $event)"
        />
      </div>
      <Switch label="HAS GOAL" :checked="hasGoal" @onChange="onSetGoal"/>
      <div class="form__group" v-if="hasGoal">
        <Input
            prepend="$"
            type="number"
            :value="form.goal"
            placeholder="Goal amount"
            @onInput="onInput('goal', $event)"
        />
      </div>
    </div>

    <div class="button-loader mt-40 text-center">
      <Button
        v-if="hasStream && !form.isPrivate"
        :disabled="isSendingData"
        type="red"
        size="m"
        rounded
        text="CONTINUE STREAMING"
        class="mb-30"
        @onClick="onSubmit($event, 'continue')"
      />
      <Button
        :disabled="isSendingData"
        type="green"
        text="NEW STREAM"
        rounded
        size="m"
        @onClick="onSubmit($event, 'new')"
      />

      <div v-if="isSendingData" class="button-loader__wrapper text-center">
        <img src="@/assets/images/spinner.svg"/>
      </div>
    </div>
  </form>
</template>

<script>
  import { ref, reactive, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { creator } from '@/api';
  import {
    Input,
    Textarea,
    Button,
    Switch
  } from '@/components';

  export default {
    name: 'CreatorNewBroadcastPage',
    components: {
      Input,
      Textarea,
      Button,
      Switch
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const isSendingData = ref(false);
      const hasStream = ref(false);
      const currentStream = ref(null);
      const hasGoal = ref(false);
      const form = reactive({
        name: '',
        description: '',
        price: null,
        isPrivate: false,
        isNew: false,
        goal: 0,
      });

      const isValid = () => {
        let errorTxt = '';

        if (!hasStream.value) {
          if (!form.name || !form.description) {
            errorTxt = 'Stream name and description can\'t be empty';
          }
        }

        if (form.isPrivate)  {
          if (form.price <= 0) {
            errorTxt = 'Please setup stream price';
          }
        }

        if (errorTxt) {
          store.dispatch('message/show', {
            type: 'error',
            text: errorTxt,
          });
          return false;
        }
        return true;
      }

      const onSubmit = async (e, type) => {
        e.preventDefault();

        if (!isValid()) {
          return false;
        }

        if (type === 'new') {
          form.isNew = true;
        }

        if (form.isPrivate) {
          const res = await store.dispatch('creator/createStream', form);

          await store.dispatch('creator/createPrivateStream', {
            ...form,
            sid: res.dbStream.streamId
          });
          await router.push('/app/live-broadcast');
        } else {
          await store.dispatch('creator/createStream', form);
          await router.push('/app/live-broadcast');
        }
      };

      const onInput = (type, value) => {
        form[type] = value;
      }

      const onSaveSelected = (isSelected) => {
        if (isSelected) {
          form.saveOnS3 = true;
          form.createPost = true;
        } else {
          delete form.saveOnS3;
          delete form.createPost;
        }
      }

      const onMakeStreamPrivate = (value) => {
        form.isPrivate = value;
      }

      const onSetGoal = (value) => {
        hasGoal.value = value;
      }

      onMounted(async () => {
        const { data } = await creator.getModelStream();

        if (!data.error && data.dbStream) {
          hasStream.value = true;
          currentStream.value = data;

          form.name = data.dbStream.snapshot.name;
          form.description = data.dbStream.snapshot.description;
        }
      });

      return {
        form,
        isSendingData,
        hasStream,
        currentStream,
        hasGoal,
        onSubmit,
        onInput,
        onSaveSelected,
        onMakeStreamPrivate,
        onSetGoal,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 30px 0;
  }

  .form {
    padding: 0 20px;
  }

  .attach-media {
    padding: 30px 20px;
    justify-content: space-around;
  }

  .attach-media-item {
    &__img-wrapper {
      position: relative;
      width: 110px;
      height: 80px;

      img {
        height: 100%;
      }
    }

    &__lbl {
      width: 110px;
      margin-top: 25px;

      font-family: Roboto;
      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
    }

    &__file-btn {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0;
    }
  }

  .switchers {
    margin: 10px 0 30px 0;
  }

  .switch {
    margin: 10px 0;
  }

  .thumb {
    margin: 20px;
    height: 200px;

    border: 1px solid #4e4c4c;

    img, video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .button-loader {
    position: relative;

    &__wrapper {
      position: absolute;
      top: 0;
      height: 61px;
      width: 100%;

      background: rgba(0,0,0, 0.82);

      img {
        height: 100%;
      }
    }
  }

  .clear-btn {
    width: 56px;
    height: 56px;
    padding: 13px;

    border-radius: 30px;
    background: #313131;
  }
</style>

<template>
  <component :is="componentName"></component>
</template>

<script>
  import { useRoute } from 'vue-router';
  import { Terms, Privacy, Cookies, USC, DMCA } from '../views/Policy';

  export default {
    name: 'PublicPagesController',
    setup() {
      const route = useRoute();
      const { params: { type }} = route;
      const componentName = {
        terms: Terms,
        privacy: Privacy,
        cookies: Cookies,
        usc: USC,
        dmca: DMCA,
      }[type];

      return {
        componentName,
      }
    }
  }
</script>

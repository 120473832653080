export default {
  postReportsList: [
    { text: 'I don\'t like this post', value: 'I don\'t like this post' },
    { text: 'Potentially features a minor', value: 'Potentially features a minor' },
    { text: 'Committing Violent or Harmful Acts', value: 'Committing Violent or Harmful Acts' },
    { text: 'Technical Issue - Not Playing/Displaying', value: 'Technical Issue - Not Playing/Displaying' },
    { text: 'This content contains stolen material (DMCA)', value: 'This content contains stolen material (DMCA)' },
    { text: 'This content violates "Rhino Fans" Terms of Service.', value: 'This content violates "Rhino Fans" Terms of Service.' },
    { text: 'Broadcaster is abusive', value: 'Broadcaster is abusive' },
    { text: 'Broadcaster is intoxicated', value: 'Broadcaster is intoxicated' },
    { text: 'Broadcaster is sleeping', value: 'Broadcaster is sleeping' },
    { text: 'Broadcaster is wrong gender', value: 'Broadcaster is wrong gender' },
    { text: 'Broadcasting in service uniform', value: 'Broadcasting in service uniform' },
    { text: 'This content is Spam', value: 'This content is Spam' },
    { text: 'Report Other Abuse', value: 'Report Other Abuse' },
  ],
  userReportsList: [
    { text: 'Technical Issue - Not Playing/Displaying', value: 'technical' },
    { text: 'Potentially features a minor', value: 'potentially_features_minor' },
    { text: 'Committing Violent or Harmful Acts', value: 'committing_violence_harmful_acts' },
    { text: 'Broadcaster is abusive', value: 'broadcaster_abusive' },
    { text: 'Broadcaster is intoxicated', value: 'broadcaster_intoxicated' },
    { text: 'Broadcaster is sleeping', value: 'broadcaster_sleeping' },
    { text: 'Broadcaster is wrong gender', value: 'broadcaster_wrong_gender' },
    { text: 'Broadcasting in service uniform', value: 'broadcasting_service_uniform' },
  ],
}

<template>
  <div :class="['story', {
    'is-viewed': story.isViewed
  }]">
    <img :src="story.bundle[0].thumbnail" class="story__thumb" @click="onStoryShow"/>
    <div class="story__author">
      <router-link :to="`/${story.author.username}`">
        <img :src="story.author.picture"/>
      </router-link>
    </div>
  </div>

  <Popup class="story-popup" v-if="isPopupVisible" @close="onPopupClose" isStoryPopup>
    <div class="story-popup__content">
      <div class="progress" v-if="isImage">
        <div
          class="progress__string"
          :style="progressStyles"
        ></div>
      </div>
      <router-link :to="`/${story.author.username}`" class="story-popup__author-link">
        <img :src="story.author.picture" class="story-popup__author-image"/>
        <span class="story-popup__author-username">{{story.author.username}}</span>
      </router-link>
      <img v-if="isMediaLoading" src="@/assets/images/spinner-trans.svg" class="story-popup__loader"/>
      <slot v-else>
        <img v-if="isImage" :src="mediaUrl" class="story-popup__media"/>
        <video
          v-else
          :src="mediaUrl"
          class="story-popup__media"
          @ended="onVideoEnded"
          :controls="false"
          autoplay
          playsinline
        />
      </slot>
    </div>
  </Popup>
</template>

<script>
  import { computed, ref } from 'vue';
  import { Popup } from '@/components';
  import { creator, consumer } from '@/api';

  export default {
    name: 'Story',
    components: { Popup },
    props: {
      story: {
        _id: String,
        author: {
          _id: String,
          picture: String,
        },
        bundle: [{
          thumbnail: String,
          mediaType: String,
        }]
      }
    },
    setup(props) {
      /* In seconds */
      const STORY_DURATION = 10;
      const isPopupVisible = ref(false);
      const mediaUrl = ref(null);
      const isMediaLoading = ref(false);
      const isImage = computed(() => props.story.bundle[0].mediaType === 'image');

      const progressStyles = ref({});
      const animationStyles = {
        animationDuration: `${STORY_DURATION}s`,
        animationName: 'story-progress',
      };

      const onStoryShow = async () => {
        isMediaLoading.value = true;

        const mediaData = await creator.getPostMedia({
          mid: props.story.author._id,
          pid: props.story._id
        });

        isPopupVisible.value = true;

        if (isImage.value) {
          let img = document.createElement('img');
          img.src = mediaData.data;
          img.onload = function () {
            mediaUrl.value = mediaData.data;
            isMediaLoading.value = false;
            progressStyles.value = animationStyles;

            viewStory();
            setCloseTimer();
          };
        } else {
          mediaUrl.value = mediaData.data;
          isMediaLoading.value = false;

          viewStory();
        }
      }

      const viewStory = () => {
        consumer.viewStory({
          pid: props.story._id
        });
      }

      const onPopupClose = () => {
        isPopupVisible.value = false;
        progressStyles.value = ({});
      }

      const setCloseTimer = () => {
        setTimeout(() => {
          onPopupClose();
        }, STORY_DURATION * 1000);
      }

      const onVideoEnded = () => {
        onPopupClose();
      }

      return {
        isPopupVisible,
        isMediaLoading,
        isImage,
        mediaUrl,
        progressStyles,
        onStoryShow,
        onPopupClose,
        onVideoEnded,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .story {
    position: relative;

    flex-basis: 135px;
    min-width: 135px;
    height: 160px;
    margin-right: 10px;

    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__author {
      position: absolute;
      bottom: 6px;
      left: 6px;

      width: 40px;
      height: 40px;

      border-radius: 20px;
      overflow: hidden;
      border: 2px solid #FFF;
    }

    &.is-viewed {
      opacity: 0.4;
    }
  }

  .story-popup {
    &__content {
      position: relative;
      width: 100%;
      height: 100vh;
    }

    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;

      margin: -54px 0 0 -54px;
    }

    &__media {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &__author-link {
      position: absolute;
      top: 30px;
      left: 10px;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    &__author-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    &__author-username {
      color: #fff;
      margin-left: 10px;
      text-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
    }
  }

  .progress {
    position: relative;
    top: 10px;
    left: 10px;
    height: 3px;
    width: calc(100vw - 20px);
    border: none;
    border-radius: 3px;
    background: #CCC;
    z-index: 3;

    &__string {
      position: absolute;
      background-color: #FFF;
      width: 0px;
      height: 3px;
      border-radius: 3px;
    }
  }
</style>

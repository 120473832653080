<template>
  <footer class="footer d-flex">
    <div class="footer__icon">
      <router-link to="/app/notifications">
        <img src="@/assets/images/icons/bell.svg"/>
        <span v-if="amountOfUnreadNotifications" class="footer__unread text-center">
          {{amountOfUnreadNotifications}}
        </span>
      </router-link>
    </div>
    <div class="footer__icon">
      <span class="footer__icon-unread" v-if="hasUnread"></span>
      <router-link to="/app/messaging">
        <img src="@/assets/images/icons/email.svg"/>
      </router-link>
    </div>
    <div class="footer__icon">
      <router-link to="/app/create-story">
        <img src="@/assets/images/icons/story.svg"/>
      </router-link>
    </div>
    <div class="footer__icon">
      <router-link to="/app">
        <img src="@/assets/images/icons/home.svg"/>
      </router-link>
    </div>
    <div class="footer__icon" @click="onSidebarOpen">
      <img src="@/assets/images/icons/menu.svg"/>
    </div>
  </footer>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import mqtt from 'mqtt';

  import { common } from '@/api';
  import config from '@/config';

  export default {
    name: 'CreatorFooter',
    setup() {
      const store = useStore();
      const hasUnread = ref(false);
      const amountOfUnreadNotifications = ref(0);

      const onSidebarOpen = () => {
        store.commit('app/SET_SIDEBAR_VISIBILITY', true);
      };

      watch(() => store.state.app.unread, (isUnread) => {
        hasUnread.value = isUnread;
      });

      watch(() => store.state.app.notifications, (notifications) => {
        amountOfUnreadNotifications.value = notifications.length;
      });

      /* Temporary solution. Move to APP level after MVP */
      watch(() => store.state.creator.profile, async (profile) => {
          const options = await common.getPublicOptions();
          const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);

          const channelToListen = `${options.data.mqttEnv}/chat/model/${profile.model._id}`;
          const client = mqtt.connect(config.MQTT_BROKER, {...config.MQTT_BROKER_CONFIG, clientId});

          client.on('connect', () => {
            client.subscribe(channelToListen, {qos: 0});
          });

          client.on('message', async () => {
            if (!window.location.pathname.includes('/app/messaging/')) {
              await store.dispatch('app/unread', true);
            }
          });
      });

      onMounted(() => {
        amountOfUnreadNotifications.value = store.state.app.notifications.length;
      });

      return {
        hasUnread,
        amountOfUnreadNotifications,
        onSidebarOpen
      };
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 60px;
    padding: 0 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      position: relative;
      height: 22px;

      img {
        height: 100%;
      }
    }

    &__unread {
      position: absolute;
      top: -10px;
      right: -10px;

      display: inline-block;
      width: 22px;
      height: 22px;
      padding: 5px;

      color: #FFF;
      font-size: 9px;
      font-weight: 500;
      border-radius: 20px;
      background: #D53E34;
      line-height: 15px;
    }

    &__icon-unread {
      display: inline-block;
      position: absolute;
      top: -6px;
      width: 10px;
      height: 10px;
      right: -5px;

      background: #d23838;
      border-radius: 10px;
    }
  }
</style>

<template>
  <div class="post__media">
    <span class="post__media_icon_lock">
      <img src="@/assets/images/icons/lock.svg"/>
    </span>
    <div class="buy-post-btn d-flex align-items-center" v-if="hasPrice && !post.blocked">
      <Button
        size="small"
        type="green"
        text="Buy"
        rounded
        @onClick="onPostBuy"
      />
      <slot v-if="!isDiscountAvailable">
        <span class="text">&nbsp;for&nbsp;</span>
        <span>${{post.price}}</span>
      </slot>
      <slot v-else>
        <span class="text">&nbsp;for&nbsp;</span>
        <s>${{post.price}}</s>
        <b>&nbsp;-&nbsp;${{post.promotion_price}}</b>
      </slot>
    </div>
  </div>

  <!-- Send Tip or Buy post -->
  <Popup v-if="isPaymentsWinVisible" @close="onPaymentClose">
    <USAepayForm
      :title="`Buy for $${isDiscountAvailable ? post.promotion_price : post.price}`"
      @paid="onPostBought"
      :hasSavedCard="hasSavedCard"
    />
  </Popup>
</template>

<script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { consumer } from '@/api';

  import USAepayForm from '@/components/USAepayForm';
  import { Button } from '@/components/atoms';
  import Popup from '@/components/Popup';

  export default {
    name: 'LockedMedia',
    props: {
      post: Object,
      hasSavedCard: Boolean,
      isDiscountAvailable: Boolean,
    },
    components: { USAepayForm, Button, Popup },
    setup({ post }) {
      const store = useStore();
      const hasPrice = ref(Boolean(post.price));
      const isPaymentsWinVisible = ref(false);

      const onPostBuy = () => { isPaymentsWinVisible.value = true };
      const onPaymentClose = () => { isPaymentsWinVisible.value = false };

      const onPostBought = async ({token}) => {
        const boughtMedia = await consumer.buyChatMedia({
          token,
          postId: post._id,
          mid: post.author._id
        }).catch((error) => {
          if (error.response.data.error) {
            alert(error.response.data.error);
          }
        });

        await store.dispatch('app/addBoughtPost', post._id);
        await store.dispatch('consumer/updateProfileInfo', {
          member: {
            lastTransaction: boughtMedia.data.lastTransaction,
            creditCard: boughtMedia.data.creditCard,
          }
        });
        onPaymentClose();
      }

      return { hasPrice, onPostBuy, onPostBought, isPaymentsWinVisible, onPaymentClose }
    }
  }
</script>

<style lang="scss" scoped>
  .post {
    &__media {
      position: relative;
      min-height: 200px;
      background: #000;
      margin: 10px 0;
    }

    &__media_icon_lock {
      display: block;
      position: absolute;
      width: 40px;
      top: 50%;
      left: 50%;
      margin: -30px 0 0 -20px;

      img {
        width: 48px;
      }
    }
  }

  .buy-post-btn {
    position: absolute;
    top: 10px;
    left: 10px;
  }
</style>

<template>
  <div class="post__header" v-if="isOwn">
    <div class="post__header-top">
      <time class="post__date">
        {{ (post.bundle && post.bundle.length) ? 'Bundle' : titleType }} added {{ createdAt }}
      </time>
      <div v-if="post.price" class="post__price">
        <slot v-if="!isDiscountAvailable">
          <span>${{post.price}}</span>
        </slot>
        <slot v-else>
          <s>${{post.price}}</s>
          <b>&nbsp;-&nbsp;${{post.promotion_price}}</b>
        </slot>
      </div>
    </div>
    <h1 class="post__title">{{ post.title }}</h1>
  </div>
  <div class="post__header d-flex" v-else>
    <div class="post__avatar">
      <router-link :to='`/${post.author.username}`'>
        <img v-if="post.author.picture" :src="post.author.picture" class="post__avatar-img"/>
        <img v-else src="@/assets/images/avatar_default.png" class="post__avatar-img"/>
      </router-link>
    </div>
    <div class="ml-10">
      <time class="post__date">
        {{ titleType }} added {{ createdAt }} by &nbsp;
        <router-link :to='`/${post.author.username}`' class="link post__date-link">
          <b>{{post.author.first_name}} {{post.author.last_name}}</b>
        </router-link>
      </time>
      <h1 class="post__title">{{ post.title }}</h1>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import moment from 'moment';

export default {
  name: 'PostHeader',
  props: {
    post: Object,
    isOwn: Boolean,
    isDiscountAvailable: Boolean,
  },
  setup({ post }) {
    const createdAt = computed(() => formattedDate(post.createdAt));

    const formattedDate = date => moment(date).format('MMMM DD YYYY');

    let titleType = null;

    if (post.media) {
      titleType = computed(() => {
        if (post.media.mediaType === 'video') {
          return 'Video';
        } else if (post.media.mediaType === 'image') {
          return 'Photo';
        } else if (post.media.mediaType === 'audio') {
          return 'Audio';
        }
        return 'Document';
      });
    }

    return { createdAt, titleType }
  }
}
</script>

<style lang="scss" scoped>
  .post {
    &__header {
      margin: 0;
      font-family: Roboto;
      font-style: normal;
      color: #FFF;

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__date {
      font-weight: 300;
      font-size: 10px;

      b {
        font-weight: bold;
      }

      .link {
        display: inline-block;
        width: 130px;

        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        font-size: 10px;
      }
    }

    &__title {
      word-break: break-all;
      line-height: 24px;
      font-weight: 500;
      font-size: 16px;
    }

    &__avatar {
      width: 40px;
      height: 40px;

      border-radius: 20px;
      overflow: hidden;
      flex-shrink: 0;

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">Notifications</h1>

  <h2 class="sub-header text-center text-uppercase">Push Settings</h2>

  <form class="form">
    <div class="form__group">
      <Switch
        :checked="notifications.interactions"
        label="INTERACTIONS" class="text-uppercase"
        @onChange="onChange('interactions', $event)"
      />
    </div>
    <div class="form__group">
      <Switch
        :checked="notifications.liked"
        label="Liked" class="text-uppercase"
        @onChange="onChange('liked', $event)"
      />
    </div>
    <div class="form__group">
      <Switch
        :checked="notifications.subscribed"
        label="SUBSCRIBED" class="text-uppercase"
        @onChange="onChange('subscribed', $event)"
      />
    </div>
    <div class="form__group">
      <Switch
        :checked="notifications.tipped"
        label="TIPPED" class="text-uppercase"
        @onChange="onChange('tipped', $event)"
      />
    </div>
    <!-- Hidden in scope of RHINO-317 -->
    <!-- <div class="form__group">
      <Switch
        :checked="notifications.promotions"
        label="Promotions" class="text-uppercase"
        @onChange="onChange('promotions', $event)"
      />
    </div> -->
    <div class="form__group">
      <Switch
        :checked="notifications.purchases"
        label="PURCHASES" class="text-uppercase"
        @onChange="onChange('purchases', $event)"
      />
    </div>
  </form>
</template>

<script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { Switch} from '@/components';

  export default {
    name: 'NotificationsSettings',
    components: { Switch },
    setup() {
      const store = useStore();
      const notifications = ref({ ...store.getters['creator/profile'].model.push_settings });

      const onChange = (type, value) => {
        store.dispatch(
          'creator/updateProfileInfo',
          {
            model: {
              push_settings: {
                ...store.getters['creator/profile'].model.push_settings,
                [type]: value
              },
            },
          }
        );
      };

      return { onChange, notifications };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .form {
    padding: 0 35px 35px 35px;
  }

  @media (max-width: 375px) {
    .form {
      padding: 20px;
    }
  }

  .form-group {
    margin-bottom: 20px;
  }
</style>

<template>
  <div class="public-page login">
    <div class="public-page__header">
      <img src="@/assets/images/logo-big.svg" class="public-page__logo"/>
      <h1 class="header">Login</h1>
    </div>

    <form class="form" @submit="onSubmit">
      <div :class="['form__group', {
        'is-disabled': form.email.length
      }]">
        <Input
            placeholder="Enter phone number"
            prepend="+1"
            @onInput="onFieldUpdate('phone', $event)"
        />
        <!--        <Input-->
        <!--            placeholder="Enter phone number"-->
        <!--            @onInput="onFieldUpdate('phone', $event)"-->
        <!--        />-->
        <!--vue-tel-input @input="onPhoneInput('phone', $event)"></vue-tel-input-->
        <div class="form__item-error">{{errors.loginError}}</div>
      </div>

      <div class="text-uppercase text-center">
        <span>or</span>
      </div>

      <div :class="['form__group', {
        'is-disabled': form.phone.length
      }]">
        <Input
            placeholder="Email address"
            @onInput="onFieldUpdate('email', $event)"
        />
        <div class="form__item-error">{{errors.loginError}}</div>
      </div>

      <div class="form__group">
        <Input
            placeholder="Password"
            type="password"
            @onInput="onFieldUpdate('password', $event)"
        />
        <div class="form__item-error">{{errors.passwordError}}</div>
      </div>
      <div class="form__group">
        <Checkbox label="Remember me"/>
      </div>
      <div class="form__group">
        <Button text="Login" :disabled="isLoading"/>
      </div>
    </form>

    <div class="public-page__socials">
      <h2 class="sub-header text-center">or login with</h2>
      <ul class="public-page__socials-list d-flex">
        <li>
          <div class="disabled">
            <span class="icon">
              <img src="@/assets/images/icons/instagram.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div class="disabled">
            <span class="icon">
              <img src="@/assets/images/icons/facebook.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div @click="onSocialAuth('twitter')">
            <span class="icon">
              <img src="@/assets/images/icons/twitter.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div @click="onSocialAuth('google')">
            <span class="icon">
              <img src="@/assets/images/icons/google.svg"/>
            </span>
          </div>
        </li>
      </ul>
      <div class="public-page__socials-links d-flex">
        <router-link to="/registration" class="link">
          First Time User Register Here!
        </router-link>
        <!--        <router-link to="/forgot-password" class="link">-->
        <!--          Forgot password?-->
        <!--        </router-link>-->
      </div>
    </div>

    <FooterPublic/>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { getCookie } from '@/utils';

import { Input, Checkbox, Button, FooterPublic } from '@/components';
import config from '@/config';
import { errorsToMessage } from '@/utils';
import { auth } from '@/api';

export default {
  name: 'Login',
  emits: ['click'],
  components: {
    Input, Checkbox, Button, FooterPublic,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const errors = ref({
      loginError: null,
      passwordError: null
    });
    const form = reactive({
      phone: '',
      email: '',
      password: ''
    });
    const isLoading = ref(false);
    const isTransferCompleted = ref(route.query?.transferCompleted);

    const onFieldUpdate = (fieldName, value) => {
      form[fieldName] = value;
    };

    const onPhoneInput = (fieldName, event) => {
      if (event) {
        if (typeof event !== 'string') {
          form[fieldName] = event.target.value;
        }
      }
    };

    const onSubmit = (event) => {
      event.preventDefault();

      clearValidationErrors();

      validateForm();

      if (!isFormValid()) return false;

      const data = {
        password: form.password,
      };

      if (form.email) {
        data.email = form.email;
      } else {
        data.phone = '+1' + form.phone.replace(/\s/g, '');
        // data.phone = form.phone.replace(/\s/g, '');
      }

      isLoading.value = true;

      store.dispatch('app/signIn', data)
          .then(() => {
            router.go(0);
          })
          .catch((e) => {
            const data = e.response.data;
            let errorText = null;

            if (data) {
              if (e.response.data.errors) {
                errorText = errorsToMessage(data.errors);
              } else {
                errorText = data.message;
              }
            }

            store.dispatch('message/show', { text: errorText, type: 'error' });
          })
          .finally(() => {
            isLoading.value = false;
          });
    }

    const clearValidationErrors = () => {
      Object.keys(errors.value).forEach((fieldName) => {
        errors.value[fieldName] = null;
      });
    };

    const validateForm = () => {
      if (!form.phone && !form.email) {
        errors.value.loginError = 'Phone or email can\'t be empty';
      }
      if (form.password.length < config.passwordMinLength) {
        errors.value.passwordError = `Password length must be at least ${config.passwordMinLength} characters long`;
      }
      if (!form.password) {
        errors.value.passwordError = 'Password can\'t be empty';
      }
    };

    const isFormValid = () => {
      let isNoErrors = true;
      Object.keys(errors.value).forEach((fieldName) => {
        if (isNoErrors) {
          isNoErrors = !errors.value[fieldName];
        }
      });
      return isNoErrors;
    };

    const onSocialAuth = (type) => {
      if (window.location.hostname === 'localhost') {
        window.open(`http://localhost:3000/api/v1/oauth/${type}`, '_self');
      } else {
        window.open(`/api/v1/oauth/${type}`, '_self');
      }
    }

    onMounted(async () => {
      if (isTransferCompleted.value) {
        store.dispatch('message/show', {
          text: 'Your transition was completed. Now please re-login as a model',
          type: 'info',
        });
      }
      if (getCookie('accessToken')) {
        const {data} = await auth.getUserByCookie();
        await store.dispatch('app/refreshToken', data);
        router.go('/app');
      }
    });

    return {
      errors,
      form,
      onFieldUpdate,
      onSubmit,
      onPhoneInput,
      onSocialAuth,
      isLoading,
    };
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.login {
  .checkbox__item-label-text {
    top: -19px !important;
  }
}

.input.has-prepend {
  padding-left: 32px !important;
}
</style>

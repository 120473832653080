import axios from 'axios';
import store from '@/store';
import { auth } from '@/api';
import router from '@/router';
import { getCookie } from '@/utils';

/* if (process.env.VUE_APP_API_PATH) {
  axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
} else {
  axios.defaults.baseURL = process.env.NODE_ENV === 'production' ?
    `${window.location.origin}/api/v1/` :
    'http://localhost:3000/api/v1/';
} */

axios.defaults.baseURL = `${window.location.origin}/api/v1/`

axios.interceptors.request.use((config) =>{
    const token = localStorage.getItem('token');
    const cookieToken = getCookie('accessToken');

    if (token) {
      config.headers['Authorization'] = token;
    } else if (cookieToken) {
      config.headers['Authorization'] = `Bearer ${cookieToken}`;
    }

    return config;
});

axios.interceptors.response.use(
  (response) => {
    /*
      If MEMBER logged in and admin switched role to MODEL.
      User should be logged out.
    */

    if (store.state.app.auth.user) {
      if (
        response?.data?.currentRole === 'model' &&
        store.state.app.auth.user.currentRole === 'member'
      ) {
        localStorage.clear();
        router.push('/?transferCompleted=true');
        return;
      }
    }

    return Promise.resolve(response);
  },
    async error => {
        const {response: {config: { url }}} = error;
        const originalConfig = error.config;
        const { status } = error.response;
        const token = localStorage.getItem('token');

        /* Reasons to make force logout */
        const isRoleChanged = url.includes('users/model_profile/') && [500].includes(status);
        const isRefreshTokenExpired = (url === '/auth/refresh-token') && [401].includes(status);

        if (isRoleChanged) {
          localStorage.clear();
          await router.push('/?transferCompleted=true');

          return
        }

        if (isRefreshTokenExpired) {
          localStorage.clear();
          await router.push('/');
          return;
        }

        if (token && [401].includes(status)) {
          const user = JSON.parse(localStorage.getItem('user'));
          const refreshParams = {
            refreshToken: localStorage.getItem('refreshToken'),
            phone: user.phone,
            email: user.email
          };

          originalConfig._retry = true;

          const newTokenData = await auth.refreshToken(refreshParams);
          await store.dispatch('app/refreshToken', { token: newTokenData.data });

          return axios(originalConfig);
        } else {
            return Promise.reject(error);
        }
    }
);

import consumer from '@/api/consumer';

const state = {
  profile: null,
  feedList: null,
  modelPostsList: null,
  topModels: null,
  feed: {
    docs: [],
    nextPage: 1,
    page: 1,
  },
  modelPosts: {
    docs: [],
    nextPage: 1,
    page: 1,
  },
  limitPagePage: 10,
  viewingModel: null,
  isSubscribingProcess: false,
  isPaymentProcess: false,
  isUnsubscribeProcess: false,
  isUnfollowProcess: false,
  search: [],
}

const getters = {
  profile: () => state.profile,
};

const actions = {
  async getOwnProfileInfo({ commit }) {
    return consumer.getOwnProfileInfo().then(({data}) => {
      commit('SET_PROFILE', data);
      return data;
    });
  },

  async updateProfileInfo({ commit }, params) {
    return consumer.updateProfileInfo(params).then(() => {
      commit('UPDATE_MODEL', params);
    });
  },

  async createModel({ commit }, params) {
    return consumer.createModel(params).then(() => {
      commit('CREATE_MODEL', params);
    });
  },

  async updateProfileAvatar({ commit }, picture) {
    commit('UPDATE_PROFILE_AVATAR', picture);
  },

  async getFeed({ commit }, data = {}) {
    const nextPage = data.next ? state.feed.nextPage : (data.page || state.feed.page);

    if (!state.feed.nextPage) {
      return null;
    }

    return consumer.getFeed({
      limit: state.limitPagePage,
      page: nextPage,
    }).then(({data}) => {
      commit('SET_FEED', data);
      return data;
    });
  },

  async getCurrentModelFeed({ commit }, data = {}) {
    const nextPage = data.next ? state.modelPosts.nextPage : (data.page || state.modelPosts.page);

    if (!state.modelPosts.nextPage) return null;

    const r = await consumer.getModelPosts({
      uid: data.uid,
      limit: state.limitPagePage,
      page: nextPage,
    });

    commit('SET_CURRENT_MODEL_FEED', r?.data);

    return r?.data;
  },

  async getTopModels({commit}, params = {limit: 10, page: 1}) {
    const { data } = await consumer.getTopModels(params);
    commit('SET_TOP_MODELS', data.docs);
    return data;
  },

  async updateProfileCover({ commit }, cover) {
    commit('UPDATE_PROFILE_COVER', cover);
  },

  async getFeaturedModels(_, params= {limit: 10, page: 1}) {
    const { data } = await consumer.getFeaturedModels(params);
    return data;
  },

  async getTopViewsModels(_, params = {limit: 10, page: 1}) {
    const { data } = await consumer.getFeaturedModels(params);
    return data;
  },

  async getTopLikesModels(_, params = {limit: 10, page: 1}) {
    const { data } = await consumer.getFeaturedModels(params);
    return data;
  },

  async getModelByUsername({commit}, params) {
    const { data } = await consumer.getModelByUsername(params);
    commit('SET_VIEWING_MODEL', data);
    return data;
  },

  updateViewingModelData({commit}, data) {
    commit('UPDATE_VIEWING_MODEL', data);
  },

  clearFeed({commit}) {
    commit('ClEAR_FEED');
  },

  clearCurrentModelFeed({commit}) {
    commit('CLEAR_CURRENT_MODEL_FEED');
  },

  setSubscribingProcess({commit}, status) {
    commit('SET_SUBSCRIBING_STATUS', status);
  },

  setPaymentProcess({commit}, status) {
    commit('SET_PAYMENT_STATUS', status);
  },

  setUnsubscribeConfirmProcess({commit}, status) {
    commit('SET_UNSUBSCRIBE_CONFIRM_STATUS', status);
  },

  setUnfollowConfirmProcess({commit}, status) {
    commit('SET_UNFOLLOW_CONFIRM_STATUS', status);
  },

  async searchModels({commit}, params) {
    const { data } = await consumer.searchForModel(params);
    commit('SET_SEARCH', data);
    return data;
  }
}

const mutations = {
  SET_PROFILE (state, data) {
    state.profile = data;
  },
  UPDATE_MODEL (state, data) {
    state.profile = {
      ...state.profile,
      member: {
        ...state.profile.member,
        ...data.member
      }
    };
  },
  CREATE_MODEL (state, data) {
    state.profile = {
      ...state.profile,
      model: {
        ...data.model,
      }
    }
  },
  UPDATE_PROFILE_COVER (state, picture) {
    state.profile.model.home_settings.picture = picture;
  },
  SET_FEED (state, data) {
    if (data.docs.length) {
      state.feed = { ...data };
    }

    if (!state.feedList) {
      state.feedList = [ ...data.docs ];
    } else {
      state.feedList = [ ...state.feedList, ...data.docs ];
    }
  },
  SET_TOP_MODELS (state, data) {
    state.topModels = data;
  },
  SET_VIEWING_MODEL (state, data) {
    state.viewingModel = { ...data };
  },
  UPDATE_VIEWING_MODEL (state, data) {
    state.viewingModel = {
      ...state.viewingModel,
      model: { ...data },
    };
  },
  ClEAR_FEED (state) {
    state.feedList = null;
    state.topModels = null;
    state.feed = {
      docs: [],
      nextPage: 1,
      page: 1,
    };
  },
  SET_SUBSCRIBING_STATUS (state, status) {
    state.isSubscribingProcess = status;
  },
  SET_PAYMENT_STATUS (state, status) {
    state.isPaymentProcess = status;
  },
  SET_UNSUBSCRIBE_CONFIRM_STATUS (state, status) {
    state.isUnsubscribeProcess = status;
  },
  SET_UNFOLLOW_CONFIRM_STATUS (state, status) {
    state.isUnfollowProcess = status;
  },
  SET_CURRENT_MODEL_FEED (state, data) {
    if (data.docs.length) {
      state.modelPosts = { ...data };
    }

    if (!state.modelPostsList) {
      state.modelPostsList = [ ...data.docs ];
    } else {
      state.modelPostsList = [ ...state.modelPostsList, ...data.docs ];
    }
  },
  CLEAR_CURRENT_MODEL_FEED (state) {
    state.modelPostsList = null;
    state.modelPosts = {
      docs: [],
      nextPage: 1,
      page: 1,
    };
  },
  SET_SEARCH (state, data) {
    state.search = data;
  },
  UPDATE_PROFILE_AVATAR (state, picture) {
    state.profile.member.picture = picture;
  },
};

export default {
  namespaced: true,
  state, getters, actions, mutations,
};

<template>
  <h2 class="header text-center text-uppercase header_size_sm">
    {{title}}
  </h2>
  <div class="models-list" v-if="models">
    <p v-if="isLoading" class="text text-center">
      <img src="@/assets/images/spinner.svg"/>
    </p>
    <p v-if="!models.length" class="text-center text">
      No featured models
    </p>
    <slot v-else>
      <ModelCard
          :key="model._id"
          v-for="model in models"
          :data="{ ...model, url: `/${model.username}` }"
      />
    </slot>
  </div>
</template>

<script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { ModelCard } from '@/components';
  import { consumer } from '@/api';

  export default {
    name: 'ConsumerPopularFilterPage',
    components: { ModelCard },
    setup() {
      const route = useRoute();
      const MODELS_PER_PAGE = 10;
      const models = ref(null);
      const isLoading = ref(true);
      let contentBlock = null;
      let page = 1;
      let data = null;

      const title = {
        'liked': 'Most Liked',
        'viewed': 'Most Viewed',
        'popular': 'Most Popular',
        'newest': 'New',
        'trending': 'Most Trending'
      }[route.params.type];

      const getModels = async () => {
        if (data.data.nextPage) {
          isLoading.value = true;
          page = data.data.nextPage;

          await fetchModels();

          isLoading.value = false;
        }
      }

      const fetchModels = async () => {
        const modelsFilterType = route.params.type;

        switch (modelsFilterType) {
          case 'liked':
            data = await consumer.getPopularModels({ type: 'like', limit: MODELS_PER_PAGE, page });
            break;
          case 'viewed':
            data = await consumer.getPopularModels({ type: 'view', limit: MODELS_PER_PAGE, page });
            break;
          case 'newest':
            data = await consumer.getNewestModels({ limit: MODELS_PER_PAGE, page });
            break;
          case 'trending':
            data = await consumer.getTrendingModels({ limit: MODELS_PER_PAGE, page });
            break;
          default:
            data = await consumer.getTopModels({ limit: MODELS_PER_PAGE, page });
        }

        if (models.value) {
          models.value = [...models.value, ...data.data.docs.filter((model) => model)];
        } else {
          models.value = [ ...data.data.docs.filter((model) => model) ];
        }
      }

      const onContentScroll = () => {
        const contentHeight = contentBlock.offsetHeight;

        if (!isLoading.value && contentHeight > innerHeight) {
          if (contentHeight - document.documentElement.scrollTop < window.innerHeight) {
            getModels();
          }
        }
      };

      onMounted(async () => {
        await fetchModels();
        isLoading.value = false;

        window.addEventListener('scroll', onContentScroll);
        contentBlock = document.querySelector('.content');
      });

      onUnmounted(async () => {
        window.removeEventListener('scroll', onContentScroll);
        models.value = [];
      });

      return { title, isLoading, models };
    }
  }
</script>

<style lang="scss" scoped>
.header {
  margin: 20px;
}

.menu {
  padding: 15px 30px;

  background: #141414;
  list-style-type: none;

  &__item {
    display: inline-block;

    border-right: 1px solid #444;

    &.is-active {
      color: #D5AF34;
    }
  }

  &__item:last-child {
    border: none;
  }

  .link {
    width: 50%;

    font-weight: bold;
    font-size: 11px;
  }
}

.model-cards {
  margin: 10px 0 30px 0;
}

.see-more {
  width: 40%;
  margin: 20px auto;
}
</style>

export function onXHRError(response, cb) {
  cb(response.data);

  response.data.errors.forEach((error) => {
    error.messages.forEach((msg) => {
      console.log(msg);
    });
  });
}

export function getXHRErrorsList(error) {
  const data = [];

  error.response.data.errors.forEach((error) => {
    error.messages.forEach((msg) => {
      data.push(msg);
    });
  });

  return data;
}

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function errorsToMessage(errors) {
  let resultMessage = '';

  errors.forEach((error) => {
    error.messages.forEach((message) => {
      resultMessage += `${message}. `;
    });
  });

  return resultMessage;
}

export function throttle(fn, wait) {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    fn.apply(this, arguments);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, wait);
  }

  return wrapper;
}

export function debounce(f, ms) {
  let isCooldown = false;

  return function() {
    if (isCooldown) return;

    f.apply(this, arguments);

    isCooldown = true;

    setTimeout(() => isCooldown = false, ms);
  };
}

export function sizeInMB(sizeInBytes) {
  return (sizeInBytes / (1024*1024)).toFixed(2);
}

export function sizeInGb(sizeInBytes) {
  return (sizeInBytes / (1024*1024*1024)).toFixed(2);
}

export function getVideoDuration(file, callback) {
  const video = document.createElement('video');
  video.preload = 'metadata';

  video.onloadedmetadata = function() {
    callback(video.duration)
  }

  video.src = URL.createObjectURL(file);
}

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const SI_FORMAT_SYMBOLS = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' }
]

export const toSiFormat = (num, digits) => {
  if (!num) return 0;

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = SI_FORMAT_SYMBOLS.slice().reverse().find(({ value }) => num >= value);

  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}


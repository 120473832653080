<template>
  <div class="popup">
    <div class="popup__content" :style="contentStyles">
      <div class="popup__close" @click="onClose" :style="arrowStyles">
        <img src="@/assets/images/icons/cross.svg"/>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';

  export default {
    name: 'Popup',
    props: {
      isStoryPopup: Boolean,
      noPadding: {
        type: Boolean,
        default: false,
      }
    },
    setup({ isStoryPopup, noPadding }, { emit }) {
      const defaultContentStyles = {
        padding: noPadding ? '45px 0 0 0' : 20,
      };
      const storyPopupContentStyles = {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
      };
      const storyPopupArrowStyles = {
        top: '35px',
        width: '20px',
      };

      // Rewriting styles for so called fullscreen stories
      const contentStyles = computed(() => isStoryPopup ? storyPopupContentStyles : defaultContentStyles);
      const arrowStyles = computed(() => isStoryPopup ? storyPopupArrowStyles : {});

      const onClose = () => {
        emit('close');
      };

      return { onClose, contentStyles, arrowStyles };
    }
  }
</script>

<style lang="scss" scoped>
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 2;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 95%;
      padding: 20px;
      max-width: 400px;
      transform: translate(-50%, -50%);

      background: #232323;
    }

    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 4;
      width: 15px;

      img {
        width: 100%;
      }
    }
  }
</style>

<template>
    <li
      :key="report.id"
      :class="['post-report-item', `status__${report.status}`]"
    >
      <div class="post-report-item__wrapper" @click="onActionVisible">
        <span
          :class="['post-report-item__arrow', {
            'is-opened': isActionsVisible
          }]"
        >&#711;</span>
        <div class="post-report-item__content">
          <div class="post-report-item__date">{{formatReportDate(report.createdAt)}}</div>
          <div class="post-report-item__txt">{{report.report}}</div>
        </div>
      </div>

      <div
        v-if="isActionsVisible"
        class="post-report-item__actions d-flex justify-space-between mt-20"
      >
        <div class="d-flex">
          <Button v-if="isReportNew" class="mr-10" text="Accept" size="extra-small" type="green" rounded @click="onAccept"/>
          <Button text="Review Post" size="extra-small" type="yellow" rounded @click="onPostVisible"/>
        </div>
        <Button v-if="isReportNew" text="Reject" size="extra-small" type="red" rounded @click="onReject"/>
      </div>
      <div v-if="isPostVisible" class="post-preview">
        <ModelPost :post="report.post" noActions/>
        <div class="d-flex justify-center mt-20">
          <Button
            :text="isPostDisabled ? 'Enable' : 'Disable'"
            size="extra-small" type="red" rounded
            @click="onPostDisable"
            :disabled="isLoading"
          />
        </div>
      </div>
    </li>
</template>

<script>
  import { ref, watch } from 'vue';
  import moment from 'moment';

  import { admin, creator } from '@/api';
  import { Button, ModelPost } from '@/components';

  export default {
    name: 'PostReportItem',
    props: {
      report: {
        type: Object,
      }
    },
    components: { Button, ModelPost },
    setup(props, { emit }) {
      const isActionsVisible = ref(false);
      const isPostVisible = ref(false);
      const isReportNew = ref(props.report.status === 'new');
      const isPostDisabled = ref(props.report.post.isDisabled);
      const isLoading = ref(false);

      watch(() => props.report, (report) => {
        isReportNew.value = Boolean(report.status === 'new');
      });

      const formatReportDate = (date) => {
        return moment(date).format('MM/DD/YYYY - HH:MM A');
      }

      const onActionVisible = () => {
        isActionsVisible.value = !isActionsVisible.value;
        isPostVisible.value = false;
      };

      const onPostVisible = () => {
        isPostVisible.value = !isPostVisible.value;
      }

      const onAccept = async () => {
        await admin.acceptReport({ rid: props.report._id });
        emit('changed', {id: props.report._id, status: 'accepted'});  
      };

      const onReject = async () => {
        await admin.rejectReport({ rid: props.report._id });
        emit('changed', {id: props.report._id, status: 'rejected'});
      };

      const onPostDisable = async () => {
        isLoading.value = true;

        const r = await creator.updatePost({
          pid: props.report.post._id,
          data: { isDisabled: !isPostDisabled.value }
        });

        const { isDisabled } = JSON.parse(r.config.data);

        isPostDisabled.value = isDisabled;
        isLoading.value = false;
      }

      return {
        isActionsVisible, isPostVisible, isReportNew,
        formatReportDate, onActionVisible, onPostVisible, onAccept, onReject, onPostDisable,
        isPostDisabled, isLoading,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .post-report-item {
    position: relative;
    padding: 12px;
    margin-bottom: 1px;

    background: #000;
    border-radius: 2px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;

    &__txt {
      font-size: 14px;
      line-height: 16px;
      word-break: break-word;
    }

    &__date {
      font-size: 10px;
      line-height: 18px;
      color: #888;
    }

    &__arrow {
      position: absolute;
      right: 10px;
      width: 18px;
      height: 18px;

      text-align: center;
      font-size: 28px;

      &.is-opened {
        transform: rotate(180deg);
        -webkit-transform-origin-x: 7px;
      }
    }

    &.status__new {
      border-left: 1px solid #67AFCE;
    }
    &.status__accepted {
      border-left: 1px solid #67CE67;
    }
    &.status__rejected {
      border-left: 1px solid #D53E34;
    }
  }

  .post-preview {
    margin: 20px 0 10px 0;
  }
</style>

import { createStore, createLogger } from 'vuex';

import app from './modules/app';
import creator from './modules/creator';
import consumer from './modules/consumer';
import message from './modules/message';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: { app, creator, consumer, message },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});

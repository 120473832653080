<template>
  <div v-if="!isStreaming && !isStreamStopped" class="text text-center mt-20 text-uppercase">Loading...</div>
  <video autoplay="true" id="stream-player" class="stream-video" playsInline muted/>

  <div v-if="streamData && streamData.goal" class="goal text text-center mt-10">
    <b>Goal</b> is: <span>${{streamData.goal}}</span>.
    <b>Collected</b>: <span>${{streamData.amount}}</span>
  </div>

  <h4 class="sub-header text-center m-20">LIVE STREAM CHAT ROOM</h4>

  <!-- TODO: Move chat to sub component --->
  <div class="live-chat">
    <div class="live-chat__empty text-center text" v-if="!messages.length">No messages</div>
    <div class="live-chat__list" v-else>
      <div
          class="live-chat__message"
          v-for="(message, index) in messages"
          :key="index"
      >
        <div class="live-chat__message-username">{{message.username}}</div>
        <div class="live-chat__message-txt">{{message.text}}</div>
      </div>
    </div>
  </div>

  <form class="form">
    <div class="button-loader text-center">
      <Button
        :text="isStreamStopped ? 'Continue streaming' : 'Stop streaming'"
        :type="isStreamStopped ? 'green' : 'red'"
        size="m"
        class="mt-10"
        @onClick="onStreamingStopPlay"
      />
    </div>
  </form>
</template>

<script>
  import { ref, reactive, watch, onMounted, onBeforeUnmount } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import mqtt from 'mqtt';
  import { Input, Textarea, Button } from '@/components';
  import config from '@/config';
  import { common } from '@/api';

  /* eslint-disable */
  import adapter from 'webrtc-adapter';
  import { WebRTCAdaptor } from '@/utils/webrtc/webrtc_adaptor';

  export default {
    name: 'CreatorBroadcastPage',
    components: { Input, Textarea, Button },
    setup() {
      const MAX_CHAT_SIZE = 100; /* Only last 100 messages are visible */
      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const isStreaming = ref(false);
      const isStartStreamBtnEnabled = ref(false);
      const isStreamStopped = ref(false);
      const form = reactive({
        title: '',
        description: ''
      });
      const mqttClient = null;
      const streamData = reactive({
        goal: 0,
        amount: 0,
      });
      let stream = null;
      let options = null;
      const messages = ref([]);

      watch(() => route.name, () => {
        stream.closeStream();
      });

      const onStreamingStopPlay = (e) => {
        e.preventDefault();

        const { state: { creator: { stream : { dbStream }}}} = store;

        if (!isStreamStopped.value) {
          stream.stop(dbStream.streamId);
          isStreaming.value = false;
          isStreamStopped.value = true;

          if (dbStream.createPost) {
            store.dispatch('message/show', {
              text: 'New Video post created',
              type: 'info'
            });
          }
        } else {
          stream.publish(dbStream.streamId);
          isStreaming.value = true;
          isStreamStopped.value = false;
        }

        router.push('/app/new-broadcast');
      }

      const listenForGoalUpdate = async () => {
        options = await common.getPublicOptions();
        const clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;
        const mqttClient = mqtt.connect(config.MQTT_BROKER, {...config.MQTT_BROKER_CONFIG, clientId});

        mqttClient.on('error', (error) => {
          console.log('Connection error: ', error);
          mqttClient.end();
        });

        mqttClient.on('connect', () => {
          const streamId = store.state.creator.stream.dbStream.snapshot.streamId;
          const modelId = store.getters['creator/profile'].model._id;

          const goalTopic = `${options.data.mqttEnv}/stream/${streamId}/tips/${modelId}`;
          mqttClient.subscribe(goalTopic, {qos: 0});

          const streamChatTopic = `${options.data.mqttEnv}/stream/chat/${streamId}`;
          mqttClient.subscribe(streamChatTopic, {qos: 0});
        });

        mqttClient.on('message', async (topic, message) => {
          try {

            /* Listen to messages topic */
            if (topic.includes('/stream/chat')) {
              if (messages.value.length <= MAX_CHAT_SIZE) {
                messages.value = [
                  JSON.parse(message.toString()),
                  ...messages.value,
                ]
              } else {
                messages.value = [
                  JSON.parse(message.toString()),
                  ...messages.value.slice(0, messages.value.length - 1),
                ]
              }
            }

            /* Listen to goals topic */
            else {
              streamData.amount = JSON.parse(message.toString()).attachments[0].amount;
            }
          } catch (e) {
            console.log(`Can't parse <${topic}> message`);
          }
        });
      }

      onMounted(() => {
        try {
          if (!store.state.creator.stream) {
            return window.location = '/app/new-broadcast';
          } else {
            const {state: {creator: {stream: {dbStream}}}} = store;
            streamData.amount = dbStream.amount;
            streamData.goal = dbStream.goal;

            stream = new WebRTCAdaptor({
              websocket_url: config.ANTMEDIA_SERVER_SOCKET,
              mediaConstraints: {
                "audio": true,
                "video": {
                  "width": {
                    "min": "300",
                    "max": "640"
                  },
                  "height": {
                    "min": "200",
                    "max": "480"
                  },
                  facingMode: 'user',
                },
              },
              peerconnection_config: {
                'iceServers': config.ICE_SERVERS,
              },
              sdp_constraints: {
                OfferToReceiveAudio: false,
                OfferToReceiveVideo: false
              },
              localVideoId: 'stream-player',
              debug: true,
              callback: () => {
                isStartStreamBtnEnabled.value = true;

                if (stream && store.state.creator.stream) {
                  if (!isStreaming.value && !isStreamStopped.value) {
                    if (stream.webSocketAdaptor && stream.webSocketAdaptor.connected) {
                      stream.publish(store.state.creator.stream.dbStream.streamId);
                      isStreaming.value = true;
                    }
                  }
                }
              },
              callbackError: function (error, message) {
                console.log(error, message);
              }
            });
            listenForGoalUpdate();
          }
        } catch(e) {
          console.log('WEBRTC error', e);
        }
      });

      onBeforeUnmount(() => {
        const streamId = store.state.creator.stream.dbStream.snapshot.streamId;
        const modelId = store.getters['creator/profile'].model._id;

        stream.stop(streamId);
        stream.closeStream();
        stream.closeWebSocket();

        if (mqttClient) {
          const goalTopic = `${options.data.mqttEnv}/stream/${streamId}/tips/${modelId}`;
          mqttClient.unsubscribe(goalTopic);

          const streamChatTopic = `${options.data.mqttEnv}/stream/chat/${streamId}`;
          mqttClient.unsubscribe(streamChatTopic);
        }
      });

      return {
        form,
        isStreaming,
        isStreamStopped,
        streamData,
        messages,
        onStreamingStopPlay
      };
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    position: fixed;
    bottom: 80px;
    width: 100%;
    padding: 0 20px;
  }

  .stream-video {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
  }

  .live-chat {
    height: calc(100vh - 615px);
    overflow-y: auto;

    &__list {
      padding: 0 20px 20px;
    }

    &__message {
      margin-bottom: 20px;

      font-family: Roboto;
      font-style: italic;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.1em;
    }

    &__message-username {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .goal {
    font-size: 14px;

    b {
      color: #D5AF34;
      font-weight: bold;
    }

    span {
      color: rgb(160, 217, 160);
    }
  }
</style>

<template>
  <div class="model-request">
    <h1 class="header text-center text-uppercase">Model request</h1>
    <h2 class="sub-header text-center text-uppercase" v-if="step">Step {{step}} out of 4</h2>

    <InfoStep v-if="step === 0" :onStepIncrement="onStepIncrement"/>

    <ModelForm v-else-if="step === 1" :onStepIncrement="onStepIncrement" />

    <ImagesForm v-else-if="step === 2" :onStepIncrement="onStepIncrement" />

    <VerificationForm v-else-if="step === 3" :onStepIncrement="onStepIncrement" />

    <DocusignForm v-else />
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

import DocusignForm from './steps/DocusignForm';
import VerificationForm from './steps/VerificationForm';
import ImagesForm from './steps/ImagesForm';
import ModelForm from './steps/ModelForm';
import InfoStep from './steps/InfoStep';

export default {
  name: 'ModelRequest',
  components: { DocusignForm, VerificationForm, ImagesForm, ModelForm, InfoStep },
  setup() {
    const store = useStore();
    const model = ref(store.state.consumer.profile.model);
    const step = ref(0);

    const getStep = () => {
      if (model.value) {
        if (model.value.vouched?.id) step.value = 4;
        else if (model.value.picture && !model.value.vouched?.id) step.value = 3;
        else if (model.value.createdAt) step.value = 2;
        else step.value = 1;
      } else {
        step.value = 0;
      }
    }

    watch(() => store.state.app.auth?.user?.model, () => {
      getStep();
    })

    onMounted(() => { getStep() });

    const onStepIncrement = () => { step.value += 1 };

    return { step, onStepIncrement }
  },
}
</script>

<style scoped>
  .header {
    margin: 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }
</style>

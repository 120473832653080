import {createRouter, createWebHistory } from 'vue-router';

import routerConfig from '@/config/router';
import PublicRoutes from './public';
import ConsumerRoutes from './consumer';
import CreatorRoutes from './creator';
import AdminRoutes from './admin';
import CommonRoutes from './common';
import AuthPagesController from '../controllers/AuthPagesController';

const routes = [
  ...PublicRoutes,
  {
    path: '/app',
    name: 'Home',
    component: AuthPagesController,
    meta: { auth: true, dependOnRole: true },
  },
  {
    path: '/app/security',
    name: 'SecurityPage',
    component: AuthPagesController,
    meta: { auth: true },
  },
  {
    path: '/app/account',
    name: 'Account',
    component: AuthPagesController,
    meta: { auth: true, dependOnRole: true },
  },
  {
    path: '/app/billing',
    name: 'Billing',
    component: AuthPagesController,
    meta: { auth: true, dependOnRole: true },
  },
  {
    path: '/app/messaging',
    name: 'MessagingListPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member', 'model'],
    },
  },
  {
    path: '/app/model-request',
    name: 'ModelRequest',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
    },
  },
  {
    path: '/app/messaging/:username',
    name: 'MessagingPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member', 'model'],
    },
  },
  ...CreatorRoutes,
  ...ConsumerRoutes,
  ...AdminRoutes,
  ...CommonRoutes,
  {
    path: '/app/:modelId/posts/:postId',
    name: 'PostPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member', 'model'],
    },
  },
  {
    path: '/:modelUsername',
    name: 'ModelPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member', 'model', 'admin'],
      header: 'ModelPageHeader'
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
  {
    path: '/assets',
    name: 'Assets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Assets.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

routerConfig.routerHandler(router);

export default router;

<template>
  <div class="dashboard">
    <h2 class="header text-center text-uppercase">ADMIN DASHBOARD</h2>

    <ul class="dashboard__list">
      <li class="dashboard__item text-center">
        <router-link to="/app/admin/reports">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/dashboard-moderation.svg"/>
          </span>
          <div class="dashboard__item-txt">Post Reports</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/model-reports">
          <span class="dashboard__item-img dashboard__icon-model-report d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/model-report.svg"/>
          </span>
          <div class="dashboard__item-txt">User Reports</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/analytics">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/analytics.svg"/>
          </span>
          <div class="dashboard__item-txt">Analytics</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/featured">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/featured.svg"/>
          </span>
          <div class="dashboard__item-txt">Featured</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/payouts">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <span class="dashboard__item-symbol">$</span>
          </span>
          <div class="dashboard__item-txt">Payout Requests</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/users">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/user-stroke.svg"/>
          </span>
          <div class="dashboard__item-txt">Users</div>
        </router-link>
      </li>

      <li class="dashboard__item text-center">
        <router-link to="/app/admin/model-requests">
          <span class="dashboard__item-img d-flex align-items-center justify-center">
            <img src="@/assets/images/icons/user-stroke.svg"/>
          </span>
          <div class="dashboard__item-txt">Model requests</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'AdminHomePages',
    setup() {

    }
  }
</script>

<style lang="scss" scoped>
  .dashboard {
    margin: 30px 10px 10px 10px;

    &__list {
      display: grid;
      margin-top: 30px;

      grid-template-columns: 50% 50%;
      row-gap: 20px;

      list-style-type: none;
    }

    &__item {
      display: flex;
      width: 150px;
      height: 150px;

      background: #000;
      justify-self: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;

        text-decoration: none;
      }
    }

    &__item-txt {
      position: relative;
      top: 15px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      color: #FFFFFF;
    }

    &__item-img {
      height: 70px;
      margin-top: 30px;
    }

    &__item-symbol {
      font-size: 48px;
      color: #FFF;
    }

    &__icon-model-report {
      img {
        height: 45px;
      }
    }
  }
</style>

import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/live-stream',
    name: 'LiveStreamPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
    },
  },
  {
    path: '/app/live',
    name: 'LiveModelsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      key: 'live',
      roles: ['member'],
    },
  },
  {
    path: '/app/models/featured',
    name: 'ConsumerFeaturedModelsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      key: 'featured',
      roles: ['member'],
    },
  },
  {
    path: '/app/models/:filter',
    name: 'ConsumerModelsFilterPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      key: 'popular',
      roles: ['member'],
    },
  },
  {
    path: '/app/models/popular/:type',
    name: 'ConsumerPopularFilterPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
      key: 'popular',
    },
  },
  {
    path: '/app/search',
    name: 'CreatorSearchPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
      isFullscreen: true,
    },
  },
  {
    path: '/app/search-results',
    name: 'CreatorSearchResultsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
    },
  },
  {
    path: '/app/payments',
    name: 'PaymentsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['member'],
    },
  },
];

import creator from '../../api/creator';

const state = {
  profile: null,
  posts: null,
  stream: null,
}

const getters = {
  profile: () => state.profile,
};

const actions = {
  async getProfileInfo({ commit }, params) {
    return creator.getProfileInfo(params).then(({data}) => {
      commit('SET_PROFILE', data);
    });
  },

  async getOwnProfileInfo({ commit }) {
    return creator.getOwnProfileInfo().then(({data}) => {
      commit('SET_PROFILE', data);
      return data;
    });
  },

  async updateProfileAvatar({ commit }, picture) {
    commit('UPDATE_PROFILE_AVATAR', picture);
  },

  async updateProfileCover({ commit }, cover) {
    commit('UPDATE_PROFILE_COVER', cover);
  },

  async updateProfileInfo({ commit }, params) {
    return creator.updateProfileInfo(params).then(() => {
      commit('UPDATE_MODEL', params);
    });
  },

  async createPost(_, params) {
    return creator.createPost(params);
  },

  async getPostsList({ commit }, params) {
    return creator.getPostsList(params).then(({data}) => {
      commit('SET_POSTS', data);
      return data;
    });
  },

  async createStream({ commit }, params) {
    return creator.createStream(params).then(({data}) => {
      commit('SET_STREAM', data);
      return data;
    });
  },

  async createPrivateStream({ commit }, params) {
    return creator.createPrivateStream(params).then(({data}) => {
      commit('SET_STREAM', data);
      return data;
    });
  }
}

const mutations = {
  SET_PROFILE (state, data) {
    state.profile = { ...data };
  },
  UPDATE_MODEL (state, data) {
    state.profile = {
      ...state.profile,
      model: { ...state.profile.model, ...data.model },
    };
  },
  UPDATE_PROFILE_AVATAR (state, picture) {
    state.profile.model.picture = picture;
  },
  UPDATE_PROFILE_COVER (state, picture) {
    state.profile.model.home_settings.picture = picture;
  },
  SET_POSTS (state, data) {
    state.posts = data;
  },
  SET_STREAM (state, data) {
    state.stream = data;
  },
};

export default {
  namespaced: true,
  state, getters, actions, mutations,
};

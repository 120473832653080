<template>
  <div class="attach-media-wrapper">
    <div class="attach-media d-flex">
      <div class="attach-media-item text-center">
        <div class="attach-media-item__img-wrapper">
          <img src="@/assets/images/icons/video.svg"/>
          <input
              type="file"
              accept="video/*"
              class="attach-media-item__file-btn"
              @change="onMediaUpload($event, 'video')"
          />
        </div>
        <div class="attach-media-item__lbl">Upload a video</div>
      </div>
      <div class="attach-media-item text-center">
        <div class="attach-media-item__img-wrapper">
          <input
              type="file"
              accept="image/*"
              class="attach-media-item__file-btn"
              @change="onMediaUpload($event, 'photo')"
          />
          <img src="@/assets/images/icons/picture.svg"/>
        </div>
        <div class="attach-media-item__lbl">Upload an image</div>
      </div>
    </div>
    <div class="attach-media d-flex">
    <div class="attach-media-item text-center">
      <div class="attach-media-item__img-wrapper">
        <img src="@/assets/images/icons/audio.svg"/>
        <input
            type="file"
            accept=".mp3"
            class="attach-media-item__file-btn"
            @change="onMediaUpload($event, 'audio')"
        />
      </div>
      <div class="attach-media-item__lbl">Upload an audio</div>
    </div>
    <div class="attach-media-item text-center">
      <div class="attach-media-item__img-wrapper">
        <input
            type="file"
            accept="application/pdf"
            class="attach-media-item__file-btn"
            @change="onMediaUpload($event, 'document')"
        />
        <img src="@/assets/images/icons/document.svg"/>
      </div>
      <div class="attach-media-item__lbl">Upload a document</div>
    </div>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectMediaType',
    setup(_, { emit }) {
      const onMediaUpload = (event, type) => {
        emit('select', { event, type });
      }

      return {
        onMediaUpload,
      };
    }
  }
</script>

<style lang="scss" scoped>

</style>

<template>
  <div
    :class="['input', {
      [`input_type_${type}`]: type,
      'is-disabled': disabled,
      'has-prepend': prepend
    }]"
    :style="{
      paddingLeft: `${18 + (prepend ? (prepend.length * 10) : 0)}px`
    }"
  >
    <span class="input__prepend" v-if="prepend">{{prepend}}</span>
    <input
      :type="type"
      class="input__item"
      :placeholder="placeholder"
      :value="inputValue"
      :disabled="disabled"
      @input="onInput"
      @keyup="onKeyup"
      :autocomplete="autocomplete"
    />
    <span v-if="disabled" class="input__append">
      <img src="@/assets/images/icons/lock.svg"/>
    </span>
    <span v-if="!disabled && prepend" class="input__append">
      {{append}}
    </span>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    name: 'Input',
    props: {
      value: [String, Number],
      placeholder: String,
      type: {
        type: String,
        default: 'text',
      },
      disabled: Boolean,
      prepend: String,
      append: String,
      autocomplete: {
        type: String,
        default: 'off'
      },
    },
    setup(props, { emit }) {
      const inputValue = ref(props.value);

      watch(() => props.value, (newValue) => {
        inputValue.value = newValue;
      });

      const onInput = (e) => {
        emit('onInput', e.target.value);
      };

      const onKeyup = (e) => {
        emit('onKeyUp', e);
      }

      return { inputValue, onInput, onKeyup };
    }
  }
</script>

<style lang="scss" scoped>
  .input {
    position: relative;
    height: 38px;
    padding: 9px 12px 15px 12px;

    background-color: #FFF;

    &__item {
      width: 100%;
      height: 22px;

      background: none;
      border: 0;
      -webkit-appearance: none;

      font-family: Roboto;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1em;
      color: #666;

      &:focus {
        outline: none;
      }
    }

    &__item::placeholder {
      color: #bbbbbb;
      text-transform: uppercase;
    }

    &.is-disabled {
      padding-right: 40px;
      pointer-events: none;
    }

    &__append {
      position: absolute;
      top: 7px;
      right: 10px;
    }

    &.has-prepend {
      padding-left: 22px;
    }

    &__prepend {
      position: absolute;
      top: 11px;
      left: 10px;

      color: #888;
      font-size: 14px;
    }

    &_type_date input,
    &_type_time input {
      font-size: 12px;
    }
  }
</style>

<template>
  <span
    :class="['switch', {
      'is-disabled': disabled
    }]"
  >
    <input class="switch__item" :id="id" type="checkbox" :checked="checked" @change="onChange">
    <label :for="id">
      <span class="switch__item-label-text">{{label}}</span>
    </label>
  </span>
</template>

<script>
  import { ref } from 'vue';

  export default {
    name: 'Switch',
    props: {
      label: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      checked: Boolean,
    },
    setup(props, { emit }) {
      const data = ref({
        id: `${Math.random() * 100 >> 0}_${Date.now()}`,
      });

      const onChange = (e) => {
        if (!props.disabled) {
          emit('onChange', e.target.checked);
        }
        e.preventDefault();
      };

      return {
        id: data.value.id,
        onChange
      };
    }
  }
</script>

<style lang="scss" scoped>
  .switch {
    display: inline-block;
    width: 100%;

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .switch__item {
      position: absolute;
      opacity: 0;

      & + label {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 30px;
        padding: 0;

        font-family: Roboto;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.1em;
        color: #F2F2F2;
      }

      & + label:after {
        content: '';
        display: inline-block;
        vertical-align: text-top;
        width: 50px;
        height: 30px;
        background: transparent;
        border: 5px solid #888888;
        border-radius: 15px;
      }

      &:focus + label:after {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      &:checked + label:after {
        border-color: #D5AF34;
      }

      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      &:disabled + label:after {
        box-shadow: none;
        background: #ddd;
      }

      & + label:before {
        content: '';

        position: absolute;
        width: 16px;
        height: 16px;
        top: 7px;
        right: 27px;

        border-radius: 10px;
        background: #888;
        -webkit-transition: right .25s;
      }

      &:checked + label:before {
        right: 7px;
        background: #D5AF34;
      }

      &-label-text {
        display: block;
        position: relative;

        line-height: 18px;
      }
    }
  }
</style>

<template>
  <li
    :key="report.id"
    :class="['report-item', `status__${report.status}`]"
  >
    <div class="report-item__wrapper" @click="onActionVisible">
      <span
        v-if="isReportNew"
        :class="['report-item__arrow', {
          'is-opened': isActionsVisible
        }]"
      >&#711;</span>
      <span
        v-else
        :class="['report-item__status text-uppercase', {
          'report-item__status-approved': report.status === 'accepted',
          'report-item__status-rejected': report.status === 'rejected'
        }]"
      >
        {{report.status}}
      </span>

      <div class="report-item__content">
        <div class="report-item__date">
          {{formatReportDate(report.createdAt)}}
        </div>
        <div class="report-item__txt">
          {{getRepostTitle(report.reportType)}}
        </div>
        <div class="report-item__model">
          <!--router-link :to="`/${report.model.username}`">
            {{report.model.username}}
          </router-link-->
          {{report.model.username}}
        </div>
      </div>
    </div>

    <div
      v-if="isActionsVisible && isReportNew"
      class="report-item__actions d-flex justify-space-between mt-20"
    >
      <Button text="Accept" size="extra-small" type="green" rounded @click="onAccept"/>
      <Button text="Reject" size="extra-small" type="red" rounded @click="onReject"/>
    </div>
  </li>
</template>

<script>
  import { ref, watch } from 'vue';
  import moment from 'moment';

  import textConfig from '@/config/text';
  import { Button } from '@/components';
  import { admin } from '@/api';

  export default {
    name: 'ModelReportItem',
    props: {
      report: {
        type: Object,
      }
    },
    components: {
      Button
    },
    setup(props, {emit}) {
      const { userReportsList } = textConfig;
      const isReportNew = ref(props.report.status === 'new');
      const isActionsVisible = ref(false);

      watch(() => props.report, (report) => {
        isReportNew.value = Boolean(report.status === 'new');
      });

      const formatReportDate = (date) => {
        return moment(date).format('MM/DD/YYYY - HH:MM A');
      }

      const getRepostTitle = (reportKey) => {
        return userReportsList.find(({value}) => value === reportKey).text;
      }

      const onActionVisible = () => {
        isActionsVisible.value = !isActionsVisible.value;
      };

      const onAccept = async () => {
        await admin.acceptModelReport(props.report._id);
        emit('accepted', { rid: props.report._id });
      }

      const onReject = async () => {
        await admin.rejectModelReport(props.report._id);
        emit('rejected', { rid: props.report._id });
      }

      return {
        isReportNew,
        isActionsVisible,
        getRepostTitle,
        formatReportDate,
        onActionVisible,
        onAccept,
        onReject,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .report-item {
    position: relative;
    padding: 12px;
    margin-bottom: 1px;

    background: #000;
    border-radius: 2px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;

    &__txt {
      font-size: 14px;
      line-height: 16px;
      word-break: break-word;
    }

    &__date {
      font-size: 10px;
      line-height: 18px;
      color: #888;
    }

    &__arrow {
      position: absolute;
      right: 10px;
      width: 18px;
      height: 18px;

      text-align: center;
      font-size: 28px;

      &.is-opened {
        transform: rotate(180deg);
        -webkit-transform-origin-x: 7px;
      }
    }

    &__model {
      display: inline-block;
      padding: 2px 5px;
      margin-top: 10px;

      border-radius: 5px;
      font-size: 11px;
      background: #bd920d;

      a {
        color: #FFF;
        text-decoration: none;
      }
    }

    &__actions {
      padding-top: 15px;
      border-top: 1px solid #363535;
    }

    &.status__new {
      border-left: 1px solid #67AFCE;
    }
    &.status__accepted {
      border-left: 1px solid #67CE67;
    }
    &.status__rejected {
      border-left: 1px solid #D53E34;
    }

    &__status {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 3px 5px;

      border-radius: 5px;
      font-size: 10px;

      &-approved {
        background: #298429;
      }
      &-rejected {
        background: #D53E34;
      }
    }
  }
</style>

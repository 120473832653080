<template>
  <h2 class="header text-center text-uppercase header_size_sm">Most Popular</h2>
  <div class="models-list" v-if="mostPopular">
    <p v-if="!mostPopular.docs.length" class="text text-center">
      <img src="@/assets/images/spinner.svg"/>
    </p>
    <slot v-else>
      <ModelCard
        :key="model._id"
        v-for="model in mostPopular.docs"
        :data="{ ...model, url: `/${model.username}` }"
      />
    </slot>
    <div class="text-center see-more text-uppercase">
      <router-link to="/app/models/popular/popular">See more</router-link>
    </div>
  </div>

  <h2 class="header text-center text-uppercase header_size_sm">Most Liked</h2>
  <div class="models-list" v-if="mostLiked">
    <p v-if="!mostLiked.length" class="text text-center">
      <img src="@/assets/images/spinner.svg"/>
    </p>
    <slot v-else>
      <ModelCard
          :key="model._id"
          v-for="model in mostLiked"
          :data="{ ...model, url: `/${model.username}` }"
      />
    </slot>
    <div class="text-center see-more text-uppercase">
      <router-link to="/app/models/popular/liked">See more</router-link>
    </div>
  </div>

  <h2 class="header text-center text-uppercase header_size_sm">Most Viewed</h2>
  <div class="models-list" v-if="mostViewed">
    <p v-if="!mostViewed.length" class="text text-center">
      <img src="@/assets/images/spinner.svg"/>
    </p>
    <slot v-else>
      <ModelCard
          :key="model._id"
          v-for="model in mostViewed"
          :data="{ ...model, url: `/${model.username}` }"
      />
    </slot>
    <div class="text-center see-more text-uppercase">
      <router-link to="/app/models/popular/viewed">See more</router-link>
    </div>
  </div>

  <h2 class="header text-center text-uppercase header_size_sm">New</h2>
  <div class="models-list" v-if="newest">
    <slot v-if="newest.length">
      <ModelCard
          :key="model._id"
          v-for="model in newest"
          :data="{ ...model, url: `/${model.username}` }"
      />
      <div class="text-center see-more text-uppercase">
        <router-link to="/app/models/popular/newest">See more</router-link>
      </div>
    </slot>
  </div>

  <h2 class="header text-center text-uppercase header_size_sm">Most Trending</h2>
  <div class="models-list" v-if="trending">
    <slot v-if="trending.length">
      <ModelCard
          :key="model._id"
          v-for="model in trending"
          :data="{ ...model, url: `/${model.username}` }"
      />
      <div class="text-center see-more text-uppercase">
        <router-link to="/app/models/popular/trending">See more</router-link>
      </div>
    </slot>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { ModelCard } from '@/components';
  import { consumer } from '@/api';

  export default {
    name: 'PopularModelsPages',
    components: { ModelCard },
    setup() {
      const MODELS_PER_PAGE = 6;
      const store = useStore();
      const mostPopular = ref(null);
      const mostLiked = ref(null);
      const mostViewed = ref(null);
      const newest = ref(null);
      const trending = ref(null);
      let page = 1;

      onMounted(async () => {
        mostPopular.value = await store.dispatch('consumer/getTopModels',{
          limit: MODELS_PER_PAGE,
          page,
        });

        const likedData = await consumer.getPopularModels({ type: 'like', limit: MODELS_PER_PAGE });
        mostLiked.value = likedData.data.docs;

        const viewedData = await consumer.getPopularModels({ type: 'view', limit: MODELS_PER_PAGE });
        mostViewed.value = viewedData.data.docs;

        const newestData = await consumer.getNewestModels({ limit: MODELS_PER_PAGE, page });
        newest.value = newestData.data.docs;

        const trendingData = await consumer.getTrendingModels({ limit: MODELS_PER_PAGE, page });
        trending.value = trendingData.data.docs.filter((model) => model);
      });

      return { mostPopular, mostLiked, mostViewed, newest, trending };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 20px;
  }

  .menu {
    padding: 15px 30px;

    background: #141414;
    list-style-type: none;

    &__item {
      display: inline-block;

      border-right: 1px solid #444;

      &.is-active {
        color: #D5AF34;
      }
    }

    &__item:last-child {
      border: none;
    }

    .link {
      width: 50%;

      font-weight: bold;
      font-size: 11px;
    }
  }

  .model-cards {
    margin: 10px 0 30px 0;
  }

  .see-more {
    margin: 20px auto 40px auto;

    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;

    a {
      color: #D5AF34;
      text-decoration: none;
    }
  }
</style>

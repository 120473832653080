<template>
  <div v-if="isPostTypeChoosing" class="mt-20">
    <h1 class="header text-center text-uppercase" @click="onPostTypeChoose('post')">
      <div class="mb-20">Add Post</div>
      <img src="@/assets/images/icons/picture.svg"/>
    </h1>
    <p class="text-center mb-60 mt-60">or</p>
    <h1 class="header text-center text-uppercase" @click="onPostTypeChoose('bundle')">
      <div class="mb-20">Add Bundle</div>
      <img src="@/assets/images/icons/bundle.svg"/>
    </h1>
  </div>

  <slot v-else>
    <NewBundle v-if="selectedPostType === 'bundle'"/>
    <NewPost v-else/>
  </slot>
</template>

<script>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  import NewPost from './NewPost';
  import NewBundle from './NewBundle';

  export default {
    name: 'CreatorPostPage',
    components: {
      NewPost,
      NewBundle,
    },
    setup() {
      const route = useRoute();
      const postId = route.query.pid;
      const postType = route.query.type;
      const isPostTypeChoosing = ref(postId ? false : true);
      const selectedPostType = ref(postId ? postType : null);

      const onPostTypeChoose = (type) => {
        isPostTypeChoosing.value = false;
        selectedPostType.value = type;
      }

      return {
        selectedPostType,
        isPostTypeChoosing,
        onPostTypeChoose,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 30px 0;
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">
    Search results
  </h1>
  <div class="models-list">
    <ModelCard
        :key="model._id"
        v-for="model in models"
        :data="{ ...model, url: `/${model.username}` }"
    />
    <div class="sub-header text-center" v-if="!models.length">
      No results found
    </div>
  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';

  import { ModelCard } from '@/components';

  export default {
    name: 'CreatorSearchResultsPage',
    components: { ModelCard },
    setup() {
      const route = useRoute();
      const store = useStore();
      const models = ref([]);

      watch(() => store.state.consumer.search, (data) => {
        models.value = data;
      });

      onMounted(async () => {
        const { query } = route;
        let requestQuery = {
          $and: [],
        };

        const attributes = Object.keys(query)
            .filter((key) => key !== 'text')
            .map((key) => {
              return [`attributes.${key}`, query[key]];
            })
            .reduce((attrsList, attrs) => {
              if (attrs[1].includes(',')) {
                return [
                  ...attrsList,
                  {
                    $or: [
                      ...attrs[1].split(',').reduce((attrsArray, key) => {
                        return [
                          ...attrsArray,
                          {[attrs[0]]: key}
                        ];
                      }, [])
                    ]
                  },
                ];
              }
              return [...attrsList, {[attrs[0]]: attrs[1]}];
            }, []);

        if (query.text) {
          requestQuery.$and.push({$or: [
            { username: { $regex: query.text, $options: 'i' }},
            { first_name: { $regex: query.text, $options: 'i' }},
            { last_name: { $regex: query.text, $options: 'i' }},
          ]});
        }

        if (attributes.length) {
          requestQuery.$and = [...requestQuery.$and, ...attributes];
        }

        if (!requestQuery.$and.length) {
          requestQuery = {};
        }

        await store.dispatch('consumer/searchModels', requestQuery);
      });

      return { models };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 20px;
  }
</style>

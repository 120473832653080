<template>
  <div
    :class="['button', {
      [`button_type_${type}`]: type,
      'is-disabled': disabled,
      'is-rounded': rounded,
      [`button_size_${size}`]: size,
    }]"
    @click="onClick"
  >
    <router-link v-if="to" :to="to" class="button__item">
      <span class="button__text">{{text}}</span>
    </router-link>
    <button v-else class="button__item">
      <span class="button__text">{{text}}</span>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Button',
    props: {
      text: {
        type: String,
        required: true,
      },
      type: String,
      size: String,
      to: String,
      disabled: Boolean,
      rounded: Boolean,
    },
    setup(_, { emit }) {
      return {
        onClick: (event) => {
          emit('onClick', event);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    background-color: #D5AF34;

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &.is-rounded {
      border-radius: 15px;
    }

    &__item {
      width: 100%;
      padding: 20px;

      background: none;
      border: 0;
      -webkit-appearance: button;
      text-align: center;
      text-decoration: none;
    }

    &__text {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #FFF;
    }

    &_type {
      &_transparent {
        border: 2px solid #D5AF34;
        background: none;
      }

      &_red {
        background-color: #D53E34;
      }

      &_green {
        background-color: #298429;
      }
    }

    &_size_m {
      display: inline-block;
      width: 70%;
      height: 38px;
    }
    &_size_m &__item {
      padding: 10px;
    }
    &_size_m &__text {
      font-size: 14px;
    }

    &_size_small {
      height: 30px;
    }
    &_size_small &__item {
      padding: 5px 20px;
    }
    &_size_small &__text {
      font-size: 12px;
      vertical-align: top;
    }

    &_size_extra-small {
      height: 25px;
    }
    &_size_extra-small &__item {
      padding: 2px 15px;
    }
    &_size_extra-small &__text {
      font-size: 10px;
      vertical-align: top;
    }

    &_type_transparent &__item {
      -webkit-appearance: none!important;
      display: inline-block;
    }

    &_type_transparent &__text {
      line-height: 17px;
    }
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">Payout Requests</h1>

  <ul class="payouts" v-if="payoutRequests && payoutRequests.length">
    <li
      class="payouts__item"
      v-for="request in payoutRequests"
      :key="request._id"
    >
      <div class="d-flex justify-space-between align-items-center">
        <div>
          <p class="payouts__item-date">
            {{formatDate(request.model.createdAt)}}
          </p>
          <p class="payouts__item-txt mt-10">
            creator: "{{request.model.username}}" requested ${{request.requestAmount}}
          </p>
        </div>
        <Button text="Paid" size="extra-small" type="yellow" rounded @onClick="onPaid(request)"/>
      </div>
    </li>
  </ul>
  <p v-else class="text text-center text-uppercase mt-10">No requests</p>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import moment from 'moment';

  import { admin } from '@/api';
  import { Button } from '@/components';

  export default {
    name: 'AdminPayoutRequestsPage',
    components: { Button },
    setup() {
      const payoutRequests = ref(null);

      const formatDate = (date) => {
        return moment(date).format('MM/DD/YYYY - HH:MM A');
      }

      const onPaid = async (request) => {
        await admin.paidPayoutRequest({
          rid: request._id,
          amount: request.requestAmount,
        });

        payoutRequests.value = payoutRequests.value.filter(({_id}) => _id !== request._id);
      }

      onMounted(async () => {
        const { data } = await admin.getPayoutRequestsList();
        payoutRequests.value = data.docs;
      });

      return {
        payoutRequests,
        formatDate,
        onPaid
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .payouts {
    padding: 20px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    list-style-type: none;

    &__item {
      padding: 10px 12px;

      background: #141414;
    }

    &__item-date {
      font-size: 10px;
      color: #888;
    }

    &__item-txt {
      font-size: 14px;
      color: #FFFFFF;
    }
  }
</style>

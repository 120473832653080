<template>
  <div class="nickname text-center">
    <h2 class="nickname__text">{{ username }}</h2>
    <h3 class="nickname__link">{{ url }}</h3>
  </div>
</template>

<script>
  import { computed } from 'vue';

  export default {
    name: 'Nickname',
    props: {
      username: String,
    },
    setup({ username }) {
      const url = computed(() => `www.${location.host}/${username}`);
      return { url };
    }
  }
</script>

<style lang="scss" scoped>
  .nickname {
    font-family: Roboto;
    letter-spacing: 0.1em;
    color: #FFF;

    &__text {
      max-width: 70%;
      margin: 10px auto 4px auto;

      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__link {
      max-width: 90%;
      margin: 0 auto;
      text-overflow: ellipsis;
      overflow: hidden;

      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
    }
  }
</style>

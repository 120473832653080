<template>
  <div class="header d-flex">
    <div class="header__icon header__icon-logo">
      <router-link to="/app">
        <img src="@/assets/images/logo.svg"/>
      </router-link>
    </div>
    <div class="d-flex header__right-actions" v-if="isReadyToRender">
      <Button
        size="small"
        rounded
        :type="isFollowing ? 'green' : 'yellow'"
        :text="isFollowing ? 'Following' : 'Follow'"
        @onClick="onFollowAction"
      />
      <Button
        class="ml-10"
        size="small"
        rounded
        type="yellow"
        :text="isSubscribed ? 'Unsubscribe' : 'Subscribe'"
        v-if="isFollowing || isSubscribed"
        @onClick="onSubscribeAction"
      />
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { consumer} from '@/api';
  import { Button } from '@/components';

  export default {
    name: 'ConsumerHeader',
    components: { Button },
    setup() {
      const store = useStore();
      const isReadyToRender = ref(false);
      const isFollowing = ref(false);
      const isSubscribed = ref(false);

      const onFollow = async () => {
        const uid = store.state.consumer.viewingModel.model._id;
        await consumer.followModel({ uid });
        await store.dispatch('app/addFollower', uid);
        isFollowing.value = true;
      }

      const onUnFollow = async () => {
        const uid = store.state.consumer.viewingModel.model._id;

        await consumer.unfollowModel({ uid });
        await store.dispatch('app/deleteFollower', uid);

        isFollowing.value = false;
      };

      const onFollowAction = () => {
        if (isFollowing.value) {
          if (isSubscribed.value) {
            store.dispatch('consumer/setUnfollowConfirmProcess', true)
          } else {
            onUnFollow();
          }
        } else {
          if (isSubscribed.value) {
            onFollow();
          } else {
            store.dispatch('consumer/setSubscribingProcess', true);
          }
        }
      };

      const onUnsubscribe = async () => {
        const uid = store.state.consumer.viewingModel.model._id;

        await consumer.unSubscribeModel({ uid })
        await store.dispatch('app/deleteSubscriber', uid);
      }

      const onSubscribeAction = () => {
        console.log('isSubscribed.value', isSubscribed.value);

        if (isSubscribed.value) {
          if (isFollowing.value) {
            store.dispatch('consumer/setUnsubscribeConfirmProcess', true);
          } else {
            onUnsubscribe();
          }
        } else {
          store.dispatch('consumer/setPaymentProcess', true);
        }
      }

      watch(() => store.state.consumer.viewingModel, (viewingModel) => {
        const {state: { app }} = store;

        isFollowing.value = app.auth.user?.followed?.includes(viewingModel.model._id);
        isSubscribed.value = Boolean(app.auth.user?.subscribed?.find(({model}) => model === viewingModel.model._id));

        isReadyToRender.value = true;
      });

      watch(() => store.state.app.auth.user?.followed, (followed) => {
        if (followed) {
          isFollowing.value = followed.includes(store.state.consumer.viewingModel.model._id);
        }
      });

      watch(() => store.state.app.auth.user?.subscribed, (subscribed) => {
        if (subscribed) {
          isSubscribed.value = Boolean(subscribed.find(({model}) => model === store.state.consumer.viewingModel.model._id));
        }
      });

      return {
        isReadyToRender, isFollowing, isSubscribed,
        onFollowAction, onSubscribeAction
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      height: 30px;

      img {
        height: 100%;
      }
    }

    &__icon-logo {
      height: 23px;
    }

    &__right-actions {
      width: 100px;
      justify-content: flex-end;
    }
  }
</style>

import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Maska from 'maska';
import VueTelInput from 'vue3-tel-input';

import 'vue3-tel-input/dist/vue3-tel-input.css';
import './assets/styles/index.css';

import App from './App.vue';
import router from './router';
import store from './store';

/* eslint-disable-next-line */
import axiosConfig from '@/config/axios';

const VueTelInputOptions = {
  mode: 'international',
  onlyCountries: ['GB', 'US', 'CA', 'FR', 'RU', 'CN'],
}

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Maska);
app.use(VueTelInput, VueTelInputOptions);
app.use(VueAxios, axios);

app.mount('#app');

<template>
  <div class="comments">
    <p class="comments__title text-uppercase">
      Comments <span v-if="amountOfComments">({{amountOfComments}})</span>
    </p>

    <slot v-if="comments">
      <div class="comments__list">
        <div v-for="comment in commentsList" class="comment d-flex" :key="comment._id">
          <img
            v-if="comment.author[comment.role].picture"
            class="comment__img mr-10"
            :src="comment.author[comment.role].picture"
          />
          <img v-else class="comment__img mr-10" src="@/assets/images/avatar_default.png"/>

          <div class="comment__content">
            <b class="comment__username">
              {{comment.author[comment.role].username}}
            </b>
            <p class="comment__text text">
              {{comment.comment}}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="comments.nextPage"
        class="comments__load-more text-center"
        @click="onLoadMore"
      >
        <span class="text-uppercase text">Load more...</span>
      </div>
    </slot>
    <div v-else class="comments__list-spinner comments__list text-center">
      <img src="@/assets/images/spinner-trans.svg"/>
    </div>

    <div class="comment__actions">
      <Textarea placeholder="Your comment text" :value="commentText" @onInput="onInputComment"/>
      <Button class="mt-10" color="yellow" text="Send" @onClick="sendNewComment"/>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';

  import { creator } from '@/api';
  import { Textarea, Button } from '@/components/atoms';

  export default {
    name: 'PostComments',
    props: {
      postId: String,
    },
    components: { Textarea, Button },
    setup({ postId }, { emit }) {
      const store = useStore();
      const role = store.getters['app/userRole'];
      const COMMENTS_LIMIT = 6;
      const comments = ref(null);
      const commentsList = ref([]);
      const commentText = ref('');
      const amountOfComments = ref(0);
      const isCommentsLoading = ref(true);
      let currentPage = 1;

      const sendNewComment = async () => {
        if (commentText.value.length > 128) {
          store.dispatch('message/show', {
            text: 'The comment length is limitited to 128 characters',
            type: 'error'
          });

          return
        }

        try {
          const comment = await creator.addComment({
            pid: postId,
            comment: commentText.value,
          });

          const roleData = store.state[role === 'member' ? 'consumer' : 'creator'];

          if (comment) { emit('incrementCommentsCounter') }

          commentsList.value = [{
            comment: commentText.value,
            role,
            _id: comment._id,
            author: {
              [role]: {
                username: roleData.profile[role].username,
                picture: roleData.profile[role].picture
              }
            }
          }, ...commentsList.value];

          amountOfComments.value += 1;
          commentText.value = '';
        } catch(error) {
          if (error.response.data.message.includes('Contains Prohibited Content')) {
            alert('Contains Prohibited Content.');
          }
        }
      }

      const onInputComment = (text) => {
        if (text.length > 128) {
          store.dispatch('message/show', {
            text: 'The comment length is limitited to 128 characters',
            type: 'error'
          });
        }
        commentText.value = text;
      }

      const onLoadMore = async() => {
        currentPage += 1;
        await getComments();
      }

      const getComments = async () => {
        const { data } = await creator.getComments({
          pid: postId,
          limit: COMMENTS_LIMIT,
          page: currentPage,
        });

        comments.value = data;
        commentsList.value = [
          ...commentsList.value,
          ...data.docs.filter(({author}) => author),
        ];
        amountOfComments.value = data.totalDocs;
      }

      onMounted(async () => await getComments());

      return {
        comments, commentsList, isCommentsLoading, amountOfComments, commentText,
        sendNewComment, onInputComment, onLoadMore,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .comments {
    margin-top: 25px;
    position: relative;
    padding: 20px;

    background: #2d2c2c;

    &:before {
      content: '';

      position: absolute;
      top: -10px;
      left: 53px;

      width: 20px;
      height: 20px;

      background: #2d2c2c;
      transform: rotate(45deg);
    }

    &__title {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1px;
    }

    &__list {
      margin: 15px 0 0 0;
    }

    &__list-spinner {
      margin-bottom: 20px;

      img {
        width: 50px;
      }
    }

    &__load-more {
      margin: 30px 0;
    }
  }

  .comment {
    padding-bottom: 10px;
    margin-bottom: 10px;

    border-bottom: 1px solid #3c3b3b;

    &__img {
      width: 30px;
      height: 30px;

      object-fit: cover;
      border-radius: 15px;
    }
    &__username {
      display: block;
      margin-bottom: 5px;
      width: 240px;

      letter-spacing: 1px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .textarea {
    height: 100px;
  }
</style>

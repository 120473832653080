<template>
  <h1 class="header text-center text-uppercase">Security</h1>
  <h2 class="sub-header text-center text-uppercase">Password</h2>

  <form class="form" @submit="onFormSubmit">
    <div class="form__group">
      <Input
        type="password"
        placeholder="Current password"
        :value="form.oldPassword"
        @onInput="onFieldUpdate('oldPassword', $event)"
      />
      <div class="form__item-error">{{errors.oldPasswordError}}</div>
    </div>
    <div class="form__group">
      <Input
        type="password"
        placeholder="New password"
        :value="form.newPassword"
        @onInput="onFieldUpdate('newPassword', $event)"
      />
      <div class="form__item-error">{{errors.newPasswordError}}</div>
    </div>
    <div class="form__group">
      <Input
        type="password"
        placeholder="Repeat password"
        :value="form.newPasswordRepeat"
        @onInput="onFieldUpdate('newPasswordRepeat', $event)"
      />
      <div class="form__item-error">{{errors.newPasswordRepeatError}}</div>
    </div>

    <div class="form__group">
      <Button text="Save Password"/>
    </div>

    <!-- <div class="form__group" v-if="!isModel">
      <Switch
        :disabled="hasModelRequest"
        :label="`SWITCH TO ${accountType} ACCOUNT`"
        :checked="hasModelRequest"
        @onChange="onRoleChange"
      />
      <div class="text reject-reason" v-if="rejectReason">
        Your previous request was rejected. Reason: {{rejectReason}}
      </div>
      <p v-if="hasModelRequest" class="text">
        You have already sent a new request. After verification, your status will be changed.
      </p>
    </div> -->
  </form>

  <!-- <Popup
    v-if="isVouchedVisible"
    :no-padding="true"
    @close="onVouchedClosed"
  >
    <div id='vouched'/>
  </Popup> -->
</template>

<script>
  import { ref, reactive, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';

  // import { Input, Button, Switch, Popup } from '@/components';
  import { Input, Button } from '@/components';
  import { errorsToMessage } from '@/utils';
  import config from '@/config';

  export default {
    name: 'SecurityPage',
    components: {
      // Popup,
      Input,
      Button,
      // Switch,
    },
    setup() {
      const store = useStore();
      const form = reactive({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
      });
      const errors = ref({
        oldPasswordError: null,
        newPasswordError: null,
        newPasswordRepeatError: null
      });
      const getAccountName = () => store.getters['app/userRole'] === 'member' ? 'CREATOR' : 'CONSUMER';
      const accountType = ref(getAccountName());
      const isModel = (store.getters['app/userRole'] === 'model');
      const hasModelRequest = ref(false);
      const rejectReason = ref(null);
      const isVouchedVisible = ref(false);

      const onFieldUpdate = (fieldName, value) => {
        form[fieldName] = value;
      };

      const onFormSubmit = (event) => {
        event.preventDefault();

        clearValidationErrors();

        validateForm();

        if (!isFormValid()) return false;

        const data = {
          uid: store.getters['app/userID'],
          params: {
            old_password: form.oldPassword,
            password: form.newPassword,
          }
        };

        store.dispatch('app/updateUser', data)
          .then(() => {
            clearFormData();
            store.dispatch('message/show', {
              text: 'You successfully updated your password',
              type: 'info'
            });
          })
          .catch((error) => {
            store.dispatch('message/show', {
              text: errorsToMessage(error),
              type: 'error'
            });
          });
      }

      const clearValidationErrors = () => {
        Object.keys(errors.value).forEach((fieldName) => {
          errors.value[fieldName] = null;
        });
      };

      const validateForm = () => {
        if (!form.oldPassword) {
          errors.value.oldPasswordError = 'Current password can\'t be empty';
        }
        if (form.newPassword.length < config.passwordMinLength) {
          errors.value.newPasswordError = `New password length must be at least ${config.passwordMinLength} characters long`;
        }
        if (!form.newPassword) {
          errors.value.newPasswordError = 'New Password can\'t be empty';
        }
        if (form.newPassword !== form.newPasswordRepeat) {
          errors.value.newPasswordRepeatError = 'Passwords should be equal';
        }
        if (!form.newPasswordRepeat) {
          errors.value.newPasswordRepeatError = 'New Password can\'t be empty';
        }
      };

      const isFormValid = () => {
        let isNoErrors = true;
        Object.keys(errors.value).forEach((fieldName) => {
          if (isNoErrors) {
            isNoErrors = !errors.value[fieldName];
          }
        });
        return isNoErrors;
      };

      const clearFormData = () => {
        Object.keys(form).forEach((fieldName) => {
          form[fieldName] = '';
        });
      };

      const onVouchedClosed = () => {
        isVouchedVisible.value = false;
      }

      const onRoleChange = async () => {
        isVouchedVisible.value = true;

        setTimeout(() => {
          /* TODO: move to ENV */
          const envParams = {
            ...config.VOUCHED[
                window.location.host.includes('rhinofans.com') ? 'PRODUCTION' : 'SANDBOX'
            ],
          };

          const vouchedParams = {
            ...envParams,
            callbackURL: `${envParams.callbackURL}/${store.getters['app/userID']}`,
            verification: {
              firstName: 'Gladys',
              lastName: 'West',
              phone: '000-111-2222'
            },
            crossDevice: true,
            crossDeviceQRCode: true,
            crossDeviceSMS: true,
            onDone: (job) => {
              console.log('Scanning complete', { token: job.token });

              isVouchedVisible.value = false;

              alert('Verification done');

              // An alternative way to update your system based on the
              // results of the job. Your backend could perform the following:
              // 1. query jobs with the token
              // 2. store relevant job information such as the id and
              //    success property into the user's profile
              // fetch(`/yourapi/idv?job_token=${job.token}`);
            },

            theme: {
              name: 'avant',
              bgColor: 'rgba(0, 0, 0, 0.85)',
              logo: '/img/logo.svg',
              baseColor: '#D5AF34',
              fontColor: '#FFF',
            },
          };

          const vouched = window.Vouched(vouchedParams);
          vouched.mount('#vouched');
        });

        // await store.dispatch(
        //   'consumer/updateProfileInfo',
        //   {
        //     member: {
        //       model_request: true
        //     },
        //   },
        // ).then(() => {
        //   store.dispatch('message/show', {
        //     text: 'Your request has been sent',
        //     type: 'info'
        //   });
        //   hasModelRequest.value = true;
        // });

        // const newRole = store.getters['app/userRole'] === 'member' ? 'model' : 'member';
        //
        // await store.dispatch('app/updateUser', {
        //   uid: store.getters['app/userID'],
        //   params: {
        //     role: newRole,
        //     currentRole: newRole,
        //   }
        // });
        // accountType.value = getAccountName();
        // await router.push('/app');
      }

      watch(() => store.state.consumer.profile, (profile) => {
        hasModelRequest.value = profile.member.model_request;
        rejectReason.value = profile.member.model_request_reject_reason;
      });

      onMounted(() => {
        if (store.state.consumer.profile) {
          hasModelRequest.value = store.state.consumer.profile.member.model_request;
          rejectReason.value = store.state.consumer.profile.member.model_request_reject_reason;
        }
      });

      return {
        errors,
        form,
        accountType,
        isModel,
        hasModelRequest,
        rejectReason,
        isVouchedVisible,
        onFieldUpdate,
        onFormSubmit,
        onRoleChange,
        onVouchedClosed,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 70px 0 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 20px;
  }

  .switch {
    margin: 15px 0;
  }

  .reject-reason {
    color: #e17e7e;
  }

  #vouched {
    height: 80vh;
  }
</style>

<template>
  <div v-if="!isLoading && !hasErrors">
    <div class="stream">
      <div class="stream__header text" v-if="streamInfo">
        <div class="d-flex align-items-center justify-space-between">
          <span class="stream__name">{{streamInfo.snapshot.name}}</span>
          <div class="stream__tips d-flex justify-content-end">
            <div class="ml-20 d-flex align-items-center" @click="onTipAction">
              <img src="@/assets/images/icons/tip.svg"/>
            </div>
            <Button
              v-if="streamInfo.goal"
              class="ml-20"
              text="Tip for the Goal"
              size="small"
              rounded
              @onClick="onGoalTip"
            />
          </div>
        </div>
      </div>

      <iframe
        width="100%"
        height="300"
        :src="`https://stream.stage.rhinofans.com:5443/LiveApp/play.html?name=${streamId}&token=${streamToken}&mute=false`"
        frameborder="0"
        allowfullscreen
        class="frame"
      ></iframe>
    </div>

    <div v-if="streamInfo && streamInfo.goal" class="goal text text-center mt-10">
      <b>Goal</b>: <span>${{streamInfo.goal}}</span>.
      <b>Collected</b>: <span>${{streamInfo.amount}}</span>
    </div>

    <h4 class="sub-header text-center m-20">LIVE STREAM CHAT ROOM</h4>

    <!-- TODO: Move chat to sub component --->
    <div class="live-chat">
      <div class="live-chat__empty text-center text" v-if="!messages.length">No messages</div>
      <div class="live-chat__list" v-else>
        <div
          class="live-chat__message"
          v-for="(message, index) in messages"
          :key="index"
        >
          <div class="live-chat__message-username">{{message.username}}</div>
          <div class="live-chat__message-txt">{{message.text}}</div>
        </div>
      </div>
    </div>

    <div class="messenger d-flex">
      <Input
        class="messenger__input"
        :value="streamMessage"
        placeholder="Input your message"
        @onInput="onMessageInput"
      />
      <div
        class="messenger__btn text-center d-flex justify-center align-items-center"
        @click="onMessageSend"
      >
        <img src="@/assets/images/icons/send-msg.svg"/>
      </div>
    </div>
  </div>
  <div v-if="hasErrors">
    <div class="live-chat mt-20">
      <div class="live-chat__empty text-center text">
        {{tokenError}}
      </div>

      <div v-if="streamInfo && streamInfo.isPrivate" class="text-center mt-20">
        <Button :text="`Join for $${streamInfo.price}`" size="m" @onClick="onToggleStreamVisibility"/>
      </div>
    </div>
  </div>

  <!-- Tips menu -->
  <Popup v-if="isTipPaymentVisible" @close="onPaymentClose">
    <slot v-if="isTipPriceMenuVisible">
      <h2 class="sub-header text-center text-uppercase">TIP MIA_KHALIFA</h2>
      <div class="price-values d-flex justify-space-between mt-40">
        <span
          :class="['price-values-val', {
            active: tip === tipValue
          }]"
          v-for="tip in tipValues"
          :key="tip"
          @click="onTipPriceChoose(tip)"
        >
          ${{tip}}
        </span>
      </div>
      <div class="price-action mt-40">
        <Button text="Submit" @onClick="onTipSubmit"/>
      </div>
    </slot>
    <slot v-else>
      <USAepayForm
        title="Tip"
        canSetPrice
        :price="tipValue"
        :hasSavedCard="hasSavedCard"
        @paid="onPayForMessage"
      />
    </slot>
  </Popup>

  <!-- Send a tip -->
  <Popup v-if="isStreamPaymentVisible" @close="onStreamPaymentClose">
    <USAepayForm
        :title="`Join for $${streamInfo.price}`"
        :price="streamInfo.price"
        :hasSavedCard="hasSavedCard"
        @paid="onPayForStream"
    />
  </Popup>

  <!-- Send a tip for a goal -->
  <Popup v-if="isGoalPaymentVisible" @close="onGoalPaymentClose">
    <USAepayForm
        title="Tip for the goal"
        canSetPrice
        :hasSavedCard="hasSavedCard"
        @paid="onPayStreamGoal"
    />
  </Popup>
</template>

<script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import mqtt from 'mqtt';

  import { common, consumer } from '@/api';
  import { Popup, Button, Input, USAepayForm } from '@/components';
  import config from '@/config';

  export default {
    name: 'LiveStreamPage',
    components: {
      Input,
      Popup,
      Button,
      USAepayForm
    },
    setup() {
      const MAX_CHAT_SIZE = 100; /* Only last 100 messages are visible */
      const store = useStore();
      const route = useRoute();
      const streamId = route.query.id;
      const streamInfo = ref();
      const streamToken = ref('');
      const isLoading = ref(true);
      const hasErrors = ref(false);
      const isTipPaymentVisible = ref(false);
      const tipValues = [5, 10, 20, 50];
      const tipValue = ref(tipValues[0]);
      const isTipPriceMenuVisible = ref(true);
      const hasSavedCard = ref(false);
      const tokenError = ref('You don\'t have permissions. Please subscribe');
      const isStreamPaymentVisible = ref(false);
      const isGoalPaymentVisible = ref(false);
      let mqttClient = null;
      let options = null;
      let streamMessage = ref(null);
      const messages = ref([]);

      const onTipAction = () => {
        isTipPaymentVisible.value = true;
      }

      const onPaymentClose = () => {
        isTipPaymentVisible.value = false;
        isTipPriceMenuVisible.value = true;
      }

      const onPayForMessage = async ({token, price}) => {
        await consumer.payForTip({
          token,
          tipAmount: price,
          modelId: streamInfo.value.model._id,
        });
        onPaymentClose();
      }

      const onTipPriceChoose = (price) => {
        tipValue.value = price;
      }

      const onTipSubmit = () => {
        isTipPriceMenuVisible.value = false;
      }

      const onToggleStreamVisibility = () => {
        isStreamPaymentVisible.value = true;
      }

      const onStreamPaymentClose = () => {
        isStreamPaymentVisible.value = false;
      }

      const onPayForStream = async ({token, price}) => {
        await consumer.buyStreamToken({
          token,
          sid: streamId,
          tipAmount: price,
        })
        .catch((error) => {
          if (error.response.data.error) {
            alert(error.response.data.error);
          }
        });
        onStreamPaymentClose();
        window.location = window.location.href;
      }

      const onGoalTip = () => {
        isGoalPaymentVisible.value = true;
      }

      const onGoalPaymentClose = () => {
        isGoalPaymentVisible.value = false;
      }

      const onPayStreamGoal = async ({token, price}) => {
        await consumer.payStreamGoal({
          token,
          streamId: streamInfo.value._id,
          tipAmount: price,
        })
        .catch((error) => {
          if (error.response.data.error) {
            alert(error.response.data.error);
          }
        });
        onGoalPaymentClose();
      }

      const listenForMqtt = async () => {
        options = await common.getPublicOptions();

        const clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;

        mqttClient = mqtt.connect(config.MQTT_BROKER, {...config.MQTT_BROKER_CONFIG, clientId});

        mqttClient.on('error', (error) => {
          console.log('Connection error: ', error);
          mqttClient.end();
        });

        mqttClient.on('connect', () => {
          const streamId = streamInfo.value.streamId;
          const modelId = streamInfo.value.model._id;

          const goalsTopic = `${options.data.mqttEnv}/stream/${streamId}/tips/${modelId}`;
          mqttClient.subscribe(goalsTopic, {qos: 0});

          const streamChatTopic = `${options.data.mqttEnv}/stream/chat/${streamId}`;
          mqttClient.subscribe(streamChatTopic, {qos: 0});
        });

        mqttClient.on('message', async (topic, message) => {
          try {

            /* Listen to messages topic */
            if (topic.includes('/stream/chat')) {
              if (messages.value.length < MAX_CHAT_SIZE) {
                messages.value = [
                  JSON.parse(message.toString()),
                  ...messages.value,
                ]
              } else {
                messages.value = [
                  JSON.parse(message.toString()),
                  ...messages.value.slice(0, messages.value.length - 1),
                ]
              }
            }

            /* Listen to goals topic */
            else {
              streamInfo.value.amount = JSON.parse(message.toString()).attachments[0].amount;
            }
          } catch (e) {
            console.log(`Can't parse <${topic}> message`);
          }
        });
      }

      onMounted(async () => {
        const { data } = await consumer.getStream({ sid: streamId });

        if (data.error) {
          hasErrors.value = true;
          tokenError.value = data.error;
        } else {
          const tokenData = await consumer.getStreamToken({streamId});

          hasErrors.value = false;

          if (tokenData.data.error) {
            hasErrors.value = true;
            tokenError.value = tokenData.data.error;
          } else {
            streamToken.value = tokenData.data.token.tokenId;
          }

          streamInfo.value = data;
          isLoading.value = false;

          if (store.state.consumer.profile) {
            hasSavedCard.value = Boolean(store.state.consumer.profile.member.lastTransaction);
          }

          listenForMqtt();
        }
      });

      const onMessageInput = (txt) => {
        streamMessage.value = txt;
      }

      const onMessageSend = () => {
        mqttClient.publish(
            `${options.data.mqttEnv}/stream/chat/${streamInfo.value.streamId}`,
            JSON.stringify({
              text: streamMessage.value,
              username: store.state.consumer.profile.member.username,
            }),
        );
        streamMessage.value = null;
      }

      onBeforeUnmount(() => {
        if (streamInfo.value) {
          const streamId = streamInfo.value.streamId;
          const modelId = streamInfo.value.model._id;

          mqttClient.unsubscribe(`${options.data.mqttEnv}/stream/${streamId}/tips/${modelId}`);
          mqttClient.unsubscribe(`${options.data.mqttEnv}/stream/chat/${streamId}`);
        }
      });

      return {
        tipValues,
        tipValue,
        isLoading, hasErrors,
        streamId, streamToken, streamInfo,
        isTipPaymentVisible,
        isTipPriceMenuVisible,
        hasSavedCard,
        tokenError,
        isStreamPaymentVisible,
        isGoalPaymentVisible,
        messages,
        streamMessage,
        onTipAction,
        onPaymentClose,
        onPayForMessage,
        onTipPriceChoose,
        onTipSubmit,
        onToggleStreamVisibility,
        onPayForStream,
        onStreamPaymentClose,
        onGoalTip,
        onGoalPaymentClose,
        onPayStreamGoal,
        onMessageSend,
        onMessageInput,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .stream {
    &__header {
      padding: 12px 15px;
    }

    &__name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__tips {
      min-width: 230px;
    }
  }
  .frame {
    width: 100%;
  }

  .price-values {
    &-val {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 42px;
      color: #888888;

      &.active {
        color: #E2F507;
      }
    }
  }

  .goal {
    font-size: 14px;

    b {
      color: #D5AF34;
      font-weight: bold;
    }

    span {
      color: rgb(160, 217, 160);
    }
  }

  .live-chat {
    height: calc(100vh - 615px);
    overflow-y: auto;

    &__list {
      padding: 0 20px 20px;
    }

    &__message {
      margin-bottom: 20px;

      font-family: Roboto;
      font-style: italic;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.1em;
    }

    &__message-username {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .messenger {
    position: fixed;
    bottom: 60px;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 15px;

    &__input {
      width: 100%;
    }

    &__btn {
      width: 60px;
      height: 38px;
      padding: 5px;

      img {
        width: 25px;
      }
    }
  }
</style>

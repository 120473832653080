<template>
  <div class="header d-flex">
    <div class="header__icon header__icon-logo">
      <router-link to="/app">
        <img src="@/assets/images/logo.svg"/>
      </router-link>
    </div>
    <div class="header__right-actions">
      <ul class="menu d-flex justify-space-between text-uppercase">
        <li class="menu__item" v-for="link in links" :key="link.title">
          <router-link :to="`/app${link.url}`" :class="['link', {
            'is-active': isLinkSelected(link),
            'is-disabled': !link.key
          }]">
            {{ link.title }}
          </router-link>
        </li>
      </ul>
      <!--div class="header__icon">
        <img src="@/assets/images/icons/random-cube.svg"/>
      </div>
      <div class="header__icon">
        <img src="@/assets/images/icons/settings.svg"/>
      </div-->
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'ConsumerHeader',
  setup() {
    const route = useRoute();
    const links = [
      { url: '/app/', title: 'Feed', key: 'feed' },
      { url: '/models/featured', title: 'Featured', key: 'featured' },
      { url: '/live', title: 'Live', key: 'live' },
      { url: '/models/popular', title: 'Popular', key: 'popular' },
    ];
    const selectedLink = ref(route.params.filter || route.meta.key);

    const isLinkSelected = (link) => {
      if (!selectedLink.value && link.key === 'feed') {
        return true;
      }
      return selectedLink.value && (link.key === selectedLink.value);
    }

    watch(() => route.meta.key, key => selectedLink.value = key || 'feed');

    return {
      links, selectedLink,
      isLinkSelected,
    };
  }
}
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      height: 30px;

      img {
        height: 100%;
      }
    }

    &__icon-logo {
      height: 23px;
    }

    &__right-actions {
      width: 100%;
      margin-left: 30px;
    }

    @media (max-width: 374px) {
      &__right-actions {
        margin-left: 20px;
      }
    }

    @media (max-width: 374px) {
      padding: 10px 15px 10px 20px;
    }
  }

  .menu {
    padding: 0;

    list-style-type: none;

    &__item {
      display: inline-block;

      a {
        font-size: 12px;
      }
    }

    .link {
      font-weight: bold;

      &.is-active {
        color: #D5AF34;
      }

      &.is-disabled {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
</style>

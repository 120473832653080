<template>
  <h1 class="header text-center text-uppercase">My subscribers</h1>

  <div class="search-input">
    <Input
        class="search-input__item"
        placeholder="INPUT USERNAME OR FIRST NAME"
        :value="searchValue"
        @onInput="onInput"
    />
    <img src="@/assets/images/icons/search-grey.svg" class="search-input__icon"/>
  </div>

  <ul class="list">
    <li
      class="list__item"
      v-for="item in subscribersList"
      :key="item.member._id"
      @click="onUserDisableShow(item)"
    >
      <div class="d-flex align-items-center">
        <span class="list__item-avatar">
          <img v-if="item.member.picture" :src="item.member.picture"/>
          <img v-else src="@/assets/images/avatar_default.png"/>
        </span>
        <span class="list__item-username">
          {{item.member.username}}
          <p class="list__item-disabled text text-uppercase">
            {{item.isBlocked && '(disabled)'}}
          </p>
        </span>
      </div>
    </li>
  </ul>

  <div class="load-more mt-40 mb-60" v-if="currentPage">
    <Button
        text="Load more"
        rounded
        size="extra-small"
        type="transparent"
        class="load-more__btn"
        @onClick="onLoadMoreSubscribers"
    />
  </div>

  <Popup v-if="isPopupVisible" class="text-center" @close="onPopupClose">
    <h2 class="sub-header text-center text-uppercase">
      {{selectedUser.isBlocked ? 'Enable' : 'Disable'}} user?
    </h2>

    <div class="mt-40">
      <Button
          v-if="selectedUser.isBlocked"
          text="YES"
          type="red"
          @onClick="onEnableUser"
      />
      <Button
          v-else
          text="YES"
          type="red"
          @onClick="onDisableUser"
      />

      <Button
          class="mt-20"
          text="Cancel"
          type="transparent"
          @onClick="onPopupClose"
      />
    </div>
  </Popup>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { creator } from '@/api';
  import {
    Input,
    Button,
    Popup,
  } from '@/components';

  export default {
    name: 'FansManagementPage',
    components: {
      Input,
      Button,
      Popup,
    },
    setup() {
      const PAGE_SIZE = 25;
      const store = useStore();
      const subscribersList = ref([]);
      const currentPage = ref(1);
      const searchValue = ref('');
      const isPopupVisible = ref(false);
      const selectedUser = ref(null);

      const getSubscribers = async (body = {}) => {
        const { data } = await creator.getModelSubscribers({
          limit: PAGE_SIZE,
          page: currentPage.value
        }, body);

        currentPage.value = data.nextPage;
        subscribersList.value = [...subscribersList.value, ...data.docs];

        setIsBlocked();
      }

      const setIsBlocked = () => {
        subscribersList.value = subscribersList.value.map((user) => {
          if (store.state.creator.profile.model.blackList.includes(user.member.user)) {
            return {
              ...user,
              isBlocked: true
            }
          }
          return { ...user };
        });
      }

      const onInput = async (value) => {
        let requestQuery = {
          $and: [],
        };

        searchValue.value = value;

        if (value.length > 1) {
          requestQuery.$and.push({$or: [
              { username: { $regex: value, $options: 'i' }},
              { first_name: { $regex: value, $options: 'i' }},
              { last_name: { $regex: value, $options: 'i' }},
            ]});

          subscribersList.value = [];
          await getSubscribers(requestQuery);
        } else {
          subscribersList.value = [];
          await getSubscribers();
        }
      }

      const onLoadMoreSubscribers = () => {
        getSubscribers();
      }

      const onPopupClose = () => {
        selectedUser.value = null;
        isPopupVisible.value = false;
      }

      const onUserDisableShow = (user) => {
        selectedUser.value = user;
        isPopupVisible.value = true;
      }

      const onDisableUser = async () => {
        const blackList = store.state.creator.profile.model.blackList;

        await store.dispatch(
            'creator/updateProfileInfo',
            {
              model: {
                blackList: [...blackList, selectedUser.value.member.user],
              },
            },
        );
        setIsBlocked();
        onPopupClose();
      }

      const onEnableUser = async () => {
        const blackList = store.state.creator.profile.model.blackList;

        await store.dispatch(
            'creator/updateProfileInfo',
            {
              model: {
                blackList: blackList.filter(item => item !== selectedUser.value.member.user),
              },
            },
        );
        onPopupClose();
      }

      onMounted(async () => {
        await getSubscribers();
      });

      return {
        currentPage,
        subscribersList,
        searchValue,
        isPopupVisible,
        selectedUser,
        onInput,
        onLoadMoreSubscribers,
        onUserDisableShow,
        onPopupClose,
        onDisableUser,
        onEnableUser,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 20px 0;
  }

  .list {
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 10px;
    padding: 0 10px;

    list-style-type: none;

    &__item {
      padding: 10px;
      margin: 0 5px;

      background: #000;

      &-avatar {
        width: 38px;
        height: 38px;

        border-radius: 19px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-username {
        margin-left: 10px;
        max-width: 60%;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
        letter-spacing: 0.1px;
      }

      &-disabled {
        position: absolute;
        font-size: 10px;
        color: #fb7e7e;
      }
    }
  }

  .load-more {
    &__btn {
      width: 50%;
      margin: 0 auto;
    }
  }

  .search-input {
    position: relative;
    margin: 0 20px 20px 20px;

    &__item {
      padding-right: 50px;
    }

    &__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
    }
  }
</style>

<template>
  <div class="header d-flex">
    <div class="header__icon header__icon-logo">
      <router-link to="/app">
        <img src="@/assets/images/logo.svg"/>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AdminHeader',
  }
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      height: 25px;

      img {
        height: 100%;
      }
    }

    &__icon-logo {
      height: 23px;
    }
  }
</style>

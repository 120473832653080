<template>
  <h1 class="header text-center text-uppercase">Featured</h1>

  <div class="search-input">
    <Input
      class="search-input__item"
      placeholder="INPUT USERNAME OR FIRST NAME"
      :value="searchValue"
      @onInput="onInput"
    />
    <img src="@/assets/images/icons/search-grey.svg" class="search-input__icon"/>
  </div>

  <p v-if="!featured.length" class="text text-center mt-20">No featured models</p>

  <div v-else class="models">
    <ModelCard
      v-for="model in featured"
      :data="{ ...model, url: `/${model.username}` }"
      :key="model._id"
      isFeatured
      @action="onFeatured($event, model)"
    />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';

  import { Input, ModelCard } from '@/components';
  import { admin, consumer } from '@/api';

  export default {
    name: 'AdminFeaturedPage',
    components: { Input, ModelCard },
    setup() {
      const store = useStore();
      const featured = ref([]);
      const searchValue = ref('');

      const onInput = async (value) => {
        let requestQuery = {
          $and: [],
        };

        searchValue.value = value;

        if (value) {
          requestQuery.$and.push({$or: [
            { username: { $regex: value, $options: 'i' }},
            { first_name: { $regex: value, $options: 'i' }},
            { last_name: { $regex: value, $options: 'i' }},
          ]});

          featured.value = await store.dispatch('consumer/searchModels', requestQuery);
        } else {
          await getFeaturedModels();
        }
      }

      const onFeatured = async (value, data) => {
        await admin.updateUser({
          uid: data.user._id,
          params: {
            model: { featured: !value }
          }
        });

        if (searchValue.value) {
          featured.value = featured.value.map((model) => {
            if (model._id === data._id) {
              return { ...model, featured: !value };
            }
            return model;
          });
        } else {
          if (value) {
            featured.value = featured.value.filter(({_id}) => _id !== data._id);
          }
        }
      }

      /* TODO: add pagination after MVP */
      const getFeaturedModels = async () => {
        const models = await consumer.getFeaturedModels({ limit: 1000 });
        featured.value = models.data.docs;
      }

      onMounted( () => {
        getFeaturedModels();
      });

      return {
        featured, searchValue,
        onInput, onFeatured
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .models {
    margin: 0 10px;
  }

  .search-input {
    position: relative;
    margin: 0 20px 10px 20px;

    &__item {
      padding-right: 50px;
    }

    &__icon {
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }
</style>

<template>
  <div class="cover">
    <div class="thumb">
      <img class="thumb__spinner" src="@/assets/images/spinner.svg" v-if="isLoading"/>

      <img v-if="coverSrc" :src="coverSrc" class="thumb__img" @error="onImageLoadError"/>
      <div v-else class="thumb__no-poster text-center">
        <span v-if="isOwner">Upload your poster here</span>
        <img class="thumb__no-poster-img" v-else src="@/assets/images/icons/picture.svg"/>
      </div>

      <span class="icon" v-if="isEditable">
        <img src="@/assets/images/icons/photo.svg"/>
      </span>
      <input
        type="file"
        accept="image/*"
        class="thumb__file-btn"
        v-if="isEditable"
        @change="onCoverUpload"
      />
    </div>
    <Avatar class="avatar" :isEditable="isEditable" :image="avatarSrc" :role="role" :isModelRequest="isModelRequest"/>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { v4 as uuidv4 } from 'uuid';
  import { common } from '@/api';
  import { Avatar } from '@/components';
  import config from '@/config';

  export default {
    name: 'Cover',
    components: { Avatar },
    props: {
      avatar: {
        type: String,
        default: '',
      },
      role: {
        type: String,
        default: 'creator',
      },
      cover: {
        type: String,
        default: '',
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      isOwner: {
        type: Boolean,
        default: true,
      },
      isModelRequest: {
        type: Boolean,
        default: false,
      }
    },
    setup({ cover, avatar, isModelRequest }) {
      const store = useStore();
      const coverSrc = ref(cover);
      const isLoading = ref(false);

      const onCoverUpload = (event) => {
        const image = event.target.files[0];
        const data = {
          mediaType: 'cover',
          contentType: image.type,
          fileName: image.name
        };

        let signedURLData = null;

        coverSrc.value = URL.createObjectURL(image);
        isLoading.value = true;

        common.getS3UploadURL(data)
          .then(({ data }) => {
            signedURLData = data;

            return fetch(data.url, {
              method: 'PUT',
              mode: 'cors',
              body: image,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': image.type,
                'x-amz-acl': 'public-read'
              }
            })
          })
          .then(async () => {
            await common.reportForS3SuccessUpload({
              mediaType: 'cover',
              contentType: image.type,
              key: signedURLData.key
            });

            isLoading.value = false;

            await store.dispatch(
              `${isModelRequest ? 'consumer' : 'creator'}/updateProfileCover`,
              `${config.originBucket}/${signedURLData.key}?v=${uuidv4()}`
            );
          })
          .catch((e) => {
            if (e?.response?.data) {
              alert(e?.response?.data?.message);
            }
          });
      };

      const onImageLoadError = () => {
        coverSrc.value = '';
      }

      return {
        coverSrc, isLoading,
        avatarSrc: avatar,
        onCoverUpload, onImageLoadError
      };
    }
  }
</script>

<style lang="scss" scoped>
  .cover {
    position: relative;
  }

  .thumb {
    height: 170px;

    overflow: hidden;
    background: #dbdbdb;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon {
      position: absolute;
      bottom: -12px;
      right: 15px;

      height: 10px;
    }

    &__file-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &__no-poster {
      margin-top: 30px;

      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 14px;
      color: #000;
    }

    &__spinner {
      position: absolute;
      left: 50%;
      top: 32px;

      width: 80px;
      margin-left: -40px;

      border-radius: 40px;
    }

    &__no-poster-img {
      margin-top: 20px;
      width: 60px;
    }
  }

  .avatar {
    position: absolute;
    left: 50%;

    margin-top: -45px;
    margin-left: -45px;
  }
</style>

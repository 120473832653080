<template>
  <h1 class="header text-center text-uppercase">Price Settings</h1>

  <form class="form">
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">SUBSCRIPTION / MONTHLY</span>
      <Input
        class="form-group__input" prepend="$"
        :value="form.subscriptions_monthly"
        @onInput="onInput($event, 'subscriptions_monthly')"
      />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">PRIVATE MESSAGE MEDIA</span>
      <Input
          class="form-group__input" prepend="$"
          :value="form.private_message"
          @onInput="onInput($event, 'private_message')"
      />
    </div>
    <!--div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">PRIVATE STREAMING</span>
      <Input class="form-group__input" prepend="$" value="0" />
    </div>

    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">STORIES</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">PHOTOS</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">VIDEOS</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">AUDIO</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">BUNDLES</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="d-flex form-group justify-space-between align-items-center">
      <span class="d-flex form-group__title">RECORDED STREAMS</span>
      <Input class="form-group__input" prepend="$" value="10" />
    </div>
    <div class="form-group">
      <Switch label="ALLOW DOWNLOAD"/>
    </div-->
    <div class="form-group form-group_type_actions">
      <Button text="Save changes" @onClick="onSettingsSave"/>
    </div>
  </form>
</template>

<script>
  import { reactive } from 'vue';
  import { useStore } from 'vuex';
  import { Input, Button } from '@/components';

  export default {
    name: 'CreatorPriceSettings',
    components: { Input, Button },
    setup() {
      const store = useStore();
      const profile = store.getters['creator/profile'];
      const form = reactive({
        ...profile.model.price_settings
      });

      const onInput = (value, type) => {
        form[type] = value;

        if (!value) {
          form[type] = 0;
        }
      };

      const onSettingsSave = async (e) => {
        e.preventDefault();

        if (form.private_message <= 0 || form.subscriptions_monthly <= 0) {
          store.dispatch('message/show', {
            text: 'The price should be higher than zero',
            type: 'error'
          });

          return
        }

        await store.dispatch(
            'creator/updateProfileInfo',
            {
              model: {
                price_settings: {
                  ...profile.model.price_settings,
                  ...form,
                },
              },
            },
        ).then(() => {
          store.dispatch('message/show', {
            text: 'You successfully updated your profile',
            type: 'info'
          });
        });
      };

      return { profile, form, onInput, onSettingsSave };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .form {
    padding: 0 35px 35px 35px;
  }

  @media (max-width: 375px) {
    .form {
      padding: 20px;
    }
  }

  .form-group {
    margin-bottom: 20px;

    &__title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.1em;
    }

    &__input {
      width: 90px;
    }

    @media (max-width: 374px) {
      &__title {
        font-size: 11px;
      }

      &__input {
        width: 80px;
      }
    }

    &_type_actions {
      margin-top: 40px;
    }
  }
</style>

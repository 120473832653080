<template>
  <h2 class="header text-center text-uppercase mt-20">Inbox</h2>

  <ul class="chat-list" v-if="chatList.length">
    <li
      class="d-flex chat-list__item"
      v-for="msg in chatList"
      :key="msg.id"
      @click="onChatOpen(msg.companionId.username)"
    >
      <div class="chat-list__item-avatar">
        <img v-if="msg.companionId.picture" :src="msg.companionId.picture"/>
        <img v-else src="@/assets/images/avatar_default.png"/>
      </div>
      <div class="chat-list__item-info">
        <div class="chat-list__item-username">{{msg.companionId.username}}</div>
        <div class="chat-list__item-last-msg">
          {{msg.lastMessage}}
        </div>
      </div>
    </li>
  </ul>
  <div v-else class="text-center">
    <div class="chat-list__empty-model text" v-if="role === 'model'">
      <slot v-if="hasSubscribers">
        Send your first photo or video to all subscribers
        <div class="chat-list__empty-model-arrow text-center mt-10">❯</div>
      </slot>
      <slot v-else>
        You don't have subscribers to send messages.
      </slot>
    </div>
    <div v-else class="mt-40 text">
      No messages
    </div>
  </div>

  <div
    v-if="role === 'model'"
    :class="['message-input d-flex', { disabled: !hasSubscribers }]"
  >
    <MessagingMedia
      :text="message"
      :price="mediaPrice"
      button-text="Send to all"
      @sent="onMediaSent"
    />
    <Input
      :value="message"
      placeholder="Your message"
      class="message-input__field"
      @onKeyUp="onTextInput"
    />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { creator, consumer } from '@/api';
  import { Input } from '@/components/atoms';
  import { MessagingMedia } from '@/components';

  export default {
    name: 'MessagingListPage',
    components: { Input, MessagingMedia },
    setup() {
      const router = useRouter();
      const store = useStore();
      const role = store.getters['app/userRole'];
      const chatList = ref([]);
      const message = ref('');
      const mediaPrice = ref(0);
      const hasSubscribers = ref(store.state.creator.profile?.model?.statistics?.subscribers);

      if (role === 'model') {
        mediaPrice.value = store.state.creator.profile?.model?.price_settings?.private_message;
      }

      const onChatOpen = (username) => {
        router.push(`/app/messaging/${username}`);
      }

      const onTextInput = (e) => {
        message.value = e.target.value;
      };

      const onMediaSent = () => {

        /*
          Let's give a change for MQTT broker to delivery all messages and server to put all data to history
          TODO: To think about some retry system
        */
        setTimeout(() => {
          getChatList();
        }, 1000);
      }

      const getChatList = async () => {
        let historyResp;

        if (role === 'member') {
          historyResp = await consumer.getChatList();
        } else {
          historyResp = await creator.getChatList();
        }

        chatList.value = historyResp.data.summary;

        await store.dispatch('app/unread', false);
      }

      onMounted( () => {
        getChatList();
      });

      return {
        message, role, chatList, mediaPrice, hasSubscribers,
        onChatOpen, onTextInput, onMediaSent,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .chat-list {
    margin: 30px 0;

    &__item:first-child {
      border-top: 1px solid #353535;
    }

    &__item {
      margin: 0;
      padding: 15px 30px;

      border-bottom: 1px solid #353535;
    }

    &__item-avatar {
      width: 45px;
      height: 45px;
      min-width: 45px;
      margin-right: 15px;
      border-radius: 25px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__item-info {
      width: 80%;
      font-family: Roboto;
      font-size: 12px;
    }

    &__item-username {
      margin-top: 4px;
      margin-bottom: 5px;

      font-size: 14px;
      font-weight: bold;
    }

    &__item-last-msg {
      font-style: italic;
      font-weight: 300;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__empty-model {
      position: fixed;
      bottom: 140px;
      width: 100%;
    }

    &__empty-model-arrow {
      transform: rotate(90deg);
    }
  }

  .message-input {
    position: fixed;
    bottom: 60px;
    width: 100%;
    padding: 15px 20px;

    background: #141414;

    &__action {
      position: relative;
      margin: 0 10px 0 0;

      .icon {
        margin-top: 8px;
      }
    }

    &__field {
      width: 100%;
    }
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">My Account</h1>
  <h2 class="sub-header text-center text-uppercase">Profile settings</h2>

  <form class="form">
    <div class="form__group">
      <Input disabled :value="data.fields.login"/>
    </div>
    <div class="form__group">
      <Input
        :value="data.fields.firstName"
        placeholder="First name"
        @onInput="onInput('firstName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
        :value="data.fields.lastName"
        placeholder="Last name"
        @onInput="onInput('lastName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
          :value="data.fields.username"
          placeholder="Username"
          @onInput="onInput('username', $event)"
      />
    </div>
  </form>

  <h2 class="sub-header text-center text-uppercase mt-25">Home settings</h2>

  <Cover isEditable :avatar="avatar" :cover="cover"/>

  <form class="form mt-60">
    <div class="form__group">
      <Textarea
        placeholder="Please, describe yourself"
        :value="data.fields.aboutMe"
        @onInput="onInput('aboutMe', $event)"
      />
    </div>
    <div class="form__group">
      <SelectBox
        placeholder="SELECT NATIONALITY"
        :options="data.lists.nationalityList"
        :value="data.fields.nationality"
        @onSelect="onSelect('nationality', $event)"
      />
    </div>
    <div class="form__group">
      <SelectBox
        placeholder="MARITAl STATUS"
        :options="data.lists.maritalList"
        :value="data.fields.maritalStatus"
        @onSelect="onSelect('maritalStatus', $event)"
      />
    </div>

    <div class="checkbox-panel">
      <h4 class="checkbox-panel__header">Describe body types</h4>
      <div class="checkbox-panel__box">
        <div
          class="checkbox-panel__cell"
          v-for="checkbox in data.lists.bodyTypes"
          :key="checkbox.value"
        >
          <Checkbox
            :label="checkbox.text"
            :value="checkbox.value"
            :checked="data.fields.bodyType === checkbox.value"
            @onChange="onOptionSelect('bodyType', checkbox.value, $event)"
          />
        </div>
      </div>
    </div>

    <div class="checkbox-panel">
      <h4 class="checkbox-panel__header">BODY MODS</h4>
      <div class="checkbox-panel__box">
        <div
          class="checkbox-panel__cell"
          v-for="checkbox in data.lists.bodyMods"
          :key="checkbox.value"
        >
          <Checkbox
            :label="checkbox.text"
            :value="checkbox.value"
            :checked="data.fields.bodyMod === checkbox.value"
            @onChange="onOptionSelect('bodyMod', checkbox.value, $event)"
          />
        </div>
      </div>
    </div>

    <h2 class="sub-header text-center text-uppercase mt-25">Attributes</h2>

    <form class="attributes mt-20 mb-60">
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hair color"
            :value="data.fields.hairColor"
            :options="data.lists.hairColors"
            @onSelect="onSelect('hairColor', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hair length"
            :value="data.fields.hairLength"
            :options="data.lists.hairLengths"
            @onSelect="onSelect('hairLength', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Eye color"
            :value="data.fields.eyeColor"
            :options="data.lists.eyeColors"
            @onSelect="onSelect('eyeColor', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
              placeholder="Height"
              :value="data.fields.height"
              :options="data.lists.height"
              @onSelect="onSelect('height', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Weight"
            :value="data.fields.weight"
            :options="data.lists.weight"
            @onSelect="onSelect('weight', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Bust size"
            :value="data.fields.bustSize"
            :options="data.lists.bustSize"
            @onSelect="onSelect('bustSize', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Waist size"
            :value="data.fields.waistSize"
            :options="data.lists.waistSize"
            @onSelect="onSelect('waistSize', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hip size"
            :value="data.fields.hipSize"
            :options="data.lists.hipSizes"
            @onSelect="onSelect('hipSize', $event)"
          />
        </div>
      </div>
    </form>

    <Button text="Save changes" class="mb-20" @onClick="onProfileUpdate"/>
  </form>
</template>

<script>
  import { onBeforeMount, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { common } from '@/api';
  import { Input, Cover, Textarea, Checkbox, Button, SelectBox } from '@/components';

  export default {
    name: 'CreatorAccount',
    components: { Cover, Input, Textarea, Checkbox, Button, SelectBox },
    setup() {
      const store = useStore();
      const user = store.getters['app/userInfo'];
      const profile = store.getters['creator/profile'];
      const avatar = ref(store.state.creator.profile.model.picture);
      const cover = ref(store.state.creator.profile.model.home_settings.picture);
      const creatorData = reactive({
        avatar: profile.picture,
        fields: {
          username: null,
          login: null, height: null,
          weight: null, aboutMe: null,
          hipSize: null, bustSize: null,
          lastName: null, eyeColor: null,
          waistSize: null, firstName: null,
          hairColor: null, hairLength: null,
          nationality: null, maritalStatus: null,
          bodyMod: null, bodyType: null,
        },
        lists: {
          weight: [], height: [],
          bustSize: [], hipSizes: [],
          bodyMods: [], waistSize: [],
          bodyTypes: [], eyeColors: [],
          hairColors: [], hairLengths: [],
          maritalList: [], nationalityList: [],
        }
      });

      const prepareDataForLists = (data, metrics) => (data.map((value) => ({
        text: `${value.toString().toUpperCase()} ${metrics || ''}`,
        value,
      })));

      onBeforeMount(async () => {
        const { data } = await common.getPublicOptions();
        const { fields, lists } = creatorData;
        const { model: { home_settings }} = profile;

        fields.login = user.phone || user.email;
        fields.username = profile.model.username;
        fields.height = profile.model.attributes.height;
        fields.weight = profile.model.attributes.weight;
        fields.firstName = profile.model.first_name;
        fields.lastName = profile.model.last_name;
        fields.hipSize = profile.model.attributes.hip_size;
        fields.waistSize = profile.model.attributes.waist_size;
        fields.aboutMe = home_settings.about_me;
        fields.bustSize = profile.model.attributes.bust_size;
        fields.eyeColor = profile.model.attributes.eye_color;
        fields.hairColor = profile.model.attributes.hair_color;
        fields.hairLength = profile.model.attributes.hair_length;
        fields.nationality = home_settings.nationality;
        fields.maritalStatus = home_settings.marital_status;
        fields.bodyType = home_settings.body_type;
        fields.bodyMod = home_settings.body_mods;

        /* Data to render lists */
        lists.nationalityList = prepareDataForLists(data.profile.nationality);
        lists.maritalList = prepareDataForLists(data.profile.marital_statuses);
        lists.bodyTypes = prepareDataForLists(data.profile.body_types);
        lists.bodyMods = prepareDataForLists(data.profile.body_mods);
        lists.hairColors = prepareDataForLists(data.attributes.hair_color);
        lists.hairLengths = prepareDataForLists(data.attributes.hair_length);
        lists.eyeColors = prepareDataForLists(data.attributes.eye_color);
        lists.hipSizes = prepareDataForLists(data.attributes.hip_size, '"');
        lists.waistSize = prepareDataForLists(data.attributes.waist_size, '"');
        lists.bustSize = prepareDataForLists(data.attributes.bust_size);
        lists.weight = prepareDataForLists(data.attributes.weight, 'kg');
        lists.height = prepareDataForLists(data.attributes.height, 'cm');
      });

      const onOptionSelect = (type, selectedValue, { value }) => {
        creatorData.fields[type] = value ? selectedValue : null;
      };

      const onInput = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onSelect = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onProfileUpdate = async (event) => {
        event.preventDefault();

        const { fields } = creatorData;

        await store.dispatch(
            'creator/updateProfileInfo',
            {
              model: {
                first_name: fields.firstName,
                last_name: fields.lastName,
                username: fields.username,
                attributes: {
                  height: fields.height,
                  weight: fields.weight,
                  hip_size: fields.hipSize,
                  waist_size: fields.waistSize,
                  bust_size: fields.bustSize,
                  eye_color: fields.eyeColor,
                  hair_color: fields.hairColor,
                  hair_length: fields.hairLength,
                },
                home_settings: {
                  ...profile.model.home_settings,
                  about_me: fields.aboutMe,
                  nationality: fields.nationality,
                  marital_status: fields.maritalStatus,
                  body_type: fields.bodyType,
                  body_mods: fields.bodyMod,
                },
              },
            },
        ).then(() => {
          store.dispatch('message/show', {
            text: 'You successfully updated your profile',
            type: 'info'
          });
        });
      }

      return {
        data: creatorData,
        avatar, cover,
        onOptionSelect, onInput, onSelect, onProfileUpdate,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 20px;
  }

  .cover {
    margin-top: 20px;
  }

  .checkbox-panel {
    margin: 10px 0;

    &__header {
      padding: 22px 12px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.1em;

      text-transform: uppercase;
      color: #FFF;
      background: #000;
    }

    &__box {
      padding: 14px 24px;
      background: #141414;
    }

    &__cell {
      width: 50%;
      height: 20px;
      margin: 6px 0;
      display: inline-block;
    }
  }

  .attributes {
    &__row {
      margin-bottom: 20px;
    }

    &__cell {
      width: 50%;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
</style>

<template>
  <form class="form">
    <div class="form__group">
      <p class="text form__label">E-mail</p>
      <Input
        :value="data.fields.login"
        placeholder="E-mail"
        :disabled="Boolean(hasEmail)"
        @onInput="onInput('login', $event)"
      />
    </div>
    <div class="form__group">
      <p class="text form__label">First name</p>
      <Input
        :value="data.fields.first_name"
        placeholder="First name"
        @onInput="onInput('first_name', $event)"
      />
    </div>
    <div class="form__group">
      <p class="text form__label">Last name</p>
      <Input
        :value="data.fields.last_name"
        placeholder="Last name"
        @onInput="onInput('last_name', $event)"
      />
    </div>
    <div class="form__group">
      <p class="text form__label">Username</p>
      <Input
        :value="data.fields.username"
        placeholder="Username"
        @onInput="onUsernameCheck('username', $event)"
      />
      <div v-if="isUsernameAvailable === 'available'" class="username--available">Username is available</div>
      <div v-else-if="isUsernameAvailable === 'in use'" class="username--in-use">Username already in use</div>
    </div>
    <div class="form__group">
      <p class="text form__label">Birthday</p>
      <Input
        :value="data.fields.birthday"
        placeholder="Birthday (09/24/1990)"
        @onInput="onInput('birthday', $event)"
        v-maska="'##/##/####'"
      />
    </div>
  </form>

  <form class="form mt-60">
    <div class="form__group">
      <p class="text form__label">Describe yourself</p>
      <Textarea
        placeholder="Please, describe yourself"
        :value="data.fields.about_me"
        @onInput="onInput('about_me', $event)"
      />
    </div>
    <div class="form__group">
      <p class="text form__label">Nationality</p>
      <SelectBox
        placeholder="SELECT NATIONALITY"
        :options="data.lists.nationalityList"
        :value="data.fields.nationality"
        @onSelect="onSelect('nationality', $event)"
      />
    </div>
    <div class="form__group">
      <p class="text form__label">Marital status</p>
      <SelectBox
        placeholder="MARITAl STATUS"
        :options="data.lists.maritalList"
        :value="data.fields.marital_status"
        @onSelect="onSelect('marital_status', $event)"
      />
    </div>

    <CheckboxPanel
      title="* body type"
      :options="data.lists.body_types"
      :selected="data.fields.body_type ? [data.fields.body_type] : []"
      :isOpened="true"
      :isSingle="true"
      @select="onOptionSelect('body_type', $event)"
    />

    <CheckboxPanel
      title="* BODY MODS"
      :options="data.lists.body_mods"
      :selected="data.fields.body_mod || []"
      :isOpened="true"
      @select="onMultiOptionsSelect('body_mod', $event)"
    />

    <CheckboxPanel
      title="* FETISHES"
      :options="data.lists.fetishes"
      :selected="data.fields.fetishes || []"
      :isOpened="true"
      @select="onMultiOptionsSelect('fetishes', $event)"
    />

    <h2 class="sub-header text-center text-uppercase mt-25">Attributes</h2>

    <form class="attributes mt-20 mb-60">
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <p class="text form__label">Hair color</p>
          <SelectBox
            placeholder="Hair color"
            :value="data.fields.hair_color"
            :options="data.lists.hair_colors"
            @onSelect="onSelect('hair_color', $event)"
          />
        </div>
        <div class="attributes__cell">
          <p class="text form__label">Hair length</p>
          <SelectBox
            placeholder="Hair length"
            :value="data.fields.hair_length"
            :options="data.lists.hair_lengths"
            @onSelect="onSelect('hair_length', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <p class="text form__label">Eye color</p>
          <SelectBox
            placeholder="Eye color"
            :value="data.fields.eye_color"
            :options="data.lists.eye_colors"
            @onSelect="onSelect('eye_color', $event)"
          />
        </div>
        <div class="attributes__cell">
          <p class="text form__label">Height</p>
          <SelectBox
            placeholder="Height"
            :value="data.fields.height"
            :options="data.lists.height"
            @onSelect="onSelect('height', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <p class="text form__label">Weight</p>
          <Input
            :value="data.fields.weight"
            placeholder="Weight"
            @onInput="onInput('weight', $event)"
          />
        </div>
        <div class="attributes__cell">
          <p class="text form__label">Bust size</p>
          <SelectBox
            placeholder="Bust size"
            :value="data.fields.bust_size"
            :options="data.lists.bust_size"
            @onSelect="onSelect('bust_size', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <p class="text form__label">Waist size</p>
          <SelectBox
            placeholder="Waist size"
            :value="data.fields.waist_size"
            :options="data.lists.waist_size"
            @onSelect="onSelect('waist_size', $event)"
          />
        </div>
        <div class="attributes__cell">
          <p class="text form__label">Hip size</p>
          <SelectBox
            placeholder="Hip size"
            :value="data.fields.hip_size"
            :options="data.lists.hip_sizes"
            @onSelect="onSelect('hip_size', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <p class="text form__label">Cup size</p>
          <SelectBox
            placeholder="Cup size"
            :value="data.fields.cup_size"
            :options="data.lists.cup_size"
            @onSelect="onSelect('cup_size', $event)"
          />
        </div>
        <div class="attributes__cell">&nbsp;</div>
      </div>
    </form>

    <Button text="Next" class="mb-20" @onClick="onProfileUpdate"/>
  </form>
</template>

<script>
  import { ref, onBeforeMount, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { maska } from 'maska'

  import { common } from '@/api';
  import { throttle } from '@/utils';
  import {
    Input, Textarea, Button, SelectBox, CheckboxPanel
  } from '@/components';

  export default {
    name: 'ModelForm',
    components: {
      Input, Textarea, Button, SelectBox, CheckboxPanel
    },
    props: {
      onStepIncrement: Function,
    },
    directives: { maska },
    setup({ onStepIncrement }) {
      const store = useStore();
      const user = store.getters['app/userInfo'];
      const hasEmail = user.email || '';
      const creatorData = reactive({
        fields: {
          username: null, birthday: null,
          height: null, login: user.email || '',
          weight: null, about_me: null,
          hip_size: null, bust_size: null,
          last_name: null, eye_color: null,
          waist_size: null, first_name: null,
          hair_color: null, hair_length: null,
          nationality: null, marital_status: null,
          body_mod: null, body_type: null,
          cup_size: null, fetishes: null,
        },
        lists: {
          weight: [], height: [],
          bust_size: [], hip_sizes: [],
          body_mods: [], waist_size: [],
          body_types: [], eye_colors: [],
          hair_colors: [], hair_lengths: [],
          maritalList: [], nationalityList: [],
          cup_size: [], fetishes: [],
        }
      });
      const originUsername = ref(null);
      const isUsernameAvailable = ref(null);

      const prepareDataForLists = (data, metrics) => (data.map((value) => ({
        text: `${value.toString().toUpperCase()} ${metrics || ''}`,
        value,
      })));

      onBeforeMount(async () => {
        const { data } = await common.getPublicOptions();
        const { lists } = creatorData;

        /* Data to render lists */
        lists.nationalityList = prepareDataForLists(data.enums.profile.nationality);
        lists.maritalList = prepareDataForLists(data.enums.profile.marital_statuses);
        lists.body_types = prepareDataForLists(data.enums.profile.body_types);
        lists.body_mods = prepareDataForLists(data.enums.profile.body_mods);
        lists.hair_colors = prepareDataForLists(data.enums.attributes.hair_color);
        lists.hair_lengths = prepareDataForLists(data.enums.attributes.hair_length);
        lists.eye_colors = prepareDataForLists(data.enums.attributes.eye_color);
        lists.hip_sizes = prepareDataForLists(data.enums.attributes.hip_size, '"');
        lists.waist_size = prepareDataForLists(data.enums.attributes.waist_size, '"');
        lists.bust_size = prepareDataForLists(data.enums.attributes.bust_size);
        lists.cup_size = prepareDataForLists(data.enums.attributes.cup_size);
        lists.fetishes = prepareDataForLists(data.enums.attributes.fetishes);
        lists.height = prepareDataForLists(data.enums.attributes.height);
        lists.height[0].text = `${lists.height[0].text} or shorter`;
        lists.height[lists.height.length - 1].text = `${lists.height[lists.height.length - 1].text} or taller`;
      });

      const onOptionSelect = (type, { selectedItems }) => {
        creatorData.fields[type] = selectedItems[0] || null;
      };

      const onMultiOptionsSelect = (type, { selectedItems }) => {
        creatorData.fields[type] = [...selectedItems];
      };

      const onInput = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onUsernameCheck = throttle(async (type, value) => {
        if (!value) {
          isUsernameAvailable.value = null;
          return false;
        }

        const symbolsCheck = /^([a-z]|\.|-|_)+$/;
        const isUsernameValid = symbolsCheck.test(value);

        if (!isUsernameValid) {
          store.dispatch('message/show', {
            text: 'Username contains prohibited symbols',
            type: 'error'
          });
        } else {
          creatorData.fields[type] = value;

          const { data } = await common.checkUsernameAvailability({
            role: 'model',
            username: value,
          });

          isUsernameAvailable.value = data.status;

          if (originUsername.value === value) {
            isUsernameAvailable.value = null;
          }
        }
      }, 1000);

      const onSelect = (type, value) => {
        creatorData.fields[type] = value;
      };

      const validateForm = () => {
        const { fields } = creatorData;
        const exceptions = ['marital_status', 'body_mod', 'fetishes'];
        const emptyValues = Object
          .keys(fields)
          .filter(key => !exceptions.includes(key))
          .map(key => fields[key])
          .filter(item => !item);

        if (emptyValues.length) {
          store.dispatch('message/show', {
            text: 'Make sure you filled all required fields',
            type: 'error'
          });
        }

        return !emptyValues.length;
      }

      const onProfileUpdate = async (event) => {
        event.preventDefault();

        const { fields: {
          first_name, last_name, userName, birthday, height, weight, hip_size,
          waist_size, bust_size, eye_color, hair_color, hair_length, cup_size, fetishes,
          about_me, nationality, marital_status, body_mod, body_type, login
        }} = creatorData;

        const hasEmailChanged = login !== user.email;

        if (hasEmailChanged) {
          await store.dispatch('app/updateUser', { uid: user.id, params: { email: login } });
        }

        const hasValidationPassed = validateForm();

        if (hasValidationPassed) {
          await store.dispatch(
            'consumer/createModel',
            {
              first_name,
              last_name,
              username: userName,
              birthday: new Date(birthday).toISOString(),
              attributes: {
                height,
                weight,
                hip_size,
                waist_size,
                bust_size: parseInt(bust_size),
                eye_color,
                hair_color,
                hair_length,
                cup_size,
                fetishes,
              },
              home_settings: {
                about_me,
                nationality,

                // hardcoded for now
                location: 'USA',

                marital_status,
                body_type,
                body_mod,
              },
            },
          ).then(() => {
            originUsername.value = userName;
            isUsernameAvailable.value = null;

            store.dispatch('message/show', {
              text: 'You successfully updated your profile',
              type: 'info'
            });
          }).finally(() => {
            onStepIncrement();
          })
        }
      }

      return {
        data: creatorData,
        hasEmail,
        isUsernameAvailable,
        onOptionSelect,
        onMultiOptionsSelect,
        onInput, onUsernameCheck, onSelect, onProfileUpdate,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 20px;

    &__label {
      font-size: 14px;
      margin-bottom: 10px;

      &:before {
        content: '*';
        color: #ff726f;
        font-size: 12px;
        padding-right: 5px;
      }
    }
  }

  .attributes {
    &__row {
      margin-bottom: 20px;
    }

    &__cell {
      width: 50%;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .username {
    &--available,
    &--in-use {
      position: relative;
      top: 6px;

      font-size: 12px;

      color: #31b131;
    }

    &--in-use {
      color: #e64242;
    }
  }
</style>

<template>
  <div class="public-page register">
    <div class="public-page__header">
      <router-link to="/" class="text-center">
        <img src="@/assets/images/logo-big.svg" class="public-page__logo"/>
      </router-link>
      <h1 class="header">Registration</h1>
      <h2 class="sub-header text-center">as User</h2>
    </div>

    <form class="form" @submit="onSubmit">
      <div :class="['form__group', {
        'is-disabled': form.email.length
      }]">
        <Input
            placeholder="Enter phone number"
            prepend="+1"
            @onInput="onFieldUpdate('phone', $event)"
        />
<!--        <Input-->
<!--            placeholder="Enter phone number"-->
<!--            @onInput="onFieldUpdate('phone', $event)"-->
<!--        />-->
        <!--vue-tel-input @input="onPhoneInput('phone', $event)"></vue-tel-input-->
        <div class="form__item-error">{{errors.loginError}}</div>
      </div>

      <div class="text-uppercase text-center">
        <span>or</span>
      </div>

      <div :class="['form__group', {
        'is-disabled': form.phone.length
      }]">
        <Input
            placeholder="Email address"
            @onInput="onFieldUpdate('email', $event)"
        />
        <div class="form__item-error">{{errors.loginError}}</div>
      </div>

      <div class="form__group">
        <Input
          placeholder="Password"
          :value="form.password"
          type="password"
          @onInput="onFieldUpdate('password', $event)"
        />
        <div class="form__item-error">{{errors.passwordError}}</div>
      </div>
      <div class="form__group">
        <Input
            placeholder="Repeat Password"
            :value="form.passwordRepeat"
            type="password"
            @onInput="onFieldUpdate('passwordRepeat', $event)"
        />
        <div class="form__item-error">{{errors.passwordRepeatError}}</div>
      </div>
      <div class="form__group mt-10 mb-10">
        <Checkbox
          label="By checking this box, I certify that I am at least 18-years old, have the capacity to enter into legally binding contracts, and have read and agree to theTerms & Conditions"
          @onChange="onCheckboxChange"
        />
      </div>
      <div class="form__group">
        <Button text="Continue" :disabled="isBtnDisabled || isLoading"/>
      </div>
    </form>

    <div class="public-page__socials">
      <h2 class="sub-header text-center">or register with</h2>

      <ul class="public-page__socials-list d-flex">
        <li>
          <div class="disabled">
            <span class="icon">
              <img src="@/assets/images/icons/instagram.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div class="disabled">
            <span class="icon">
              <img src="@/assets/images/icons/facebook.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div @click="onSocialAuth('twitter')">
            <span class="icon">
              <img src="@/assets/images/icons/twitter.svg"/>
            </span>
          </div>
        </li>
        <li>
          <div @click="onSocialAuth('google')">
            <span class="icon">
              <img src="@/assets/images/icons/google.svg"/>
            </span>
          </div>
        </li>
      </ul>

      <div class="public-page__socials-links d-flex">
        <router-link to="/" class="link">
          Back to Login
        </router-link>
<!--        <router-link to="/forgot-password" class="link">-->
<!--          Forgot password?-->
<!--        </router-link>-->
      </div>
    </div>

    <FooterPublic/>
  </div>
</template>

<script>
  import { onMounted, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { Input, Button, Checkbox, FooterPublic } from '@/components';
  import config from '@/config';
  import { errorsToMessage } from '@/utils';

  export default {
    name: 'Registration',
    components: {
      Input, Button, Checkbox, FooterPublic,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const errors = ref({
        loginError: null,
        passwordError: null,
        passwordRepeatError: null,
      });
      const form = reactive({
        email: '',
        phone: '',
        password: '',
        passwordRepeat: '',
      });
      const isBtnDisabled = ref(true);
      const isLoading = ref(false);

      const onFieldUpdate = (fieldName, value) => {
        form[fieldName] = value;
      };

      const onSubmit = (event) => {
        event.preventDefault();

        clearValidationErrors();

        validateForm();

        if (!isFormValid()) return false;

        const data = {
          role: 'member',
          password: form.password,
        };

        if (form.email) {
          data.email = form.email;
        } else {
          data.phone = '+1' + form.phone.replace(/\s/g, '');
          // data.phone = form.phone.replace(/\s/g, '');
        }

        isLoading.value = true;

        store.dispatch('app/signUp', data)
        .then((res) => {
          const { user } = res;

          if (user.email) {
            router.push('/registration-verification?type=email');
          }
          if (user.phone) {
            router.push('/registration-verification?type=phone');
          }
        })
        .catch((e) => {
          const data = e.response?.data;
          let errorText = null;

          if (e.exists) {
            errorText = 'User already exists';
          } else if (data) {
            if (e.response.data.errors) {
              errorText = errorsToMessage(data.errors);
            } else {
              errorText = data.message;
            }
          }

          store.dispatch('message/show', { text: errorText, type: 'error' });
        })
        .finally(() => {
          isLoading.value = false;
        });
      };

      const clearValidationErrors = () => {
        Object.keys(errors.value).forEach((fieldName) => {
          errors.value[fieldName] = null;
        });
      };

      /* TODO: universal form handling functionality */
      const validateForm = () => {
        if (!form.phone && !form.email) {
          errors.value.loginError = 'Phone or email can\'t be empty';
        }
        if (form.password.length < config.passwordMinLength) {
          errors.value.passwordError = `Password length must be at least ${config.passwordMinLength} characters long`;
        }
        if (!form.password) {
          errors.value.passwordError = 'Password can\'t be empty';
        }
        if (!form.passwordRepeat) {
          errors.value.passwordRepeatError = 'Password can\'t be empty';
        }
        if (form.password !== form.passwordRepeat) {
          errors.value.passwordRepeatError = 'Passwords should be equal';
        }
      };

      const isFormValid = () => {
        let isNoErrors = true;
        Object.keys(errors.value).forEach((fieldName) => {
          if (isNoErrors) {
            isNoErrors = !errors.value[fieldName];
          }
        });
        return isNoErrors;
      };

      const onCheckboxChange = ({ value }) => {
        isBtnDisabled.value = !value;
      }

      const onPhoneInput = (fieldName, event) => {
        if (event) {
          if (typeof event !== 'string') {
            form[fieldName] = event.target.value;
          }
        }
      };

      const onSocialAuth = (type) => {
        if (window.location.hostname === 'localhost') {
          window.open(`http://localhost:3000/api/v1/oauth/${type}`, '_self');
        } else {
          window.open(`/api/v1/oauth/${type}`, '_self');
        }
      }

      onMounted(() => {
        axios.get('/auth/social-login/success', {
          withCredentials: true,
          'Access-Control-Allow-Origin': '*',
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
          }
          throw new Error('authentication has been failed!');
        })
        .catch((err) => {
          console.log(err);
        });
      })

      return {
        form, errors, isBtnDisabled,
        onFieldUpdate, onSubmit, onCheckboxChange, onPhoneInput, onSocialAuth,
        isLoading,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .register {
    .sub-header {
      margin-top: 10px;
    }

    &__buttons {
      margin-top: 20px;

      .button {
        margin-bottom: 20px;
      }
    }
  }

  .input.has-prepend {
    padding-left: 32px !important;
  }
</style>

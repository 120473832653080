<template>
  <div class="footer">
    <ul class="footer__list">
      <li>
        <router-link to="/docs/terms" class="link">TERMS & CONDITIONS</router-link>
      </li>
      <li>
        <router-link to="/docs/usc" class="link">18 U.S.C. 2257</router-link>
      </li>
      <li>
        <router-link to="/docs/privacy" class="link">PRIVACY POLICY</router-link>
      </li>
      <li>
        <router-link to="/docs/dmca" class="link">DMCA POLICY</router-link>
      </li>
      <li>
        <router-link to="/docs/cookies" class="link">COOKIES</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'FooterPublic',
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: relative;

    &:before {
      content: '';

      position: absolute;
      margin: 0  -20px;
      width: calc(100% + 40px);
      height: 1px;

      background: #3F3F3F;
    }
  }

  .footer__list {
    margin-top: 35px;
    padding-top: 35px;
    list-style-type: none;

    li {
      display: inline-block;
      padding: 0 10px 30px 10px;
    }

    li:nth-child(odd) {
      width: 60%;
    }
    li:nth-child(even) {
      width: 40%;
    }

    @media (max-width: 374px) {
      .link {
        font-size: 11px;
      }
    }
  }
</style>

<template>

  <!-- Showing list of stories -->
  <div class="stories" v-if="stories && stories.length">
    <div class="stories__wrapper d-flex">
      <Story
        v-for="story in stories"
        :key="story.id"
        :story="story"
      />
    </div>
  </div>

  <!-- Showing user's feed -->
  <div v-if="!isModelsListVisible">
    <h2 class="header text-center text-uppercase">Feed</h2>
    <div class="model-cards">
      <ModelPost
        v-for="post in feed"
        :post="post"
        :key="post._id"
        isViewOnly
      />
    </div>
  </div>

  <!-- Showing list of most popular models if user don't subscribed yet -->
  <div v-else>
    <h2 class="header text-center text-uppercase header_size_sm">Recommendations</h2>
    <div class="models-list">
      <ModelCard
        :key="model._id"
        v-for="model in topModels"
        :data="{ ...model, url: `/${model.username}` }"
      />
    </div>
  </div>
</template>

<script>
  import { ref, watch, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import { ModelPost, ModelCard, Story } from '@/components';
  import { consumer } from '@/api';

  export default {
    name: 'ConsumerHomePages',
    components: { ModelPost, ModelCard, Story },
    setup() {
      const store = useStore();
      const feed = ref([]);
      const topModels = ref([]);
      const isFeedLoading = ref(false);
      const stories = ref(null);
      const isModelsListVisible = ref(false);
      let contentBlock = null;

      const getFeed = async () => {
        if (store.state.consumer.feed.nextPage) {
          isFeedLoading.value = true;
          await store.dispatch('consumer/getFeed', { next: true });
          isFeedLoading.value = false;
        }
      }

      const onContentScroll = () => {
        const contentHeight = contentBlock.offsetHeight;

        if (!isFeedLoading.value && contentHeight > innerHeight) {
          if (contentHeight - document.documentElement.scrollTop < window.innerHeight) {
            getFeed();
          }
        }
      };

      watch(() => store.state.consumer.feedList, (data) => {
        feed.value = data;

        /* If feed is empty */
        if (!data.length && store.state.consumer.feed.page === 1) {
          store.dispatch('consumer/getTopModels');
        } else {
          isModelsListVisible.value = false;
        }
      });

      watch(() => store.state.consumer.topModels, (data) => {
        topModels.value = data;
        isModelsListVisible.value = true;
      });

      onMounted(async () => {
        await store.dispatch('consumer/getFeed', { next: true });
        window.addEventListener('scroll', onContentScroll);
        contentBlock = document.querySelector('.content');

        const storiesData = await consumer.getStories();
        stories.value = storiesData.data;
      });

      onUnmounted(async () => {
        window.removeEventListener('scroll', onContentScroll);
        await store.dispatch('consumer/clearFeed');
      });

      return { stories, feed, topModels, isModelsListVisible };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 20px;
  }

  .menu {
    padding: 20px 30px;

    background: #141414;
    list-style-type: none;

    &__item {
      display: inline-block;

    }

    .link {
      font-weight: bold;
    }
  }

  .model-cards {
    margin: 10px 0 30px 0;
  }

  .stories {
    padding: 10px;
    width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper {
      max-width: 3000px;
    }
  }
</style>

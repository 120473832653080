<template>
  <div className="public-page" ref="wrapper"></div>
  <div class="m-20" v-if="isLoaded">
    <h1 class="header text-uppercase text-center mb-20">
      HelpDesk Support
    </h1>
    <h2 class="sub-header text-uppercase text-center">
      If you need any help, please press green button in the bottom right corner
    </h2>
  </div>
  <div v-else class="loader d-flex justify-center align-items-center">
    <img src="@/assets/images/spinner-trans.svg"/>
  </div>
</template>

<script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';

  export default {
    name: 'Helpdesk',
    setup() {
      const wrapper = ref();
      const ZENDESK_KEY = 'bcd29689-7c12-415f-8ed2-f9a1ac05880f';
      const isLoaded = ref(false);

      onMounted(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;

        wrapper.value.appendChild(script);

        script.onload = () => {
          window.zE('webWidget:on', 'open', () => {
            setTimeout(() => {
              isLoaded.value = true;
            }, 2000);
          });

          window.zE('webWidget', 'show');
          window.zE('webWidget', 'open');
        };
      });

      onBeforeUnmount(() => {
        window.zE('webWidget', 'close');
        window.zE('webWidget', 'hide');
      });

      return {
        isLoaded,
        wrapper
      };
    }
  }
</script>

<style lang="scss" scoped>
  .sub-header {
    line-height: 34px;
  }
  .loader {
    height: 80vh;
  }
</style>

import AuthPagesController from '@/controllers/AuthPagesController';

export default [
  {
    path: '/app/admin/reports',
    name: 'AdminReportsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/model-reports',
    name: 'AdminUserReportsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/analytics',
    name: 'AdminAnalyticsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/featured',
    name: 'AdminFeaturedPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/payouts',
    name: 'AdminPayoutsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/users',
    name: 'AdminUsersPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
  {
    path: '/app/admin/model-requests',
    name: 'AdminModelRequestsPage',
    component: AuthPagesController,
    meta: {
      auth: true,
      roles: ['admin'],
    },
  },
];

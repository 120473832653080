<template>
  <div class="public-page">
    <router-link to="/" class="public-page__close">
      <span class="icon">
        <img src="@/assets/images/icons/cross.svg"/>
      </span>
    </router-link>
    <h1 class="header text-center mt-25">18 U.S.C. 2257</h1>
    <p class="text mt-25">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lacus mauris. Maecenas iaculis dolor a enim placerat aliquam. Phasellus scelerisque lacus est, sed mattis dui elementum vitae. In in justo non odio condimentum maximus. In posuere diam dui, eu lacinia mauris ullamcorper a. Suspendisse luctus est vel tortor tempus, non dignissim nulla dictum. Fusce bibendum felis ut dolor porta accumsan. Suspendisse a justo eu ante molestie faucibus. Sed aliquet urna at sem varius, a bibendum leo mattis. Morbi efficitur vel justo eget sagittis. Nunc consectetur tortor est, non porttitor lectus volutpat sit amet. Maecenas dapibus mi vel mattis volutpat. Curabitur venenatis, nulla id dignissim condimentum, erat neque pharetra metus, sed aliquam nunc enim et est. Ut dictum iaculis orci, sit amet lobortis nisl euismod eu. Cras congue egestas ipsum elementum feugiat.
    </p>
    <p class="text mt-10">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lacus mauris. Maecenas iaculis dolor a enim placerat aliquam. Phasellus scelerisque lacus est, sed mattis dui elementum vitae. In in justo non odio condimentum maximus. In posuere diam dui, eu lacinia mauris ullamcorper a. Suspendisse luctus est vel tortor tempus, non dignissim nulla dictum. Fusce bibendum felis ut dolor porta accumsan. Suspendisse a justo eu ante molestie faucibus. Sed aliquet urna at sem varius, a bibendum leo mattis. Morbi efficitur vel justo eget sagittis. Nunc consectetur tortor est, non porttitor lectus volutpat sit amet. Maecenas dapibus mi vel mattis volutpat. Curabitur venenatis, nulla id dignissim condimentum, erat neque pharetra metus, sed aliquam nunc enim et est. Ut dictum iaculis orci, sit amet lobortis nisl euismod eu. Cras congue egestas ipsum elementum feugiat.
    </p>
  </div>
</template>

<script>
export default {
  name: 'USCPage',
}
</script>

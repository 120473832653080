export default {
	ANTMEDIA_SERVER_SOCKET: 'wss://stream.stage.rhinofans.com:5443/LiveApp/websocket',
	// ANTMEDIA_SERVER_SOCKET: 'wss://stream.test.rhinofans.com:5443/LiveApp/websocket',

	AWS_REFRESH_FILES_DELAY: 3000,
	DEFAULT_STREAM_NAME: 'rhino-default-stream',
	ICE_SERVERS: [{ 'urls': 'stun:stun1.l.google.com:19302' }],

	MQTT_BROKER: 'wss://RhinoUser:J8e82jDs4W7ExRc723y284@b-5eb12a7c-f933-4e93-8314-1b472351d9b2-1.mq.us-west-2.amazonaws.com:61619',
	// MQTT_BROKER: 'wss://RhinoUser:J8e82jDs4W7ExRc723y284@b-afb24a9d-ccc6-49b4-9dce-073a457674d5-1.mq.us-west-2.amazonaws.com:61619',

	MQTT_BROKER_CONFIG: {
		clean: true,
		connectTimeout: 30 * 1000,
		keepalive: 60,
		protocolId: 'MQTT',
		protocolVersion: 4,
		reconnectPeriod: 1000,
		will: {
			payload: 'Connection Closed abnormally..!',
			qos: 0,
			retain: false,
			topic: 'WillMsg'
		}
	},

	originBucket: 'https://rhino-stage-storage-pub.s3.amazonaws.com',
	thumbsBucket: 'https://rhino-stage-storage-pub-thumb.s3.amazonaws.com',
	// originBucket: 'https://rhino-storage-pub.s3.amazonaws.com',
	// thumbsBucket: 'https://rhino-storage-pub-thumb.s3.amazonaws.com',

	passwordMinLength: 6,
	SOCKET: {
		// connectionURl: process.env.NODE_ENV === 'production' ? `${window.location.origin}:3000` : 'http://localhost:3000'
		connectionURl: `${window.location.origin}:3000`
	},
	STORY_MAX_DURATION: 60,
	STORY_MAX_SIZE: 0.1,
	VOUCHED: {
		PRODUCTION: {
			appId: 'ai*yiraS_vjv.6KHBdVe10#D!yoJl@',
			callbackURL: 'https://stage.rhinofans.com/api/v1/sys/vouched',
			// callbackURL: 'https://test.rhinofans.com/api/v1/sys/vouched',
			sandbox: false
		},
		SANDBOX: {
			appId: 'WoSt0gg--h8yWwd#-0#c04wzxWMW4_',
			callbackURL: 'http://localhost:8080/api/v1/sys/vouched',
			sandbox: true
		}
	}
};
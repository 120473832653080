import axios from 'axios';

export default {
  getOwnProfileInfo() {
    return axios.get('/users/profile');
  },
  updateProfileInfo(data) {
    return axios.patch('/users/profile', data);
  },
  createModel(data) {
    return axios.post('/users/create-model', data);
  },
  getFeed({ limit, page }) {
    return axios.get(`/users/feed?perPage=${limit}&page=${page}`);
  },
  getTopModels({ limit, page }) {
    return axios.get(`/users/top-models?perPage=${limit}&page=${page}`);
  },
  getFeaturedModels({ limit, page }) {
    return axios.get(`/users/featured-models?perPage=${limit}&page=${page}`);
  },
  getPopularModels({ type, limit, page }) {
    return axios.get(`/users/popular-models/${type}?perPage=${limit}&page=${page}`);
  },
  getNewestModels({ limit, page }) {
    return axios.get(`users/last-models?perPage=${limit}&page=${page}`);
  },
  getTrendingModels({ limit, page }) {
    return axios.get(`users/mostpay-models?perPage=${limit}&page=${page}`);
  },
  getMemberByUsername({ username }) {
    return axios.get(`/member/page/${username}`);
  },
  getModelByUsername({ username }) {
    return axios.get(`/model/page/${username}`);
  },
  getStreams() {
    return axios.get('/users/streams');
  },
  getStream(params) {
    return axios.get(`/users/streams/${params.sid}`);
  },
  getStreamToken({ streamId }) {
    return axios.get(`/users/streams/${streamId}/token`);
  },
  buyStreamToken(params) {
    return axios.post(`/users/streams/${params.sid}/buy`, params);
  },
  payStreamGoal(params) {
    return axios.post('/users/stream-tip', params);
  },
  followModel({ uid }) {
    return axios.post(`/users/model/${uid}/follow`);
  },
  unfollowModel({ uid }) {
    return axios.post(`/users/model/${uid}/unfollow`);
  },
  getModelPosts({ uid, limit, page }) {
    return axios.get(`/users/model/${uid}/post?perPage=${limit}&page=${page}`);
  },
  subscribeModel({ uid }) {
    return axios.post(`/users/model/${uid}/subscribe`);
  },
  unSubscribeModel({ uid }) {
    return axios.post(`/users/model/${uid}/unsubscribe`);
  },
  searchForModel(params) {
    return axios.post('/users/model_search', params);
  },
  payForSubscription(params) {
    return axios.post('/users/pay_for_subscription', params);
  },
  getChatHistory(params) {
    /* TODO: Remove limit after demo */
    return axios.get(`/users/chat_history/member/${params.id}?perPage=10000`);
  },
  getChatList() {
    return axios.get('/users/chat_list/member');
  },

  /* TODO: Looks strange. Need to check */
  buyChatMedia(params) {
    return axios.post(`/users/model/${params.mid}/post/buy`, params);
  },

  deleteSavedCC() {
    return axios.post('/users/pay_reset');
  },
  getStories() {
    return axios.get('/users/stories');
  },
  viewStory({pid}) {
    return axios.post(`/users/view-story/${pid}`);
  },
  payForTip(params) {
    return axios.post('/users/tip', params);
  },
  addCreditCard(params) {
    return axios.post('/users/connect-card', params);
  },
  getModelStreamsScheduler(params) {
    return axios.post(`/users/model/${params.mid}/shedule?perPage=${params.limit}&page=${params.page}`);
  },
  sendModelReport(mid, params) {
    return axios.post(`/users/model/${mid}/report`, params);
  },
}

<template>
  <span class="checkbox">
    <input class="checkbox__item" :id="id" type="checkbox" :checked="checked" @change="onChange">
    <label :for="id">
      <span class="checkbox__item-label-text">{{label}}</span>
    </label>
  </span>
</template>

<script>
  import { ref } from 'vue';

  export default {
    name: 'Checkbox',
    props: {
      label: {
        type: [String, Number],
        default: '',
      },
      checked: Boolean,
    },
    setup(_, { emit }) {
      const data = ref({
        id: `${Math.random() * 100000000 >> 0}_${Date.now()}`,
      });

      const onChange = (event) => {
        emit('onChange', {
          value: event.target.checked,
          event
        });
      };

      return {
        id: data.value.id,
        onChange
      };
    }
  }
</script>

<style lang="scss" scoped>
  .checkbox {
    position: relative;
    display: inline-block;

    .checkbox__item {
      position: absolute;
      opacity: 0;

      & + label {
        position: relative;
        display: inline-block;
        height: 20px;
        padding: 0;

        font-family: Roboto;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.1em;
        color: #F2F2F2;
      }

      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
      }

      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      &:checked + label:before {
        background: #D5AF34;
      }

      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
      }

      &-label-text {
        display: flex;
        align-items: center;

        position: relative;

        top: -28px;
        margin-left: 32px;
        padding-right: 6px;

        height: 40px;
        line-height: 16px;
      }
    }
  }
</style>

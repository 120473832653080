<template>
  <h1 class="header text-center text-uppercase">Analytics</h1>
  <h2 class="sub-header text-center text-uppercase mt-20">OVERALL OVERVIEW</h2>
  <p class="text text-center mt-10">During the last 30 days...</p>

  <ul class="blocks-list" v-if="stats">
    <li class="blocks-list__item">
      <div class="blocks-list__item-icon text-center">
        <img src="@/assets/images/icons/user-stroke.svg"/>
      </div>
      <div class="blocks-list__item-amount text-center mt-20">{{stats.total.models}}</div>
      <div class="blocks-list__item-title text-center mt-10">Creators Users</div>
      <div class="blocks-list__item-value text-center mt-10">
        <img src="@/assets/images/icons/arrow-green-up.svg"/>
        <span>{{stats.pr.models.toFixed(2)}}%</span>
      </div>
    </li>

    <li class="blocks-list__item">
      <div class="blocks-list__item-icon text-center">
        <img src="@/assets/images/icons/user-filled.svg"/>
      </div>
      <div class="blocks-list__item-amount text-center mt-20">{{stats.total.members}}</div>
      <div class="blocks-list__item-title text-center mt-10">Users</div>
      <div class="blocks-list__item-value text-center mt-10">
        <img src="@/assets/images/icons/arrow-green-up.svg"/>
        <!--img src="@/assets/images/icons/arrow-red-down.svg"/-->
        <span>{{stats.pr.members.toFixed(2)}}%</span>
      </div>
    </li>
  </ul>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { admin } from '@/api';

  export default {
    name: 'AdminAnalyticsPage',
    setup() {
      const stats = ref(null);

      onMounted(async () => {
        const { data } = await admin.getStats();

        stats.value = data;
      });

      return { stats };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .blocks-list {
    display: grid;
    margin: 20px 10px;

    grid-template-columns: 50% 50%;
    row-gap: 20px;

    list-style-type: none;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;

    &__item {
      margin: 10px;
      padding: 30px 10px;
      height: 200px;
      background: #000;
    }

    &__item-amount {
      font-size: 24px;
      line-height: 28px;
      color: #E2F507;
    }

    &__item-title {
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
    }

    &__item-value {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      color: #67CE67;

      img {
        margin-right: 5px;
      }
    }

    &__item-value_type_red {
      color: #D53E34;
    }
  }
</style>

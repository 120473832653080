<template>
  <h1 class="header text-center text-uppercase">Post Reports</h1>

  <ul class="reports-list">
    <PostReportItem v-for="item in reports" :report="item" :key="item.id" @changed="onReportChange"/>
  </ul>
</template>

<script>
  import { ref, onMounted } from 'vue';

  import { admin } from '@/api';
  import { PostReportItem } from '@/components';

  export default {
    name: 'AdminReportsPage',
    components: { PostReportItem },
    setup() {
      const reports = ref([]);

      const onReportChange = ({ id, status }) => {
        reports.value = reports.value.map((report) => {
          if (report._id === id) {
            return { ...report, status };
          }
          return { ...report };
        });
      }

      onMounted(async() => {
        const { data }  = await admin.getReports();
        reports.value = data.docs;
      });

      return { reports, onReportChange };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .reports-list {
    margin: 20px;

    list-style-type: none;
  }
</style>

<template>
  <div class="header">
    <router-link to="/" class="header__close">
      <span class="icon">
        <img src="@/assets/images/icons/cross.svg"/>
      </span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'FullScreenHeader',
  }
</script>

<style lang="scss" scoped>
  .header {
    padding: 10px 20px 0 20px;

    text-align: right;

    &__close {
      display: inline-block;
      padding: 10px;
    }
  }
</style>

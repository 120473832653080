import axios from 'axios';

export default {
  getReports() {
    /* TODO: remove 1000 after MVP */
    return axios.get('/admin/reports?perPage=1000');
  },
  acceptReport(params) {
    return axios.post(`/admin/reports/${params.rid}/accept`);
  },
  rejectReport(params) {
    return axios.post(`/admin/reports/${params.rid}/reject`);
  },
  getStats() {
    return axios.get('/admin/stat');
  },
  findUsers({params}) {
    return axios.post('/admin/users/', params);
  },
  updateUser({uid, params}) {
    return axios.patch(`/admin/users/${uid}`, params);
  },
  disableUser(uid) {
    return axios.get(`/admin/users/${uid}/disable`);
  },
  enableUser(uid) {
    return axios.get(`/admin/users/${uid}/enable`);
  },
  getPayoutRequestsList() {
    return axios.get('/admin/pay-requests');
  },
  paidPayoutRequest(data) {
    return axios.post(`/admin/pay-requests/${data.rid}/pay`, data);
  },
  getModelsReports(params) {
    return axios.get(`/admin/model-reports?perPage=${params.limit}&page=${params.page}`);
  },
  acceptModelReport(rid) {
    return axios.post(`/admin/model-reports/${rid}/accept`);
  },
  rejectModelReport(rid) {
    return axios.post(`/admin/model-reports/${rid}/reject`);
  },
  getListOfModelsRequests(params) {
    return axios.get(`/admin/model-role-requests?perPage=${params.limit}&page=${params.page}`);
  },
  approveModelRequest(params) {
    return axios.post(`/admin/model-role-requests-approve/${params.mid}`);
  },
  rejectModelRequest(params) {
    return axios.post(`/admin/model-role-requests-reject/${params.mid}`, params);
  }
}

<!-- TODO: need to refactor this component. Split into several sub-components -->
<template>
  <h1 class="header text-center text-uppercase">
    <div>{{ titleText }} Post</div>
  </h1>

  <form class="form">
    <div class="form__group">
      <Input
          :value="form.title"
          placeholder="Give it a title"
          @onInput="onInput('title', $event)"
      />
    </div>
    <div class="form__group">
      <Textarea
          :value="form.description"
          placeholder="Description"
          @onInput="onInput('description', $event)"
      />
    </div>
  </form>

  <h2 class="sub-header text-center text-uppercase mt-25">ATTACH MEDIA?</h2>

  <SelectMediaType
    v-if="!thumbSrc && !videoSrc && !audioSrc && !pdfSrc"
    @select="onMediaUpload"
  />

  <div v-if="thumbSrc" class="thumb text-center">
    <img :src="thumbSrc"/>
  </div>

  <div v-if="videoSrc" class="thumb text-center">
    <video :src="videoSrc" controls/>
  </div>

  <div v-if="audioSrc" class="audio-preview text-center">
    <audio controls>
      <source :src="audioSrc" type="audio/mpeg">
    </audio>
  </div>

  <div v-if="pdfSrc" class="d-flex justify-center align-items-center pdf-preview text-center">
    <img src="@/assets/images/icons/document.svg"/>
    <span class="text-left ml-10">
      <slot v-if="pdfSrc.name">
        Document: {{pdfSrc.name}}
      </slot>
      <slot v-else>
        <a :href="pdfSrc" class="link text-underline" target="_blank">PDF Document</a>
      </slot>
    </span>
  </div>

  <!-- We should always upload thumbnail if it's not a photo post -->
  <div v-if="!thumbSrc">
    <h2 class="sub-header text-center text-uppercase mt-25">Upload Media Thumbnail</h2>

    <div v-if="!mediaThumbSrc" class="attach-media d-flex">
      <div class="attach-media-item text-center">
        <div class="attach-media-item__img-wrapper">
          <input
              type="file"
              accept="image/*"
              class="attach-media-item__file-btn"
              @change="onTakeMediaThumb"
          />
          <img src="@/assets/images/icons/picture.svg"/>
        </div>
        <div class="attach-media-item__lbl attach-media-item__lbl-wrap">Take a photo or upload an image.</div>
      </div>
    </div>
    <div v-else class="thumb text-center">
      <img :src="mediaThumbSrc"/>
    </div>
  </div>

  <div class="m-20 text-center" v-if="thumbSrc || videoSrc || audioSrc || pdfSrc">
    <span class="clear-btn icon" @click="onSelectedMediaClear">
      <img src="@/assets/images/icons/trash.svg"/>
    </span>
  </div>

  <form class="form switchers">
    <div
      :class="['form__group', {
        disabled: form.isPublic
      }]"
    >
      <h2 class="sub-header text-center text-uppercase mb-20">Setup price</h2>
      <Input
          :value="form.price"
          placeholder="Price"
          prepend="$"
          @onInput="onInput('price', $event)"
      />
    </div>

    <div
      :class="['form__group mt-20 mb-20', {
        disabled: form.isPublic
      }]"
    >
      <Checkbox
        label="Add discount"
        :value="hasDiscount"
        :checked="hasDiscount"
        @onChange="onSetDiscount($event)"
      />
      <Input
          v-if="hasDiscount"
          :value="form.promotion_price"
          placeholder="Discount price"
          prepend="$"
          @onInput="onInput('promotion_price', $event)"
      />
      <div v-if="hasDiscount" class="d-flex mt-10">
        <div class="promo-date-input">
          <h3 class="text text-uppercase mb-10">Start date</h3>
          <Input
              :value="form.promotion_start"
              @onInput="onDate('promotion_start', $event)"
              class="mr-10"
              type="date"
          />
        </div>
        <div class="promo-date-input">
          <h3 class="text text-uppercase mb-10">End date</h3>
          <Input
              :value="form.promotion_end"
              @onInput="onDate('promotion_end', $event)"
              type="date"
          />
        </div>
      </div>
    </div>

    <Switch label="OPEN TO PUBLIC?" :checked="form.isPublic" @onChange="onMakePostPublic"/>

    <div class="button-loader">
      <Button :disabled="isSendingData" text="SUBMIT POST" class="mt-40" @onClick="onSubmit"/>
      <div v-if="isSendingData" class="button-loader__wrapper text-center">
        <img src="@/assets/images/spinner.svg"/>
      </div>
    </div>
  </form>
</template>

<script>
  import { ref, reactive, computed, onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import moment from 'moment';

  import { Input, Textarea, Switch, Button, Checkbox } from '@/components';
  import SelectMediaType from './SelectMediaType';
  import { common, creator } from '@/api';
  import { errorsToMessage, sizeInMB, sizeInGb } from '@/utils';
  import config from '@/config';

  export default {
    name: 'NewPost',
    components: {
      Input,
      Textarea,
      Switch,
      Button,
      Checkbox,
      SelectMediaType
    },
    props: { },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const thumbSrc = ref(null);
      const videoSrc = ref(null);
      const audioSrc = ref(null);
      const pdfSrc = ref(null);
      const mediaThumbSrc = ref(null);

      const isSendingData = ref(false);
      const form = reactive({
        title: '',
        description: '',
        isPublished: true,
        price: 0,
        isPublic: false,
        promotion_price: null,
        promotion_start: null,
        promotion_end: null,
      });
      const postId = route.query.pid;
      const titleText = computed(() => postId ? 'Edit' : 'Add');
      const hasDiscount = ref(false);
      let image = null;
      let video = null;
      let audio = null;
      let pdf = null;
      let mediaThumb = null;

      const uploadMedia = (media, mediaType, pid) => {
        let signedURLData = null;

        return common.getS3UploadURL({
          mediaType,
          contentType: media.type,
          fileName: media.name,
          postId: pid,
        })
        .then(({ data }) => {
          signedURLData = data;

          return fetch(data.url, {
            method: 'PUT',
            mode: 'cors',
            body: media,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': media.type,
            }
          })
        })
        .then(async () => {
          await common.reportForS3SuccessUpload({
            mediaType,
            contentType: media.type,
            key: signedURLData.key,
            postId: pid,
          });
        });
      };

      const validation = () => {
        let errorTxt = null;

        const isEmptyOrNull = v => v === null || v.match(/^ *$/) !== null;
        const isMediaPresent = thumbSrc.value || videoSrc.value || audioSrc.value || pdfSrc.value;

        if (isEmptyOrNull(form.title) || isEmptyOrNull(form.description)) {
          errorTxt = 'Title and Description are required';
        }

        if (form.description.length < 6) {
          errorTxt = 'Description should be at least 6 characters long';
        }

        if (!isMediaPresent) {
          errorTxt = 'Add at least one type of media (image, video, audio or a pdf)';
        }

        if (!form.isPublic) {
          if (form.promotion_price) {
            if (!form.promotion_start || !form.promotion_end) {
              errorTxt = 'Promotion should have start and end dates';
            }
            if (!moment(form.promotion_start, 'YYYY-MM-DD', true).isValid()) {
              errorTxt = 'Incorrect start date format';
            }
            if (!moment(form.promotion_end, 'YYYY-MM-DD', true).isValid()) {
              errorTxt = 'Incorrect end date format';
            }
          }

          if (parseInt(form.promotion_price, 10) > form.price) {
            errorTxt = 'Promotion price should be less or equal then post price.';
          }
        }

        if (errorTxt) {
          store.dispatch('message/show', {
            type: 'error',
            text: errorTxt,
          });
          return false;
        }

        return true;
      };

      const disablePromoData = () => {
        form.promotion_price = 0;
        form.promotion_start = null;
        form.promotion_end = null;
      }

      const onSubmit = (e) => {
        let post = null;
        let postAction;

        e.preventDefault();

        if (!validation()) return;

        isSendingData.value = true;

        /* If creator unchecked promo, we need to reset promo data */
        if (!hasDiscount.value) {
          disablePromoData();
        }

        if (postId) {
          postAction = creator.updatePost({ pid: postId, data: form });
        } else {
          postAction = creator.createPost(form);
        }

        postAction
            .then((res) => {
              const _media = image || video || audio || pdf;
              let _mediaType = null;

              if (image) {
                _mediaType = 'image';
              } else if (video) {
                _mediaType = 'video';
              } else if (audio) {
                _mediaType = 'audio';
              } else {
                _mediaType = 'pdf';
              }

              post = res;

              if (_media) {
                return uploadMedia(_media, _mediaType, postId || post.data._id);
              }
              return;
            })
            .then(() => {
              if (mediaThumb) {
                return uploadMedia(mediaThumb, 'video-img', postId || post.data._id);
              }
            })
            .then(() => {

              /*
                Image on AWS can't be updated immediately event after callback so we need a delay on our side.
                TODO: find more elegant solution (maybe makes sense in Post component retry to load cover several times)
              */
              setTimeout(() => {
                isSendingData.value = false;
                router.push('/');
              }, config.AWS_REFRESH_FILES_DELAY);
            })
            .catch((error) => {
              if (error.response.data.message.includes('Contains Prohibited Content')) {
                alert('Contains Prohibited Content.');
              } else {
                store.dispatch('message/show', {
                  type: 'error',
                  text: errorsToMessage(error.response.data.errors)
                });
              }
              isSendingData.value = false;
            });
      };

      const onInput = (type, value) => {
        form[type] = value;
      }

      const onDate = (type, value) => {
        form[type] = moment(value).format('YYYY-MM-DD');
      }

      const onTakePhoto = (e) => {
        image = e.target.files[0];

        if (parseFloat(sizeInMB(image.size)) > 20) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Photo size can not be more then 20Mb'
          });
          return false;
        }

        thumbSrc.value = URL.createObjectURL(image);
      }

      const onTakeVideo = (e) => {
        video = e.target.files[0];

        if (parseFloat(sizeInGb(video.size)) > 10) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Video size can not be more then 10Gb'
          });
          return false;
        }

        videoSrc.value = URL.createObjectURL(video);
      }

      const onTakeMediaThumb = (e) => {
        mediaThumb = e.target.files[0];

        if (parseFloat(sizeInMB(mediaThumb.size)) > 20) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Photo size can not be more then 20Mb'
          });
          return false;
        }

        mediaThumbSrc.value = URL.createObjectURL(mediaThumb);
      }

      const onTakeAudio = (e) => {
        audio = e.target.files[0];

        if (parseFloat(sizeInMB(audio.size)) > 20) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Audio size can not be more then 20Mb'
          });
          return false;
        }

        audioSrc.value = URL.createObjectURL(audio);
      }

      const onTakeDoc = (e) => {
        pdf = e.target.files[0];

        if (parseFloat(sizeInMB(pdf.size)) > 10) {
          store.dispatch('message/show', {
            type: 'error',
            text: 'Document size can not be more then 10Mb'
          });
          return false;
        }

        pdfSrc.value = {
          name: pdf.name,
        };
      }

      const onSelectedMediaClear = () => {
        thumbSrc.value = null;
        videoSrc.value = null;
        audioSrc.value = null;
        pdfSrc.value = null;
        mediaThumbSrc.value = null;
      }

      const onMakePostPublic = () => {
        form.isPublic = !form.isPublic;
      }

      const onMediaUpload = ({event, type}) => {
        if (type === 'video') {
          onTakeVideo(event, type);
        } else if (type === 'photo') {
          onTakePhoto(event, type);
        } else if (type === 'audio') {
          onTakeAudio(event, type);
        } else {
          onTakeDoc(event, type);
        }
      }

      const onSetDiscount = ({value}) => {
        hasDiscount.value = value;
      }

      onBeforeMount(async () => {
        if (postId) {
          const media = await creator.getPostMedia({
            mid: store.getters['app/userID'],
            pid: postId
          });

          await creator.getPost(postId).then(({ data }) => {
            form.title = data.title;
            form.description = data.description;
            form.price = data.price;
            form.isPublic = data.isPublic;
            form.promotion_price = data.promotion_price;

            if (data.promotion_price) {
              form.promotion_start = moment(data.promotion_start).format('YYYY-MM-DD');
              form.promotion_end = moment(data.promotion_end).format('YYYY-MM-DD');
            }

            if (data.promotion_price) {
              hasDiscount.value = true;
            }

            if (data.media.mediaType === 'video') {
              videoSrc.value = media.data;
              mediaThumbSrc.value = data.media.thumbnail;
            }
            else if (data.media.mediaType === 'audio') {
              audioSrc.value = media.data;
              mediaThumbSrc.value = data.media.thumbnail;
            }
            else if (data.media.mediaType === 'pdf') {
              pdfSrc.value = media.data;
              mediaThumbSrc.value = data.media.thumbnail;
            }
            else {
              thumbSrc.value = media.data;
            }
          });
        }
      });

      return {
        titleText,
        form,
        thumbSrc,
        audioSrc,
        videoSrc,
        pdfSrc,
        mediaThumbSrc,
        isSendingData,
        hasDiscount,
        onSubmit,
        onInput,
        onTakePhoto,
        onTakeVideo,
        onTakeDoc,
        onTakeMediaThumb,
        onSelectedMediaClear,
        onMakePostPublic,
        onMediaUpload,
        onSetDiscount,
        onDate,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 30px 0;
  }

  .form {
    padding: 0 20px;
  }

  .switchers {
    margin: 10px 0 30px 0;
  }

  .switch {
    margin: 10px 0;
  }

  .thumb {
    margin: 20px;
    height: 200px;

    border: 1px solid #4e4c4c;

    img, video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .audio-preview {
    margin: 50px 0;
  }

  .pdf-preview {
    margin: 50px 20px;
  }

  .button-loader {
    position: relative;

    &__wrapper {
      position: absolute;
      top: 0;
      height: 61px;
      width: 100%;

      background: rgba(0,0,0, 0.82);

      img {
        height: 100%;
      }
    }
  }

  .clear-btn {
    width: 56px;
    height: 56px;
    padding: 13px;

    border-radius: 30px;
    background: #313131;
  }

  .promo-date-input {
    width: 50%;
  }
</style>

<template>
  <router-view/>
  <Notification/>
</template>

<script>
  import { watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import mqtt from 'mqtt';

  import { Notification } from '@/components';
  import { common } from '@/api';
  import config from '@/config';

  import io from 'socket.io-client';

  export default {
    name: 'Application',
    components: { Notification },
    setup() {
      const store = useStore();

      watch(() => store.state.creator.profile?.model._id, (modelId) => {
        if (store.getters['app/userRole'] === 'model') {

          /* Settings Online / Offline status */
          window.__socket = io.connect(config.SOCKET.connectionURl, {
            extraHeaders: {
              'x-role': 'model',
              'x-model-id': modelId
            }
          });
          window.__socket.on('connect', function () {
            console.log('socket connected');
          });
          window.__socket.on('disconnect', function () {
            console.log('socket disconnected');
          });
        }
      });

      onMounted(async () => {
        if (store.getters['app/userRole'] === 'model' && store.getters['creator/profile']) {
          const notifications = await common.getNotificationsList();

          await store.dispatch('app/addNotifications', notifications.data);

          const options = await common.getPublicOptions();
          const clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;
          const client = mqtt.connect(config.MQTT_BROKER, {...config.MQTT_BROKER_CONFIG, clientId});

          client.on('error', (error) => {
            console.log('Connection error: ', error);
            client.end();
          });

          client.on('connect', () => {
            const topic = `${options.data.mqttEnv}_notify_model_${store.getters['creator/profile'].model._id}`;
            client.subscribe(topic, {qos: 0});
          });

          client.on('message', async (topic, message) => {
            await store.dispatch(
                'app/addNotifications',
                [JSON.parse(message.toString())]
            );
          });
        }
      });

      /* We need to block body content and prevent scroll */
      watch(() => store.state.app.isSidebarVisible, (value) => {
        if (value) {
          document.body.classList.add('scroll-none');
        } else {
          document.body.classList.remove('scroll-none');
        }
      });
    }
  }
</script>

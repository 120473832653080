<template>
  <div class="post-preview" v-if="!isPDFType && isPreviewOpened">
    <div class="post-preview__close" @click="onMediaClose">
      <img src="@/assets/images/icons/cross.svg"/>
    </div>
    <div class="post-preview__content">
      <img v-if="!imageToView" src="@/assets/images/spinner-trans.svg"/>
      <img v-else :src="imageToView" class="post-preview__content-img"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostPreview',
  props: {
    isPreviewOpened: Boolean,
    imageToView: String,
    isPDFType: Boolean,
    onMediaClose: Function,
  }
}
</script>

<style lang="scss" scoped>
  .post-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: rgba(0, 0, 0, 0.7);

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content-img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
  }
</style>

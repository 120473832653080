<template>
  <div class="message-media">
    <img src="@/assets/images/icons/picture.svg" class="icon"/>
    <input
        type="file"
        accept="image/*, video/*"
        class="message-media__input"
        @change="onMediaChoose"
    />
  </div>

  <Popup class="message-popup" @close="onPopupClose" v-if="isMessagePopupVisible">
    <div class="message-popup__content">
      <img v-if="thumbSrc" :src="thumbSrc" class="message-popup__thumb"/>
      <video v-else :src="videoSrc" class="message-popup__video" controls/>
      <Input
          :value="message"
          placeholder="Your message"
          class="message-popup__text"
          @onKeyUp="onTextInput"
      />
      <div
        :class="['mt-10 d-flex justify-space-between', {
          disabled: isFree
        }]"
      >
        <p class="message-popup__price-lbl">Setup price for this media</p>
        <Input
            :value="mediaPrice"
            class="message-popup__price"
            prepend="$"
            @onKeyUp="onPriceInput"
        />
      </div>
      <div class="mt-10 d-flex justify-space-between">
        <Checkbox
          label="Send for free"
          :value="isFree"
          :checked="isFree"
          @onChange="onSelectIsFree($event)"
        />
      </div>
      <div class="text-center mt-40">
        <Button :text="buttonText" size="m" type="green" @click="onSend"/>
      </div>
      <div class="message-popup__loader text-center" v-if="isLoading">
        <img src="@/assets/images/spinner-trans.svg"/>
      </div>
    </div>
  </Popup>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { creator, common } from '@/api';
  import { Input, Button, Checkbox } from '@/components/atoms';
  import { Popup } from '@/components';
  import { sizeInGb, sizeInMB } from '@/utils';

  export default {
    name: 'MessagingMedia',
    props: {
      text: {
        type: String,
      },
      price: {
        type: [String, Number],
        default: 0,
      },
      memberId: {
        type: String,
        default: null,
      },
      buttonText: {
        type: String,
      }
    },
    emits: ['sent'],
    components: { Input, Button, Popup, Checkbox },
    setup(props, { emit }) {
      const store = useStore();
      const role = store.getters['app/userRole'];
      const message = ref(props.text);
      const mediaPrice = ref(props.price);
      const thumbSrc = ref(null);
      const videoSrc = ref(null);
      const isMessagePopupVisible = ref(false);
      const mediaFile = ref(null);
      const isLoading = ref(false);
      const isFree = ref(false);

      watch(() => props.text, (text) => {
        message.value = text;
      });

      watch(() => props.price, (price) => {
        mediaPrice.value = price;
      });

      const onTextInput = (e) => {
        message.value = e.target.value;
      };

      const onPriceInput = (e) => {
        mediaPrice.value = e.target.value;
      }

      const onMediaChoose = (e) => {
        const file = e.target.files[0];

        if (file.type.includes('image')) {
          if (parseFloat(sizeInMB(file.size)) > 20) {
            store.dispatch('message/show', {
              type: 'error',
              text: 'Photo size can not be more then 20Mb'
            });
            return false;
          }
          thumbSrc.value = URL.createObjectURL(file);
        } else {
          if (parseFloat(sizeInGb(file.size)) > 10) {
            store.dispatch('message/show', {
              type: 'error',
              text: 'Video size can not be more then 10Gb'
            });
            return false;
          }
          videoSrc.value = URL.createObjectURL(file);
        }

        mediaFile.value = e.target.files[0];
        isMessagePopupVisible.value = true;
      }

      const onPopupClose = () => {
        isMessagePopupVisible.value = false;
        isLoading.value = false;
      }

      const uploadMedia = (media, mediaType, pid) => {
        let signedURLData = null;

        return common
          .getS3UploadURL({
            mediaType,
            contentType: media.type,
            fileName: media.name,
            postId: pid,
          })
          .then(({ data }) => {
            signedURLData = data;

            return fetch(data.url, {
              method: 'PUT',
              mode: 'cors',
              body: media,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': media.type,
              }
            })
          })
          .then(async () => {
            return await common.reportForS3SuccessUpload({
              mediaType,
              contentType: media.type,
              key: signedURLData.key,
              postId: pid,
            });
          });
      };

      const onSend = async (e) => {
        const postData = {
          title: message.value,
          description: message.value,
          isPrivat: true,
          price: isFree.value ? 0 : mediaPrice.value,
        };
        let post;

        e.preventDefault();

        if (!message.value) {
          return store.dispatch('message/show', {
            type: 'error',
            text: 'Message text is required'
          });
        }
        if (!isFree.value && mediaPrice.value === 0) {
          return store.dispatch('message/show', {
            type: 'error',
            text: 'Please setup price'
          });
        }

        isLoading.value = true;

        await creator.createPost(postData)
          .then((res) => {
            post = res;
            return uploadMedia(mediaFile.value, thumbSrc.value ? 'image' : 'video', post.data._id);
          })
          .then(() => {
            return creator.sendMessageToSubscribers({
              pid: post.data._id,
              memberId: props.memberId,
            });
          })
          .then((res) => {
            emit('sent', res.data.data);
            isLoading.value = false;
          })
          .catch((error) => {
            if (error.response.data.message.includes('Contains Prohibited Content')) {
              alert('Contains Prohibited Content.');
            }
            isLoading.value = false;
          });

        onPopupClose();
        resetPreview();
      }

      const resetPreview = () => {
        thumbSrc.value = null;
        videoSrc.value = null;
      };

      const onSelectIsFree = (data) => {
        isFree.value = data.value;
      }

      return {
        isFree,
        message, mediaPrice, isLoading,
        role, thumbSrc, videoSrc, isMessagePopupVisible,
        onTextInput, onMediaChoose, onPriceInput,
        onPopupClose, onSend,
        onSelectIsFree,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .message-media {
    position: relative;
    margin: 8px 15px 0 0;

    &__input {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      opacity: 0;
    }
  }

  .message-popup {
    &__content {
      margin-top: 20px;
    }

    &__thumb, &__video {
      width: 100%;
      height: 200px;
      padding: 5px;
      object-fit: contain;
      background: #000;
    }

    &__text {
      margin-top: 10px;
    }

    &__price {
      width: 80px;
    }

    &__price-lbl {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #FFF;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 38px;
    }

    &__loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: 33px;

      background: rgba(0, 0, 0, 0.7);
    }
  }
</style>

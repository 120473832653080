<template>
  <p class="text paragraph">In order to switch to model account, you need to go through <b class="bold">four</b> simple steps:</p>
  <ol class="list">
    <li class="text"><b class="bold">Describe yourself</b> to your fans by fulfilling the general information</li>
    <li class="text"><b class="bold">Upload</b> your profile <b class="bold">cover</b> and <b class="bold">avatar</b></li>
    <li class="text"><b class="bold">Verify</b> your account</li>
    <li class="text">Sign the <b class="bold">contract</b></li>
  </ol>
  <Button text="Next" class="mb-20" @onClick="onStepIncrement"/>
</template>

<script>
import { Button } from '@/components/atoms';

export default {
  name: 'InfoStep',
  components: { Button },
  props: { onStepIncrement: { type: Function, required: true } },
  setup() {
    
  },
}
</script>

<style scoped>
  .bold {
    font-weight: bold;
  }

  .text {
    font-size: 16px;
    margin: 0 20px;
    padding: 10px 0;
    line-height: 20px;
  }

  .list {
    padding-left: 20px;
  }

  .text:last-child {
    margin-bottom: 66.6px;
  }

  .paragraph {
    margin-bottom: 20px;
  }

  .mb-20 {
    margin: 10px 20px;
  }
</style>
<template>
  <footer class="footer d-flex">
    <!--div class="footer__icon">
      <img src="@/assets/images/icons/bell.svg"/>
    </div-->
    <div class="footer__icon">
      <span class="footer__icon-unread" v-if="hasUnread"></span>
      <router-link to="/app/messaging">
        <img src="@/assets/images/icons/email.svg"/>
      </router-link>
    </div>
    <div class="footer__icon">
      <router-link to="/app">
        <img src="@/assets/images/icons/home.svg"/>
      </router-link>
    </div>
    <div class="footer__icon">
      <router-link to="/app/search">
        <img src="@/assets/images/icons/search.svg"/>
      </router-link>
    </div>
    <div class="footer__icon" @click="onSidebarOpen">
      <img src="@/assets/images/icons/menu.svg"/>
    </div>
  </footer>
</template>

<script>
  import { ref, watch, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import mqtt from 'mqtt';
  import config from '@/config';
  import { common } from '@/api';

  export default {
    name: 'ConsumerFooter',
    setup() {
      const store = useStore();
      const hasUnread = ref(false);
      let client = null;
      let channelToListen = null;
      let member = null;

      const onSidebarOpen = () => {
        store.commit('app/SET_SIDEBAR_VISIBILITY', true);
      };

      watch(() => store.state.app.unread, (isUnread) => {
        hasUnread.value = isUnread;
      });

      /* Temporary solution. Move to APP level after MVP */
      watch(() => store.state.consumer.profile, async (profile) => {
        const options = await common.getPublicOptions();
        const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8);

        member = profile.member._id;
        channelToListen = `${options.data.mqttEnv}/chat/member/${member}`;
        client = mqtt.connect(config.MQTT_BROKER, {...config.MQTT_BROKER_CONFIG, clientId});

        client.on('connect', () => {
          client.subscribe(channelToListen, {qos: 0});
        });

        client.on('message', async () => {
          if (!window.location.pathname.includes('/app/messaging/')) {
            await store.dispatch('app/unread', true);
          }
        });
      });

      onUnmounted(() => {
        client.unsubscribe(channelToListen);
        client.end();
      });

      return { hasUnread, onSidebarOpen };
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    position: fixed;
    width: 100%;
    height: 60px;
    bottom: 0;
    padding: 0 20px;
    z-index: 1;

    justify-content: space-between;
    align-items: center;
    background: #000;

    &__icon {
      position: relative;
      height: 22px;

      img {
        height: 100%;
      }
    }

    &__icon-unread {
      display: inline-block;
      position: absolute;
      top: -6px;
      width: 10px;
      height: 10px;
      right: -5px;

      background: #d23838;
      border-radius: 10px;
    }
  }
</style>

<template>
  <div
    :class="['sidebar', {
      'is-visible': visibilityStatus
    }]"
  >
    <a href="/#" class="sidebar__close" @click="onSidebarClose">
      <span class="icon">
        <img src="@/assets/images/icons/cross.svg"/>
      </span>
    </a>

    <div class="content">
      <h1 class="header text-center">Settings</h1>

      <Avatar class="avatar" :image="avatar" role="creator"/>

      <div class="info text-center">
        <h2 class="info__nickname">{{profile.model.username}}</h2>
      </div>

      <div class="separator"></div>

      <ul class="links">
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/account">Account information</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/fans">FAN MANAGEMENT</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/price-settings">PRICING</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/billing">BILLING & PAYMENTS</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/security">SECURITY & PRIVACY SETTINGS</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/notifications-settings">NOTIFICATIONS</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/scheduler">SCHEDULER</router-link>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="/app/helpdesk">HelpDesk Support</router-link>
        </li>
        <li class="links__item">
          <a
            class="link text-uppercase"
            href="https://rhinofans.helpsite.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>
        </li>
        <li class="links__item">
          <router-link class="link text-uppercase" to="#" @click="onSignOut">SIGN OUT</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Avatar } from '@/components';

  export default {
    name: 'CreatorSidebar',
    props: {
      isVisible: Boolean,
    },
    components: { Avatar },
    setup(props) {
      const store = useStore();
      const profile = ref(store.getters['creator/profile']);
      const avatar = ref(store.state.creator.profile.model.picture);
      const visibilityStatus = ref(props.isVisible);

      watch(() => props.isVisible, (status) => {
        visibilityStatus.value = status;
      });

      watch(() => store.state.creator?.profile.model, (data) => {
        profile.value.model = data;
      });

      watch(() => store.state.creator?.profile.model.picture, (data) => {
        avatar.value = data;
      });

      const onSidebarClose = (event) => {
        event.preventDefault();
        store.commit('app/SET_SIDEBAR_VISIBILITY', false);
      };

      const onSignOut = (event) => {
        event.preventDefault();
        store.commit('app/LOGOUT');
      }

      return {
        visibilityStatus, profile, avatar,
        onSidebarClose, onSignOut,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar {
    position: fixed;
    top: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    margin-left: -100%;

    background: #202020;
    transition: margin .25s linear;

    &__close {
      display: inline-block;
      position: absolute;
      right: 30px;
      top: 30px;
    }
  }

  .is-visible {
    margin-left: 0;
  }

  .header {
    margin-top: 30px;
    text-transform: uppercase;
  }

  .content {
    overflow-y: scroll;
    height: 100vh;
    padding: 30px;
  }

  .avatar {
    margin-top: 20px;
  }

  .info {
    margin-top: 20px;

    letter-spacing: 0.1em;

    &__nickname {
      font-size: 24px;
      letter-spacing: 0.1em;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__email {
      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      text-decoration: none;
      color: #FFF;
    }
  }

  .sub-header {
    margin-top: 25px;
  }

  .connected-apps {
    display: flex;
    justify-content: space-between;

    margin-top: 25px;

    list-style-type: none;
  }

  .separator {
    width: calc(100% + 60px);
    height: 1px;
    margin: 40px 0 40px -30px;

    background: #3F3F3F;
  }

  .links {
    padding-bottom: 100px;
    list-style-type: none;

    &__item {
      margin-bottom: 35px;
    }
  }
</style>

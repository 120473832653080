<template>
  <h1 class="header text-center text-uppercase">Scheduler</h1>

  <div class="new-event text-center" @click="onNewEventPopupShow">
    <div class="new-event__plus">+</div>
    <div class="new-event__txt text text-uppercase mt-10">New Stream Event</div>
  </div>

  <div v-if="!events.length" class="events-list text-center mt-40">
    <p class="text">No streams in scheduler</p>
  </div>
  <div v-else class="events-list mt-40">
    <div
      class="event"
      v-for="event in events"
      :key="event._id"
    >
      <div class="event__title text text-uppercase">
        {{event.title}}
      </div>
      <div class="event__description text">
        ({{event.description}})
      </div>
      <div class="event__date mt-10">
        {{formatEventDate(event)}}
      </div>
      <div class="event__remove" @click="onEventRemove(event)">
        <img src="@/assets/images/icons/trash.svg"/>
      </div>
    </div>
  </div>

  <Popup
    v-if="isNewEventVisible"
    @close="onNewStreamPopupClose"
  >
    <h1 class="header text-center text-uppercase">Stream Event</h1>
    <form class="form">
      <div class="form__group">
        <Input
          :value="form.title"
          placeholder="Title"
          @onInput="onInput($event, 'title')"
        />
      </div>
      <div class="form__group">
        <Textarea
          :value="form.description"
          placeholder="Event description"
          @onInput="onInput($event, 'description')"
        />
      </div>
      <div class="form__group d-flex">
        <div class="form__group-cell">
          <h3 class="text text-uppercase mb-10">Day</h3>
          <Input
            type="date"
            :value="form.date"
            @onInput="onDate($event, 'date')"
          />
        </div>
        <div class="form__group-cell ml-10">
          <h3 class="text text-uppercase mb-10">Time</h3>
          <Input
            type="time"
            :value="form.time"
            @onInput="onDate($event, 'time')"
          />
        </div>
      </div>
      <div class="form__group text-center mt-20">
        <Button
          text="Create"
          type="yellow"
          size="m"
          rounded
          :disabled="isLoading"
          @onClick="onNewEventCreate"
        />
      </div>
    </form>
  </Popup>
</template>

<script>
  import { reactive, ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import moment from 'moment';

  import {
    Popup,
    Textarea,
    Input,
    Button,
  } from '@/components';
  import { creator } from '@/api';

  export default {
    name: 'CreatorSchedulerSettings',
    components: {
      Popup,
      Textarea,
      Input,
      Button,
    },
    setup() {
      const EVENT_LIMIT_SIZE = 25;
      const store = useStore();
      const isNewEventVisible = ref(false);
      const isLoading = ref(false);
      const form = reactive({
        title: '',
        description: '',
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:MM'),
      });
      const events = ref([]);

      const onNewEventPopupShow = () => {
        isNewEventVisible.value = true;
      }

      const onNewStreamPopupClose = () => {
        isNewEventVisible.value = false;

        form.title = '';
        form.description = '';
        form.date = moment().format('YYYY-MM-DD');
        form.time = moment().format('HH:MM');
      }

      const onInput = (value, type) => {
        form[type] = value;
      }

      const onDate = (value, type) => {
        if (type === 'date') {
          form[type] = moment(value).format('YYYY-MM-DD');
        } else {
          form[type] = value;
        }
      }

      const isNewEventValidData = () => {
        let errorTxt = null;

        if (!form.title || !form.description) {
          errorTxt = 'Title and description of the event are required';
        }

        if (!form.date || !form.time) {
          errorTxt = 'Day and time of the event are required';
        }

        const currentDate = new Date().toISOString();
        const formDate = new Date(form.date+'T'+form.time).toISOString();

        if (currentDate > formDate) {
          errorTxt = 'You can\'t schedule for past date';
        }

        if (errorTxt) {
          store.dispatch('message/show', {
            type: 'error',
            text: errorTxt,
          });
          return false;
        }
        return true;
      }

      const onNewEventCreate = async (e) => {
        e.preventDefault();

        isLoading.value = true;

        const formattedDate = moment(form.date + ' ' + form.time, 'YYYY-MM-DD HH:mm');

        if (!isNewEventValidData()) {
          return false;
        }

        const newEvent = await creator.addStreamToScheduler({
          title: form.title,
          description: form.description,
          startAt: formattedDate,
        });

        events.value = [newEvent.data, ...events.value];

        isLoading.value = false;

        onNewStreamPopupClose();
      }

      const formatEventDate = (event) => {
        const date = moment(event.startAt);
        return `${date.format('DD of MMM')} at ${date.format('HH:mm')}`;
      }

      const onEventRemove = async (event) => {
        await creator.deleteSchedulerEvent({
          sid: event._id,
        });
        events.value = events.value.filter(({_id}) => _id !== event._id);
      }

      onMounted(async () => {
        const eventsData = await creator.getSchedulerEvents({
          limit: EVENT_LIMIT_SIZE,
          page: 1,
        });
        events.value = eventsData.data.docs;
      });

      return {
        isNewEventVisible,
        form,
        events,
        onInput,
        onDate,
        onNewEventPopupShow,
        onNewStreamPopupClose,
        onNewEventCreate,
        formatEventDate,
        onEventRemove,
        isLoading,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .new-event {
    width: 120px;
    margin: 0 auto;

    &__plus {
      margin: 0 auto;

      width: 60px;
      height: 60px;
      background: #c19f38;
      border-radius: 30px;
      text-align: center;
      line-height: 60px;
      font-size: 32px;
    }
  }

  .form{
    &__group-cell {
      width: 50%;
    }
  }

  .events-list {
    margin: 30px 10px;
  }

  .event {
    position: relative;
    margin: 10px 0;
    padding: 10px 15px;

    background: #000;
    font-family: Roboto;
    font-style: normal;

    &__title {
      padding-right: 40px;
      font-size: 16px;
    }

    &__description {
      margin-top: 5px;
      font-size: 11px;
      color: #CCC;
    }

    &__date {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #d5ae34;
    }

    &__remove {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;

      img {
        width: 16px;
      }
    }
  }
</style>

import axios from 'axios';

export default {
  getProfileInfo({ uid }) {
    return axios.get(`/users/model_profile/${uid}`);
  },
  getOwnProfileInfo() {
    return axios.get('/users/profile');
  },
  updateProfileInfo(data) {
    return axios.patch('/users/profile', data);
  },
  getModelByUsername({ username }) {
    return axios.get(`/model/page/${username}`);
  },
  createPost(params) {
    return axios.post('/model/posts', params);
  },
  getPost(pid) {
    return axios.get(`/model/posts/${pid}`);
  },
  updatePost({ pid, data }) {
    return axios.patch(`/model/posts/${pid}`, data);
  },
  getPostMedia({ mid, pid }) {
    return axios.get(`/users/model/${mid}/post/${pid}/media`);
  },
  getPostsList(params) {
    return axios.get(`/model/posts?perPage=${params.limit}&page=${params.page}`);
  },
  createStream(params) {
    return axios.post('/model/stream', params);
  },
  createPrivateStream(params) {
    return axios.post(`/model/stream/${params.sid}`, params);
  },
  getModelStream() {
    return axios.get('/model/stream');
  },
  getChatHistory(params) {
    /* TODO: Remove limit after demo */
    return axios.get(`/users/chat_history/model/${params.id}?perPage=1000`);
  },
  getChatList() {
    return axios.get('/users/chat_list/model');
  },
  sendMessageToSubscribers({ pid, memberId }) {
    return axios.post(`/model/posts/${pid}/send`, { memberId });
  },
  getSubscribersAmount(params) {
    return axios.get(`/users/model/${params.mid}/subscribers-num`);
  },
  setModelView(params) {
    return axios.get(`/users/model_profile/${params.uid}/inc`);
  },
  addComment(params) {
    return axios.post(`/model/posts/${params.pid}/comments`, {
      comment: params.comment,
    });
  },
  getComments(params) {
    return axios.get(`/model/posts/${params.pid}/comments?perPage=${params.limit}&page=${params.page}`);
  },
  createStory(data) {
    return axios.post('/model/stories', data);
  },
  getModelBillingActivity(params) {
    return axios.get(`/model/payments?perPage=${params.limit}&page=${params.page}`);
  },
  getOwnPayoutRequests() {
    return axios.get('/model/pay-requests');
  },
  sendPayoutRequest(data) {
    return axios.post('/model/pay-requests', data);
  },
  getBillingBalance() {
    return axios.get('/model/balance');
  },
  getModelSubscribers(params, body) {
    return axios.post(`/model/subscribers?perPage=${params.limit}&page=${params.page}`, body);
  },
  addStreamToScheduler(params) {
    return axios.post('/model/streams-shedule', params);
  },
  getSchedulerEvents(params) {
    return axios.get(`/model/streams-shedule?perPage=${params.limit}&page=${params.page}`);
  },
  deleteSchedulerEvent(params) {
    return axios.delete(`/model/streams-shedule/${params.sid}`);
  }
}

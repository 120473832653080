<template>
  <h1 class="header text-center text-uppercase">ACCOUNTING</h1>
  <h2 class="sub-header text-center text-uppercase">RECENT ACTIVITY</h2>

  <div class="billing-items-list">
    <div
      class="billing-item status__new"
      v-for="transaction in transactions"
      :key="transaction.id"
    >
      <div class="billing-item__wrapper d-flex justify-space-between align-items-center">
        <div class="billing-item__content">
          <div class="billing-item__date">
            {{formatReportDate(transaction.createdAt)}}
          </div>
          <div class="billing-item__txt">
            {{transaction.transactionRef}}
          </div>
        </div>
        <span class="billing-item__amount">
          ${{transaction.amount}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import moment from 'moment';
  import { common } from '@/api';

  export default {
    name: 'ConsumerBillingPage',
    setup() {
      const transactions = ref(null);

      const formatReportDate = (date) => {
        return moment(date).format('MM/DD/YYYY - HH:mm A');
      }

      onMounted(async () => {
        const { data } = await common.getBillingActivity();
        transactions.value = data.docs;
      });

      return {
        transactions,
        formatReportDate,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 20px 0;
  }

  .sub-header {
    margin-bottom: 40px;
  }

  .billing-items-list {
    margin: 0 15px 30px 15px;
  }

  .billing-item {
    position: relative;
    padding: 12px;
    margin-bottom: 1px;

    background: #000;
    border-radius: 2px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;

    &__txt {
      font-size: 14px;
      line-height: 16px;
      word-break: break-word;
    }

    &__date {
      font-size: 10px;
      line-height: 18px;
      color: #888;
    }

    &__amount {
      text-align: center;
      font-size: 22px;
    }

    &.status__new {
      border-left: 1px solid #67AFCE;
    }
    &.status__accepted {
      border-left: 1px solid #67CE67;
    }
    &.status__rejected {
      border-left: 1px solid #D53E34;
    }
  }
</style>

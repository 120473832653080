<template>
  <h2 class="header text-center text-uppercase header_size_sm">Featured</h2>
  <div class="models-list" v-if="featuredModels">
    <p v-if="isLoading" class="text text-center">
      <img src="@/assets/images/spinner.svg"/>
    </p>
    <p v-if="!featuredModels.length" class="text-center text">
      No featured models
    </p>
    <slot v-else>
      <ModelCard
          :key="model._id"
          v-for="model in featuredModels"
          :data="{ ...model, url: `/${model.username}` }"
      />
    </slot>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { ModelCard } from '@/components';
  import { consumer } from '@/api';

  export default {
    name: 'ConsumerFeaturedModelsPage',
    components: { ModelCard },
    setup() {
      const MODELS_PER_PAGE = 10;
      const featuredModels = ref(null);
      const isLoading = ref(true);

      onMounted(async () => {
        const viewedData = await consumer.getFeaturedModels({
          type: 'view',
          limit: MODELS_PER_PAGE,
          page: 1
        });
        featuredModels.value = viewedData.data.docs;
        isLoading.value = false;
      });

      return { isLoading, featuredModels };
    }
  }
</script>

<style lang="scss" scoped>
.header {
  margin: 20px;
}

.menu {
  padding: 15px 30px;

  background: #141414;
  list-style-type: none;

  &__item {
    display: inline-block;

    border-right: 1px solid #444;

    &.is-active {
      color: #D5AF34;
    }
  }

  &__item:last-child {
    border: none;
  }

  .link {
    width: 50%;

    font-weight: bold;
    font-size: 11px;
  }
}

.model-cards {
  margin: 10px 0 30px 0;
}

.see-more {
  width: 40%;
  margin: 20px auto;
}
</style>

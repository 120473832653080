<template>
  <h1 class="header text-center text-uppercase">User Reports</h1>

  <ul class="reports-list">
    <ModelReportItem
      v-for="item in reports"
      :report="item"
      :key="item.id"
      @accepted="onAccepted"
      @rejected="onRejected"
    />
  </ul>
</template>

<script>
  import { ref, onMounted } from 'vue';

  import { admin } from '@/api';
  import { ModelReportItem } from '@/components';

  export default {
    name: 'AdminUserReportsPage',
    components: { ModelReportItem },
    setup() {
      const reports = ref([]);

      const onAccepted = ({rid}) => {
        reports.value = reports.value.map((report) => ({
          ...report,
          status: report._id === rid ? 'accepted' : report.status,
        }));
      }

      const onRejected = ({rid}) => {
        reports.value = reports.value.map((report) => ({
          ...report,
          status: report._id === rid ? 'rejected' : report.status,
        }));
      }

      onMounted(async() => {
        const { data } = await admin.getModelsReports({
          limit: 1000,
          page: 1
        });
        reports.value = data.docs;
      });

      return {
        reports,
        onAccepted,
        onRejected,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .reports-list {
    margin: 20px;

    list-style-type: none;
  }
</style>

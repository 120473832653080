<template>
  <div className="public-page forgot-password">
    <div class="public-page__header">
      <router-link to="/" class="text-center">
        <img src="@/assets/images/logo-big.svg" class="public-page__logo"/>
      </router-link>
      <h1 class="header">Reset password</h1>
    </div>

    <form class="form" @submit="onSubmit">
      <div class="form__group">
        <Input
            placeholder="Verification code"
            @onInput="onFieldUpdate('code', $event)"
        />
        <div class="form__item-error">{{errors.codeError}}</div>
      </div>
      <div class="form__group">
        <Input
            placeholder="New Password"
            type="password"
            @onInput="onFieldUpdate('password', $event)"
        />
        <div class="form__item-error">{{errors.passwordError}}</div>
      </div>
      <div class="form__group">
        <Input
            placeholder="Repeat New Password"
            type="password"
            @onInput="onFieldUpdate('repeatPassword', $event)"
        />
        <div class="form__item-error">{{errors.repeatPasswordError}}</div>
      </div>
      <div class="form__group">
        <Button text="Reset Password" :disabled="isLoading"/>
      </div>
    </form>

    <div class="forgot-password__links d-flex">
      <router-link to="/registration" class="link">
        Register here!
      </router-link>
      <router-link to="/" class="link">
        Back to Login
      </router-link>
    </div>

    <FooterPublic/>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Input, Button, FooterPublic } from '@/components';
  import { errorsToMessage, isEmail } from '@/utils';
  import config from '@/config';

  export default {
    name: 'ResetPassword',
    components: { Input, Button, FooterPublic },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const isLoading = ref(false);
      const form = {
        code: '',
        login: route.query.login,
        password: '',
        repeatPassword: ''
      };
      const errors = ref({
        codeError: null,
        passwordError: null,
        repeatPasswordError: null
      });

      const onFieldUpdate = (fieldName, value) => {
        form[fieldName] = value;
      };

      const onSubmit = (event) => {
        event.preventDefault();

        clearValidationErrors();

        validateForm();

        if (!isFormValid()) return false;
        isLoading.value = true;

        store.dispatch('app/resetPassword',
            {
              resetToken: form.code,
              password: form.password,
              [isEmail(form.login) ? 'email' : 'phone']: form.login,
            })
            .then(() => {
              store.dispatch('message/show', {
                text: 'We successfully updated your password',
                type: 'info'
              });
              setTimeout(() => {
                router.push('/')
              }, 3000);
            })
            .catch((e) => {
              const data = e.response.data;
              let errorText = null;

              if (data) {
                if (e.response.data.errors) {
                  errorText = errorsToMessage(data.errors);
                } else {
                  errorText = data.message;
                }
              }

              store.dispatch('message/show', { text: errorText, type: 'error' });
            })
            .finally(() => {
              isLoading.value = false;
            }
          );
      }

      const clearValidationErrors = () => {
        Object.keys(errors.value).forEach((fieldName) => {
          errors.value[fieldName] = null;
        });
      };

      const validateForm = () => {
        if (!form.code) {
          errors.value.codeError = 'Verification code can\'t be empty';
        }
        if (!form.login) {
          errors.value.loginError = 'Login can\'t be empty';
        }
        if (form.password.length < config.passwordMinLength) {
          errors.value.passwordError = `Password length must be at least ${config.passwordMinLength} characters long`;
        }
        if (!form.password) {
          errors.value.passwordError = 'Password can\'t be empty';
        }
        if (form.password !== form.repeatPassword) {
          errors.value.repeatPasswordError = 'Passwords should be equal';
        }
      };

      const isFormValid = () => {
        let isNoErrors = true;
        Object.keys(errors.value).forEach((fieldName) => {
          if (isNoErrors) {
            isNoErrors = !errors.value[fieldName];
          }
        });
        return isNoErrors;
      };

      return {
        errors,
        onFieldUpdate, onSubmit,
        isLoading,
      }
    }
  }
</script>

<style lang="scss" scoped>
.forgot-password {
  &__links {
    justify-content: space-around;

    margin-top: 35px;

    list-style-type: none;
    align-items: center;
  }
}
</style>

<template>
  <div class="public-page verification">
    <div class="public-page__header">
      <router-link to="/" class="text-center">
        <img src="@/assets/images/logo-big.svg" class="public-page__logo"/>
      </router-link>
      <h1 class="header">Verification</h1>
      <h2 class="sub-header text-center">
        by {{ verificationType === 'email' ? 'email' : 'phone' }}
      </h2>
    </div>

    <form class="form" @submit="onFormSubmit">
      <div class="form__group">
        <Input placeholder="Code" @onInput="onCodeInput" autocomplete="one-time-code"/>
      </div>
      <div class="form__group">
        <Button text="Continue"/>
      </div>
    </form>

    <div class="verification__links d-flex">
      <router-link to="/registration" class="link">
        First Time User Register Here!
      </router-link>
<!--      <router-link to="/forgot-password" class="link">-->
<!--        Forgot password?-->
<!--      </router-link>-->
    </div>

    <FooterPublic/>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { Input, Button, FooterPublic } from '@/components';
  import { errorsToMessage } from '@/utils';

  export default {
    name: 'RegistrationVerification',
    components: {
      Input, Button, FooterPublic,
    },
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();

      const verificationType = ref(route.query.type);
      let code = null;

      store.dispatch('app/sendVerificationCode', {
        type: verificationType.value,
        action: 'send',
      }).then(() => {
        store.dispatch('message/show', {
          text: `We sent verification code to your ${verificationType.value}`,
          type: 'info'
        });
      });

      const onCodeInput = (value) => {
        code = value;
      }

      const onFormSubmit = (event) => {
        event.preventDefault();

        store.dispatch('app/codeVerification', {
          type: verificationType.value,
          action: 'check',
          code,
        })
        .then(() => {
          const fieldName = verificationType.value === 'email' ? 'email_confirmed' : 'phone_confirmed';

          store.commit('app/UPDATE_USER', {
            [fieldName]: true,
          });

          router.push('/');
        })
        .catch((e) => {
          const data = e.response.data;
          let errorText = null;

          if (data) {
            if (e.response.data.errors) {
              errorText = errorsToMessage(data.errors);
            } else {
              errorText = data.message;
            }
          }

          store.dispatch('message/show', { text: errorText, type: 'error' });
        });
      }

      return {
        verificationType,
        onCodeInput, onFormSubmit,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .verification {
    .sub-header {
      margin-top: 10px;
    }

    &__links {
      justify-content: space-around;

      margin-top: 35px;

      list-style-type: none;
      align-items: center;
    }
  }
</style>

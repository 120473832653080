<template>
  <div class="select">
    <select :value="value" class="select__item" @change="onSelect">
      <option v-if="placeholder" value="" disabled selected>
        {{ placeholder.toUpperCase() }}
      </option>
      <option v-for='option in options' :value="option.value" :key="option.value">
        {{option.text}}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    name: 'SelectBox',
    props: {
      value: [String, Number],
      placeholder: String,
      options: {
        type: Array,
        required: true,
      },
    },
    setup(_, { emit }) {
      const onSelect = (event) => {
        emit('onSelect', event.target.value);
      }

      return { onSelect };
    }
  }
</script>

<style lang="scss" scoped>
  .select {
    &__item {
      width: 100%;
      padding: 10px 18px 10px 12px;

      font-family: Roboto;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1em;
      color: #666;

      -webkit-appearance: none;
      border: none;
      border-radius: 0;

      background-color: #FFF;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
</style>

<template>
  <h1 class="header">Test page for experiments</h1>
</template>

<script>
  export default {
    name: 'TestPage',
  }
</script>

<style lang="scss" scoped>

</style>

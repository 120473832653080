<template>
  <h1 class="header text-center text-uppercase">ACCOUNTING</h1>

  <div class="revenue">
    <h2 class="sub-header text-center text-uppercase revenue__header">YOUR ACCOUNT HAS</h2>
    <div class="revenue__value text-center mb-20">${{balanceValue}}</div>
    <div class="text-center mb-30">
      <Button
        text="Request payout"
        rounded
        size="m"
        type="green"
        :disabled="!canSendPayoutRequest"
        @onClick="onRequestPopupCall"
      />
      <p class="text text-center mt-10" v-if="!canSendPayoutRequest && payoutRequest && !isPayoutPaid">
        Request already sent "${{payoutRequest.requestAmount}}"
      </p>
    </div>
  </div>

  <h2 class="sub-header text-center text-uppercase">RECENT ACTIVITY</h2>
  <div class="billing-items-list">
    <div
        class="billing-item status__new"
        v-for="transaction in transactions"
        :key="transaction.id"
    >
      <div class="billing-item__wrapper d-flex justify-space-between align-items-center">
        <div class="billing-item__content">
          <div class="billing-item__date">
            {{formatReportDate(transaction.createdAt)}}
          </div>
          <div class="billing-item__txt">
            {{transaction.transactionRef}}
          </div>
        </div>
        <span class="billing-item__amount">
          ${{transaction.amount}}
        </span>
      </div>
    </div>
  </div>
  <div class="billing-items-load-more mb-60" v-if="currentPage">
    <Button
      text="Load more"
      rounded
      size="extra-small"
      type="transparent"
      class="billing-items-load-more__btn"
      @onClick="onLoadMoreBillings"
    />
  </div>

  <Popup v-if="isPayoutPopupVisible" @close="onPayoutPopupClose">
    <div class="text-center">
      <h3 class="sub-header text-center text-uppercase">Minimum amount $25</h3>
      <Input placeholder="Amount" :value="payoutValue" prepend="$" @onInput="onPayoutInput"/>
      <Button class="mt-20" text="Request" rounded size="m" type="green" @onClick="onSendPayout"/>
    </div>
  </Popup>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import moment from 'moment';

  import { Button, Popup, Input } from '@/components';
  import { creator } from '@/api';

  export default {
    name: 'CreatorBillingPage',
    components: {
      Button,
      Popup,
      Input
    },
    setup() {
      const BILLING_PAGE_SIZE = 25;
      const MIN_PAYOUT = 25; /* TODO: move to config from server */
      const payoutValue = ref(MIN_PAYOUT);
      const isPayoutPopupVisible = ref(false);
      const transactions = ref([]);
      const payoutRequest = ref(null);
      const canSendPayoutRequest = ref(false);
      const isPayoutPaid = ref(false);
      const balanceValue = ref(0);
      const currentPage = ref(1);

      const formatReportDate = (date) => {
        return moment(date).format('MM/DD/YYYY - HH:mm A');
      }

      const getPaymentWithRate = ({amount, rate}) => {
        return (amount * rate).toFixed(2);
      }

      const onRequestPopupCall = () => {
        isPayoutPopupVisible.value = true;
      }

      const onPayoutPopupClose = () => {
        isPayoutPopupVisible.value = false;
      }

      const onPayoutInput = (amount) => {
        payoutValue.value = amount;
      }

      const onSendPayout = async() => {
        if (!payoutValue.value) {
          return alert('Please, enter the amount you want to pay out');
        }

        if (payoutValue.value < MIN_PAYOUT) {
          return alert(`Min amount is $${MIN_PAYOUT}`);
        }

        if (payoutValue.value > balanceValue.value) {
          return alert('You can\'t exceed your balance');
        }

        const { data } = await creator.sendPayoutRequest({
          requestAmount: payoutValue.value,
        });
        payoutRequest.value = data;
        canSendPayoutRequest.value = false;

        onPayoutPopupClose();
      }

      const getBillings = async () => {
        const { data } = await creator.getModelBillingActivity({
          limit: BILLING_PAGE_SIZE,
          page: currentPage.value
        });
        currentPage.value = data.nextPage;
        transactions.value = [...transactions.value, ...data.docs];
      }

      const onLoadMoreBillings = () => {
        getBillings();
      }

      onMounted(async () => {
        await getBillings();

        const balance = await creator.getBillingBalance();
        balanceValue.value = balance.data.balance.toFixed(2);

        const payoutRequestsData = await creator.getOwnPayoutRequests();
        payoutRequest.value = payoutRequestsData.data.docs[0];

        isPayoutPaid.value = payoutRequestsData.data.docs[0]?.status === 'paid'

        if (balanceValue.value < MIN_PAYOUT) {
          canSendPayoutRequest.value = false;
        } else {
          canSendPayoutRequest.value = !payoutRequestsData.data.docs.filter(({status}) => status === 'request').length;
        }
      });

      return {
        balanceValue,
        isPayoutPopupVisible,
        transactions,
        payoutValue,
        payoutRequest,
        canSendPayoutRequest,
        currentPage,
        getPaymentWithRate,
        formatReportDate,
        onRequestPopupCall,
        onPayoutInput,
        onSendPayout,
        onPayoutPopupClose,
        onLoadMoreBillings,
        isPayoutPaid,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 20px 0;
  }

  .sub-header {
    margin-bottom: 40px;
  }

  .billing-items-list {
    margin: 0 15px 30px 15px;
  }

  .billing-item {
    position: relative;
    padding: 12px;
    margin-bottom: 1px;

    background: #000;
    border-radius: 2px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.1em;

    &__txt {
      font-size: 14px;
      line-height: 16px;
      word-break: break-word;
    }

    &__date {
      font-size: 10px;
      line-height: 18px;
      color: #888;
    }

    &__amount {
      text-align: center;
      font-size: 22px;
    }

    &.status__new {
      border-left: 1px solid #67AFCE;
    }
    &.status__accepted {
      border-left: 1px solid #67CE67;
    }
    &.status__rejected {
      border-left: 1px solid #D53E34;
    }
  }

  .revenue {
    &__header {
      margin-bottom: 20px;
    }

    &__value {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 56px;
      color: #E2F507;
    }
  }

  .billing-items-load-more {
    &__btn {
      width: 50%;
      margin: 0 auto;
    }
  }
</style>

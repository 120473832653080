<template>
  <div id='vouched'/>
  <Button text="Next" class="mb-20" @onClick="onSubmit" :disabled="!vouched"/>
</template>

<script>
import { onMounted, watch, ref } from 'vue';
import { Button } from '@/components';
import config from '@/config';
import { useStore } from 'vuex';

export default {
  name: 'VerificationForm',
  components: { Button },
  props: { onStepIncrement: Function },
  setup({ onStepIncrement }) {
    const store = useStore();
    const model = ref(store.state.consumer?.profile?.model);
    const vouched = ref(model?.vouched?.success);

    watch(() => store.state?.app?.auth?.user?.vouched?.success, isUserVouched => {
      vouched.value = isUserVouched;
    });

    const onSubmit = () => {
      onStepIncrement();
    };

    onMounted(async () => {
      const envParams = {
        ...config.VOUCHED['PRODUCTION'],
      };

      const vouchedParams = {
        ...envParams,
        callbackURL: `${envParams.callbackURL}/${store.getters['app/userID']}`,
        verification: {
          firstName: model.first_name,
          lastName: model.last_name,
        },
        crossDevice: true,
        crossDeviceQRCode: true,
        crossDeviceSMS: true,
        onDone: (job) => {
          console.log('Scanning complete', { token: job.token });

          onStepIncrement();

          // An alternative way to update your system based on the
          // results of the job. Your backend could perform the following:
          // 1. query jobs with the token
          // 2. store relevant job information such as the id and
          //    success property into the user's profile
          // fetch(`/yourapi/idv?job_token=${job.token}`);
        },

        theme: {
          name: 'avant',
          bgColor: '#202020',
          logo: '/img/logo.svg',
          baseColor: '#D5AF34',
          fontColor: '#FFF',
        },
      };

      const vouched = window.Vouched(vouchedParams);
      vouched.mount('#vouched');
    });

    return { onSubmit, vouched }
  },
}
</script>

<style scoped>
  #vouched {
    height: calc(100vh - 315px);
    overflow-y: auto;
  }

  .mb-20 {
    margin: 0 20px;
  }
</style>

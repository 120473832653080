<template>
  <h1 class="header text-center text-uppercase">
    <div>{{ titleText }} Bundle</div>
  </h1>

  <form class="form">
    <div class="form__group">
      <Input
          :value="form.title"
          placeholder="Give it a title"
          @onInput="onInput('title', $event)"
      />
    </div>
    <div class="form__group">
      <Textarea
          :value="form.description"
          placeholder="Description"
          @onInput="onInput('description', $event)"
      />
    </div>
  </form>

  <slot v-if="!bundleFilesList">
    <h2 class="sub-header text-center text-uppercase mt-25">ATTACH MEDIA?</h2>
    <h2 class="text text-center mt-20">You can add maximum 10 files to 1 bundle</h2>

    <div class="files">
      <div class="files__selector" v-if="files.length < 10">
        <input
          type="file"
          class="files__selector-input"
          accept="image/*, video/*, .mp3, application/pdf"
          multiple
          @change="onFilesSelect"
        />
        <Button text="SELECT FILES" class="mt-40" type="transparent"/>
      </div>
      <div class="mt-40 files__list" v-if="files.length">
        <h2 class="text text-center mt-20" v-if="files.length">{{ filesSelected }}</h2>
        <div class="d-flex align-items-center justify-space-between files__list-item" v-for="(file, index) in files" :key="index">
          <div class="d-flex align-items-center">
            <img
                v-if="file.type === 'image'"
                class="files__list-item-img"
                :src="file.image"
            />
            <span v-else-if="file.type === 'video'" class="files__list-item-img d-flex justify-center">
              <img src="@/assets/images/icons/video.svg" width="32"/>
            </span>
            <span v-else-if="file.type === 'audio'" class="files__list-item-img d-flex justify-center">
              <img src="@/assets/images/icons/video.svg" width="32"/>
            </span>
            <span v-else class="files__list-item-img d-flex justify-center">
              <img src="@/assets/images/icons/document.svg" width="32"/>
            </span>

            <span class="files__list-item-name text">
              {{file.text}}
            </span>
          </div>
          <Button text="Remove" size="small" @onClick="onRemoveSelectedFile(file)" />
        </div>
      </div>
    </div>

    <div class="mt-40 text-center" v-if="files.length">
      <spawn class="clear-btn icon" @click="onSelectedMediaClear">
        <img src="@/assets/images/icons/trash.svg"/>
      </spawn>
    </div>
  </slot>
  <slot v-else>
    <div class="files">
      <h2 class="sub-header mb-10">Uploaded:</h2>
      <ul class="files-list">
        <li
          v-for="type in Object.keys(bundleFilesList)"
          :key="type"
          class="text"
        >
          {{bundleFilesList[type].length}} <b>{{type}}</b> {{bundleFilesList[type].length > 1 ? 'files' : 'file'}}
        </li>
      </ul>
    </div>
  </slot>

  <form class="form switchers">
    <div
        :class="['form__group', {
        disabled: form.isPublic
      }]"
    >
      <h2 class="sub-header text-center text-uppercase mb-20">Setup price</h2>
      <Input
          :value="form.price"
          placeholder="Price"
          prepend="$"
          @onInput="onInput('price', $event)"
      />
    </div>

    <div
        :class="['form__group mt-20 mb-20', {
        disabled: form.isPublic
      }]"
    >
      <Checkbox
          label="Add discount"
          :value="hasDiscount"
          :checked="hasDiscount"
          @onChange="onSetDiscount($event)"
      />
      <Input
          v-if="hasDiscount"
          :value="form.promotion_price"
          placeholder="Discount price"
          prepend="$"
          @onInput="onInput('promotion_price', $event)"
      />
      <div v-if="hasDiscount" class="d-flex mt-10">
        <div class="promo-date-input">
          <h3 class="text text-uppercase mb-10">Start date</h3>
          <Input
              :value="form.promotion_start"
              @onInput="onDate('promotion_start', $event)"
              class="mr-10"
              type="date"
          />
        </div>
        <div class="promo-date-input">
          <h3 class="text text-uppercase mb-10">End date</h3>
          <Input
              :value="form.promotion_end"
              @onInput="onDate('promotion_end', $event)"
              type="date"
          />
        </div>
      </div>
    </div>

    <Switch label="OPEN TO PUBLIC?" :checked="form.isPublic" @onChange="onMakePostPublic"/>

    <div class="button-loader">
      <Button
        :disabled="isSendingData"
        :text="`${isEdit ? 'EDIT' : 'SUBMIT'} BUNDLE`"
        class="mt-40"
        @onClick="onSubmit"
      />
      <div v-if="isSendingData" class="button-loader__wrapper text-center">
        <img src="@/assets/images/spinner.svg"/>
      </div>
    </div>
  </form>
</template>

<script>
  import { ref, reactive, computed, onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import moment from 'moment';

  import {
    Input,
    Textarea,
    Button,
    Switch,
    Checkbox
  } from '@/components';
  import { common, creator } from '@/api';
  import { errorsToMessage, groupBy } from '@/utils';
  import config from '@/config';

  export default {
    name: 'NewBundle',
    components: {
      Input,
      Textarea,
      Button,
      Switch,
      Checkbox,
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const thumbSrc = ref(null);
      const videoSrc = ref(null);
      const videoThumbSrc = ref(null);
      const isSendingData = ref(false);
      const files = ref([]);
      const bundleFilesList = ref(null);
      const form = reactive({
        title: '',
        description: '',
        isPublished: true,
        price: 1,
        isPublic: false,
        isBundle: true,
        promotion_price: null,
        promotion_start: null,
        promotion_end: null,
      });
      const postId = route.query.pid;
      const isEdit = Boolean(postId);
      const titleText = computed(() => postId ? 'Edit' : 'Add');
      const hasDiscount = ref(false);
      const filesSelected = computed(() =>
        files.value.length && `${files.value.length} file${files.value.length > 1 ? 's' : ''} selected`
      );

      const uploadMedia = (media, mediaType, pid) => {
        let signedURLData = null;

        return common.getS3UploadURL({
              mediaType,
              contentType: media.type,
              fileName: media.name,
              postId: pid,
            })
            .then(({ data }) => {
              signedURLData = data;

              return fetch(data.url, {
                method: 'PUT',
                mode: 'cors',
                body: media,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': media.type,
                }
              })
            })
            .then(async () => {
              await common.reportForS3SuccessUpload({
                mediaType,
                contentType: media.type,
                key: signedURLData.key,
                postId: pid,
              });
            });
      };

      const onRemoveSelectedFile = ({ text }) => {
        files.value = files.value.filter(file => file.text !== text);
      }

      /* TODO: move Post and Bundle validation in common place */
      const validation = () => {
        let errorTxt = null;

        const isEmptyOrNull = v => v === null || v.match(/^ *$/) !== null;

        if (isEmptyOrNull(form.title) || isEmptyOrNull(form.description)) {
          errorTxt = 'Title and Description are required';
        }

        if (form.description.length < 6) {
          errorTxt = 'Description should be at least 6 characters long';
        }

        if (files.value.length > 10) {
          errorTxt = 'You can add maximum 10 files to 1 bundle';
        }

        if (!form.isPublic) {
          if (form.promotion_price) {
            if (!form.promotion_start || !form.promotion_end) {
              errorTxt = 'Promotion should have start and end dates';
            }
            if (!moment(form.promotion_start, 'YYYY-MM-DD', true).isValid()) {
              errorTxt = 'Incorrect start date format';
            }
            if (!moment(form.promotion_end, 'YYYY-MM-DD', true).isValid()) {
              errorTxt = 'Incorrect end date format';
            }
          }
          if (parseInt(form.promotion_price, 10) > form.price) {
            errorTxt = 'Promotion price should be less or equal then post price.';
          }
        }

        if (errorTxt) {
          store.dispatch('message/show', {
            type: 'error',
            text: errorTxt,
          });
          return false;
        }

        return true;
      };

      const disablePromoData = () => {
        form.promotion_price = 0;
        form.promotion_start = null;
        form.promotion_end = null;
      }

      const onSubmit = (e) => {
        let post = null;
        let postAction;

        e.preventDefault();

        if (!validation()) return;

        isSendingData.value = true;

        /* If creator unchecked promo, we need to reset promo data */
        if (!hasDiscount.value) {
          disablePromoData();
        }

        if (postId) {
          postAction = creator.updatePost({ pid: postId, data: form });
        } else {
          postAction = creator.createPost(form);
        }

        postAction
            .then(async (res) => {
              post = res;

              for (let i = 0; i < files.value.length; i++) {
                const type = getFileType(files.value[i].originFile);

                await uploadMedia(
                    files.value[i].originFile,
                    type,
                    postId || post.data._id
                );
              }
            })
            .then(() => {
              /*
                Image on AWS can't be updated immediately event after callback so we need a delay on our side.
                TODO: find more elegant solution
              */
              setTimeout(() => {
                isSendingData.value = false;
                router.push('/');
              }, config.AWS_REFRESH_FILES_DELAY);
            })
            .catch((e) => {
              store.dispatch('message/show', {
                type: 'error',
                text: errorsToMessage(e.response.data.errors)
              });
              isSendingData.value = false;
            });
      };

      const onInput = (type, value) => {
        form[type] = value;
      }

      const onDate = (type, value) => {
        form[type] = moment(value).format('YYYY-MM-DD');
      }

      const onSelectedMediaClear = () => {
        files.value = [];
      }

      const onMakePostPublic = () => {
        form.isPublic = !form.isPublic;
      }

      const onFilesSelect = (event) => {
        event.target.files.forEach((file) => {
          let type = getFileType(file);

          files.value.push({
            originFile: file,
            type,
            image: URL.createObjectURL(file),
            text: file.name,
          });
        });
      }

      const getFileType = (file) => {
        if (file.type.includes('image')) {
          return 'image';
        } else if (file.type.includes('video')) {
          return 'video';
        } else if (file.type.includes('audio')) {
          return 'audio';
        }
        return 'pdf';
      }

      const onSetDiscount = ({value}) => {
        hasDiscount.value = value;
      }

      onBeforeMount(async () => {
        if (postId) {
          const media = await creator.getPostMedia({
            mid: store.getters['app/userID'],
            pid: postId
          });

          await creator.getPost(postId).then(({ data }) => {
            form.title = data.title;
            form.description = data.description;
            form.price = data.price;
            form.promotion_price = data.promotion_price;

            if (data.promotion_price) {
              form.promotion_start = moment(data.promotion_start).format('YYYY-MM-DD');
              form.promotion_end = moment(data.promotion_end).format('YYYY-MM-DD');
            }

            if (data.promotion_price) {
              hasDiscount.value = true;
            }

            if (data.bundle) {
              bundleFilesList.value = groupBy(data.bundle, 'mediaType');
            } else {
              if (data.media.mediaType === 'video') {
                videoSrc.value = media.data;
                videoThumbSrc.value = data.media.thumbnail;
              } else {
                thumbSrc.value = media.data;
              }
            }
          });
        }
      });

      return {
        isEdit,
        titleText,
        form,
        files,
        thumbSrc,
        videoSrc,
        videoThumbSrc,
        isSendingData,
        bundleFilesList,
        hasDiscount,
        onSubmit,
        onInput,
        onSelectedMediaClear,
        onMakePostPublic,
        onFilesSelect,
        onSetDiscount,
        onDate,
        onRemoveSelectedFile,
        filesSelected,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 0 30px 0;
  }

  .form {
    padding: 0 20px;
  }

  .attach-media {
    padding: 30px 20px;
    justify-content: space-around;
  }

  .attach-media-item {
    &__img-wrapper {
      position: relative;
      width: 110px;
      height: 80px;

      img {
        height: 100%;
      }
    }

    &__lbl {
      width: 110px;
      margin-top: 25px;

      font-family: Roboto;
      font-style: italic;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
    }

    &__file-btn {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;

      opacity: 0;
    }
  }

  .switchers {
    margin: 20px 0 30px 0;
  }

  .switch {
    margin: 10px 0;
  }

  .thumb {
    margin: 20px;
    height: 200px;

    border: 1px solid #4e4c4c;

    img, video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .button-loader {
    position: relative;

    &__wrapper {
      position: absolute;
      top: 0;
      height: 61px;
      width: 100%;

      background: rgba(0,0,0, 0.82);

      img {
        height: 100%;
      }
    }
  }

  .clear-btn {
    width: 56px;
    height: 56px;
    padding: 13px;

    border-radius: 30px;
    background: #313131;
  }

  .files {
    margin: 20px;

    &__selector {
      position: relative;
    }

    &__selector-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &__list {

    }

    &__list-item {
      margin: 10px 0;
      width: 100%;
    }

    &__list-item-img {
      height: 40px;
      width: 40px;
      object-fit: cover;
    }

    &__list-item-name {
      margin-left: 10px;
      max-width: 180px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .files-list {
    list-style-type: none;

    b {
      font-weight: bold;
    }
  }

  .promo-date-input {
    width: 50%;
  }
</style>

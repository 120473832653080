<template>
  <div className="public-page forgot-password">
    <div class="public-page__header">
      <router-link to="/" class="text-center">
        <img src="@/assets/images/logo-big.svg" class="public-page__logo"/>
      </router-link>
      <h1 class="header">Forgot password?</h1>
    </div>

    <form class="form" @submit="onSubmit">
      <div class="form__group">
        <Input
          placeholder="Phone number or email"
          @onInput="onFieldUpdate('login', $event)"
        />
      </div>
      <div class="form__group">
        <Button text="Reset Password" :disabled="isLoading"/>
      </div>
    </form>

    <div class="forgot-password__links d-flex">
      <router-link to="/registration" class="link">
        Register here!
      </router-link>
      <router-link to="/forgot-password" class="link">
        Forgot password?
      </router-link>
    </div>

    <FooterPublic/>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Input, Button, FooterPublic } from '@/components';
  import { errorsToMessage, isEmail } from '@/utils';

  export default {
    name: 'ForgotPassword',
    components: { Input, Button, FooterPublic },
    setup() {
      const isLoading = ref(false)
      const router = useRouter();
      const store = useStore();
      const form = {
        login: ''
      };

      const onFieldUpdate = (fieldName, value) => {
        form[fieldName] = value;
      };

      const onSubmit = (event) => {
        event.preventDefault();

        isLoading.value = true;

        const type = isEmail(form.login) ? 'email' : 'phone';

        store.dispatch('app/sendResetCode',
          {
            [type]: form.login,
          })
          .then(() => {
            store.dispatch('message/show', {
              text: `We sent recovery code to your ${type}`,
              type: 'info'
            });
          })
          .then(() => {
            router.push({
              path: '/reset-password',
              query: {
                login: form.login,
              }
            });
          })
          .catch((e) => {
            const data = e.response.data;
            let errorText = null;

            if (data) {
              if (e.response.data.errors) {
                errorText = errorsToMessage(data.errors);
              } else {
                errorText = data.message;
              }
            }

            store.dispatch('message/show', { text: errorText, type: 'error' });
          })
          .finally(() => {
            isLoading.value = false
          });
      }

      return {
        onFieldUpdate, onSubmit, isLoading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .forgot-password {
    &__links {
      justify-content: space-around;

      margin-top: 35px;

      list-style-type: none;
      align-items: center;
    }
  }
</style>

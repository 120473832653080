<template>
  <slot v-if="profile">
    <Cover :avatar="avatar" :cover="cover"/>

    <div class="top">
      <!--Statusbar/-->
      <Nickname class="nickname" :username=" profile.model.username"/>
      <ModelStatistics :statistics="profile.model.statistics"/>
    </div>

    <div class="content text-center">
      <h4 class="sub-header">ABOUT ME</h4>
      <p class="text-italic"> {{ profile.model.home_settings.about_me || '-' }} </p>

      <h4 class="sub-header">ATTRIBUTES</h4>
      <ModelAttributes
        :attributes="profile.model.attributes"
        :home_settings="profile.model.home_settings"
      />
    </div>

    <div class="posts">
      <h4 class="sub-header text-center">FEED</h4>

      <div v-if="!posts.length" class="text-center mb-20">-</div>

      <ModelPost
        v-for="post in posts"
        :post="post"
        :key="post._id"
        isOwn
      />
    </div>
  </slot>
</template>

<script>
  import { watch, onBeforeMount, ref } from 'vue';
  import { useStore } from 'vuex';
  import moment from 'moment';
  import {
    Cover, ModelPost, Nickname, ModelStatistics, ModelAttributes
  } from '@/components';
  import { common } from '@/api';

  export default {
    name: 'CreatorHomePages',
    components: {
      Cover, ModelPost, Nickname, ModelStatistics, ModelAttributes
    },
    setup() {
      const store = useStore();
      const posts = ref([]);
      const profile = store.getters['creator/profile'];
      const avatar = ref(store.state.creator.profile.model.picture);
      const cover = ref(store.state.creator.profile.model.home_settings.picture);

      const formattedDate = (date) => {
        return moment(date).format('MMMM DD YYYY');
      };

      watch(() => store.state.creator.profile, () => {
        profile.value = store.getters['creator/profile'];
        avatar.value = store.state.creator.profile.model.picture;
      });

      onBeforeMount(async () => {
        await store.dispatch('creator/getProfileInfo', {
          uid: store.getters['app/userID'],
        });

        /* TODO: make pagination on scroll */
        await store.dispatch('creator/getPostsList', {
          limit: 100,
          page: 1,
        }).then((data) => {
          posts.value = data;
        });

        await common.getBillingActivity();
      });

      return {
        posts,
        formattedDate,
        user: store.getters['app/userInfo'],
        profile,
        avatar,
        cover,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .thumb {
    height: 190px;
    overflow: hidden;

    &__img {
      width: 100%;
    }
  }

  .avatar {
    margin-top: -45px;
  }

  .list {
    padding: 28px;

    list-style-type: none;
    font-family: Roboto;
    letter-spacing: 0.1em;

    &__item-value {
      font-style: normal;
      font-weight: 300;
      font-size: 9px;
      color: #E2F507;
    }

    &__item-txt {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      color: #FFF;
      text-transform: uppercase;
    }
  }

  .top {
    background: #000;
  }

  .content {
    padding: 8px 28px;
  }

  .content .sub-header {
    margin: 20px 0;
  }

  .attributes-list {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    padding: 0 0 28px 0;
  }

  .nickname {
    padding-top: 40px;
  }
</style>

<template>
  <h1 class="header text-center text-uppercase">My Account</h1>
  <h2 class="sub-header text-center text-uppercase">Profile settings</h2>
  <form class="form">
    <div class="form__group">
      <Input disabled :value="data.fields.login"/>
    </div>
    <div class="form__group">
      <Input
        :value="data.fields.firstName"
        placeholder="First name"
        @onInput="onInput('firstName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
        :value="data.fields.lastName"
        placeholder="Last name"
        @onInput="onInput('lastName', $event)"
      />
    </div>
    <div class="form__group">
      <Input
        :value="data.fields.username"
        placeholder="Username"
        @onInput="onUsernameCheck('username', $event)"
      />
      <div v-if="isUsernameAvailable === 'available'" class="username--available">Username is available</div>
      <div v-else-if="isUsernameAvailable === 'in use'" class="username--in-use">Username already in use</div>
    </div>
    <div class="form__group">
      <Input
          :value="data.fields.birthday"
          placeholder="01/31/1990"
          @onInput="onInput('birthday', $event)"
          v-maska="'##/##/####'"
      />
    </div>
  </form>

  <h2 class="sub-header text-center text-uppercase mt-25">Home settings</h2>

  <Cover isEditable :avatar="avatar" :cover="cover"/>

  <form class="form mt-60">
    <div class="form__group">
      <Textarea
        placeholder="Please, describe yourself"
        :value="data.fields.aboutMe"
        @onInput="onInput('aboutMe', $event)"
      />
    </div>
    <div class="form__group">
      <SelectBox
        placeholder="SELECT NATIONALITY"
        :options="data.lists.nationalityList"
        :value="data.fields.nationality"
        @onSelect="onSelect('nationality', $event)"
      />
    </div>
    <div class="form__group">
      <SelectBox
        placeholder="MARITAl STATUS"
        :options="data.lists.maritalList"
        :value="data.fields.maritalStatus"
        @onSelect="onSelect('maritalStatus', $event)"
      />
    </div>

    <CheckboxPanel
      title="body type"
      :options="data.lists.bodyTypes"
      :selected="data.fields.bodyType || []"
      :isOpened="true"
      @select="onMultiOptionsSelect('bodyType', $event)"
    />

    <CheckboxPanel
      title="BODY MODS"
      :options="data.lists.bodyMods"
      :selected="data.fields.bodyMod || []"
      :isOpened="true"
      @select="onMultiOptionsSelect('bodyMod', $event)"
    />

    <CheckboxPanel
      title="FETISHES"
      :options="data.lists.fetishes"
      :selected="data.fields.fetishes || []"
      @select="onMultiOptionsSelect('fetishes', $event)"
    />

    <h2 class="sub-header text-center text-uppercase mt-25">Attributes</h2>

    <form class="attributes mt-20 mb-60">
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hair color"
            :value="data.fields.hairColor"
            :options="data.lists.hairColors"
            @onSelect="onSelect('hairColor', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hair length"
            :value="data.fields.hairLength"
            :options="data.lists.hairLengths"
            @onSelect="onSelect('hairLength', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Eye color"
            :value="data.fields.eyeColor"
            :options="data.lists.eyeColors"
            @onSelect="onSelect('eyeColor', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
              placeholder="Height"
              :value="data.fields.height"
              :options="data.lists.height"
              @onSelect="onSelect('height', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <Input
              :value="data.fields.weight"
              placeholder="Weight"
              @onInput="onInput('weight', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Bust size"
            :value="data.fields.bustSize"
            :options="data.lists.bustSize"
            @onSelect="onSelect('bustSize', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
            placeholder="Waist size"
            :value="data.fields.waistSize"
            :options="data.lists.waistSize"
            @onSelect="onSelect('waistSize', $event)"
          />
        </div>
        <div class="attributes__cell">
          <SelectBox
            placeholder="Hip size"
            :value="data.fields.hipSize"
            :options="data.lists.hipSizes"
            @onSelect="onSelect('hipSize', $event)"
          />
        </div>
      </div>
      <div class="d-flex attributes__row">
        <div class="attributes__cell">
          <SelectBox
              placeholder="Cup size"
              :value="data.fields.cupSize"
              :options="data.lists.cupSize"
              @onSelect="onSelect('cupSize', $event)"
          />
        </div>
        <div class="attributes__cell">&nbsp;</div>
      </div>
    </form>

    <Button text="Save changes" class="mb-20" @onClick="onProfileUpdate"/>
  </form>
</template>

<script>
  import { ref, onBeforeMount, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { maska } from 'maska'
  import moment from 'moment';

  import { common } from '@/api';
  import { throttle } from '@/utils';
  import {
    Input, Cover, Textarea, Button, SelectBox, CheckboxPanel
  } from '@/components';

  export default {
    name: 'CreatorAccount',
    components: {
      Cover, Input, Textarea, Button, SelectBox, CheckboxPanel
    },
    directives: { maska },
    setup() {
      const store = useStore();
      const router = useRouter();
      const user = store.getters['app/userInfo'];
      const profile = store.getters['creator/profile'];
      const avatar = ref(store.state.creator.profile.model.picture);
      const cover = ref(store.state.creator.profile.model.home_settings.picture);
      const creatorData = reactive({
        avatar: profile.picture,
        fields: {
          username: null, birthday: null,
          login: null, height: null,
          weight: null, aboutMe: null,
          hipSize: null, bustSize: null,
          lastName: null, eyeColor: null,
          waistSize: null, firstName: null,
          hairColor: null, hairLength: null,
          nationality: null, maritalStatus: null,
          bodyMod: null, bodyType: null,
          cupSize: null, fetishes: null,
        },
        lists: {
          weight: [], height: [],
          bustSize: [], hipSizes: [],
          bodyMods: [], waistSize: [],
          bodyTypes: [], eyeColors: [],
          hairColors: [], hairLengths: [],
          maritalList: [], nationalityList: [],
          cupSize: [], fetishes: [],
        }
      });
      const originUsername = ref(null);
      const isUsernameAvailable = ref(null);

      const prepareDataForLists = (data, metrics) => (data.map((value) => ({
        text: `${value.toString().toUpperCase()} ${metrics || ''}`,
        value,
      })));

      onBeforeMount(async () => {
        const { data } = await common.getPublicOptions();
        const { fields, lists } = creatorData;
        const { model: { home_settings }} = profile;

        fields.login = user.phone || user.email;
        fields.username = profile.model.username;
        fields.birthday = profile.model.birthday && moment(profile.model.birthday).format('MM/DD/YYYY');
        fields.height = profile.model.attributes.height;
        fields.weight = profile.model.attributes.weight;
        fields.firstName = profile.model.first_name;
        fields.lastName = profile.model.last_name;
        fields.hipSize = profile.model.attributes.hip_size;
        fields.waistSize = profile.model.attributes.waist_size;
        fields.aboutMe = home_settings.about_me;
        fields.bustSize = profile.model.attributes.bust_size;
        fields.eyeColor = profile.model.attributes.eye_color;
        fields.hairColor = profile.model.attributes.hair_color;
        fields.hairLength = profile.model.attributes.hair_length;
        fields.nationality = home_settings.nationality;
        fields.maritalStatus = home_settings.marital_status;
        fields.bodyType = home_settings.body_type;
        fields.bodyMod = home_settings.body_mods;
        fields.cupSize = profile.model.attributes.cup_size;
        fields.fetishes = [ ...profile.model.attributes.fetishes ];

        /* Data to render lists */
        lists.nationalityList = prepareDataForLists(data.enums.profile.nationality);
        lists.maritalList = prepareDataForLists(data.enums.profile.marital_statuses);
        lists.bodyTypes = prepareDataForLists(data.enums.profile.body_types);
        lists.bodyMods = prepareDataForLists(data.enums.profile.body_mods);
        lists.hairColors = prepareDataForLists(data.enums.attributes.hair_color);
        lists.hairLengths = prepareDataForLists(data.enums.attributes.hair_length);
        lists.eyeColors = prepareDataForLists(data.enums.attributes.eye_color);
        lists.hipSizes = prepareDataForLists(data.enums.attributes.hip_size, '"');
        lists.waistSize = prepareDataForLists(data.enums.attributes.waist_size, '"');
        lists.bustSize = prepareDataForLists(data.enums.attributes.bust_size);
        lists.cupSize = prepareDataForLists(data.enums.attributes.cup_size);
        lists.fetishes = prepareDataForLists(data.enums.attributes.fetishes);

        originUsername.value = profile.model.username;

        lists.height = prepareDataForLists(data.enums.attributes.height);
        lists.height[0].text = `${lists.height[0].text} or shorter`;
        lists.height[lists.height.length - 1].text = `${lists.height[lists.height.length - 1].text} or taller`;
      });

      const onOptionSelect = (type, { selectedItems }) => {
        creatorData.fields[type] = selectedItems[0] || null;
      };

      const onMultiOptionsSelect = (type, { selectedItems }) => {
        creatorData.fields[type] = [...selectedItems];
      };

      const onInput = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onUsernameCheck = throttle(async (type, value) => {
        if (!value) {
          isUsernameAvailable.value = null;
          return false;
        }

        creatorData.fields[type] = value;

        const { data } = await common.checkUsernameAvailability({
          role: 'model',
          username: value,
        });

        isUsernameAvailable.value = data.status;

        if (originUsername.value === value) {
          isUsernameAvailable.value = null;
        }
      }, 1000);

      const onSelect = (type, value) => {
        creatorData.fields[type] = value;
      };

      const onProfileUpdate = async (event) => {
        event.preventDefault();

        const { fields } = creatorData;

        await store.dispatch(
            'creator/updateProfileInfo',
            {
              model: {
                first_name: fields.firstName,
                last_name: fields.lastName,
                username: fields.username,
                birthday: fields.birthday,
                attributes: {
                  height: fields.height,
                  weight: fields.weight,
                  hip_size: fields.hipSize,
                  waist_size: fields.waistSize,
                  bust_size: fields.bustSize,
                  eye_color: fields.eyeColor,
                  hair_color: fields.hairColor,
                  hair_length: fields.hairLength,
                  cup_size: fields.cupSize,
                  fetishes: fields.fetishes,
                },
                home_settings: {
                  ...profile.model.home_settings,
                  about_me: fields.aboutMe,
                  nationality: fields.nationality,
                  marital_status: fields.maritalStatus,
                  body_type: fields.bodyType,
                  body_mods: fields.bodyMod,
                },
              },
            },
        ).then(() => {
          originUsername.value = fields.username;
          isUsernameAvailable.value = null;

          store.dispatch('message/show', {
            text: 'You successfully updated your profile',
            type: 'info'
          });
        }).finally(() => {
          // giving a model a second to see the notification on the edit page prior being redirected
          setTimeout(() => {
            router.push('/');
          }, 1000)
        });
      }

      return {
        data: creatorData,
        avatar, cover, isUsernameAvailable,
        onOptionSelect, onMultiOptionsSelect,
        onInput, onUsernameCheck, onSelect, onProfileUpdate,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0 30px 0;
  }

  .sub-header {
    margin-bottom: 15px;
  }

  .form {
    padding: 0 20px;
  }

  .cover {
    margin-top: 20px;
  }

  .attributes {
    &__row {
      margin-bottom: 20px;
    }

    &__cell {
      width: 50%;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .username {
    &--available,
    &--in-use {
      position: relative;
      top: 6px;

      font-size: 12px;

      color: #31b131;
    }

    &--in-use {
      color: #e64242;
    }
  }
</style>

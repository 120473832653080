<template>
  <h1 class="header text-center text-uppercase">Model Requests</h1>

  <ul class="requests-list" v-if="requestsList && requestsList.length">
    <li
        class="requests-list__item d-flex justify-space-between align-items-center"
        v-for="item in requestsList"
        :key="item._id"
    >
      <div class="text requests-list__info">
        <div class="requests-list__item-username">{{item.username}}</div>
        <div class="requests-list__item-name">{{item.first_name}} {{item.last_name}}</div>
      </div>
      <div class="requests-list__item-actions d-flex">
        <Button
          text="Approve"
          size="extra-small"
          type="green"
          rounded
          @onClick="onRequestApprove(item)"
        />
        <Button
          text="Reject"
          size="extra-small"
          type="red"
          rounded
          class="ml-10"
          @onClick="onRequestReject(item)"
        />
      </div>
    </li>
  </ul>
  <div v-else class="text text-center mt-10">
    No requests
  </div>

  <div class="load-more mt-40 mb-60" v-if="currentPage && currentPage > 1">
    <Button
        text="Load more"
        rounded
        size="extra-small"
        type="transparent"
        class="load-more__btn"
        @onClick="onLoadMore"
    />
  </div>

  <Popup v-if="isRejectWinVisible" @close="onRejectWinClose">
    <h1 class="header text-center text-uppercase">Reject reason</h1>
    <Textarea
      class="mb-20"
      placeholder="Input reason text"
      :value="rejectReason"
      @onInput="onRejectReasonInput"
    />
    <Button text="Send" @onClick="onRequestRejectSend"/>
  </Popup>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { Button, Popup, Textarea } from '@/components';
  import { admin } from '@/api';

  export default {
    name: 'AdminModelRequestsPage',
    components: {
      Button,
      Popup,
      Textarea,
    },
    setup() {
      const PAGE_SIZE = 25;
      const currentPage = ref(1);
      const requestsList = ref([]);
      const isRejectWinVisible = ref(false);
      const rejectReason = ref('');
      let currentMember = null;

      const getRequests = async () => {
        const { data } = await admin.getListOfModelsRequests({
          limit: PAGE_SIZE,
          page: currentPage.value,
        });

        currentPage.value = data.nextPage;
        requestsList.value = [...requestsList.value, ...data.docs];
      }

      const onLoadMore = () => getRequests();

      const onRequestApprove = async (member) => {
        if (confirm('Are you sure?')) {
          await admin.approveModelRequest({
            mid: member._id
          });
          requestsList.value = requestsList.value.filter(({_id}) => _id !== member._id);
        }
      }

      const onRequestReject = async (member) => {
        currentMember = member;
        isRejectWinVisible.value = true;
      }

      const onRejectWinClose = () => {
        isRejectWinVisible.value = false;
      }

      const onRequestRejectSend = async () => {
        await admin.rejectModelRequest({
          mid: currentMember._id,
          reason: rejectReason.value
        });
        requestsList.value = requestsList.value.filter(({_id}) => _id !== currentMember._id);
        onRejectWinClose();
      }

      const onRejectReasonInput = (text) => {
        rejectReason.value = text;
      }

      onMounted(async () => {
        await getRequests();
      });

      return {
        currentPage,
        requestsList,
        isRejectWinVisible,
        rejectReason,
        onLoadMore,
        onRequestApprove,
        onRequestReject,
        onRejectWinClose,
        onRequestRejectSend,
        onRejectReasonInput,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    margin: 30px 0;
  }

  .requests-list {
    &__info {
      max-width: 50%;
    }

    &__item {
      margin: 10px 20px;
      padding-bottom: 10px;

      border-bottom: 1px solid #383737;
    }

    &__item-username {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .load-more {
    &__btn {
      width: 50%;
      margin: 0 auto;
    }
  }
</style>

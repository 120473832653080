<template>
  <div>
    <Cover isEditable :cover="cover" :avatar="avatar" isModelRequest :role="'creator'"/>
    <Button text="Next" class="mb-20" :disabled="isButtonDisabled" @onClick="onSubmit"/>
  </div>
</template>

<script>
import { Cover, Button } from '@/components';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';

export default {
  name: 'ImagesForm',
  props: { onStepIncrement: Function },
  components: { Cover, Button },
  setup({ onStepIncrement }) {
    const store = useStore();
    const cover = ref(store.state.consumer.profile.model?.home_settings?.picture);
    const avatar = ref(store.state.consumer.profile.model?.picture);
    const isButtonDisabled = computed(() => Boolean(!cover || !avatar));

    const onSubmit = () => {
      onStepIncrement();
    };

    return { onSubmit, cover, avatar, isButtonDisabled };
  },
}
</script>

<style scoped>
  .mb-20 {
    margin: 150px 20px 10px;
  }
</style>